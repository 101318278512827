import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function LoadingPage() {
  return (
    <div className="flex h-screen justify-center items-center">
      <AiOutlineLoading3Quarters
        size={40}
        className="animate-spin text-PrimaryColor"
      />
    </div>
  );
}

export default LoadingPage;
