import React from "react";
import Container from "../../../Shared/Components/Container";
import { useTranslation } from "react-i18next";

function Glance() {
  const { t } = useTranslation();
  return (
    <div className="pb-10">
      <Container>
        <div className="w-full h-[2px] bg-[#f1f1f1] max-md:bg-transparent relative mb-5">
          <div className="uppercase font-bold text-[#161616] text-[26px] max-md:text-[18px] absolute w-full text-center top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]">
            {t("kunafaFingers - Online Sweet Shop Malaysia")}
          </div>
        </div>
        <div className="flex flex-col gap-1 font-[500] py-5 mx-auto max-md:w-full text-[#5c5b5b]">
          <h1>
            {t(
              "The Levantine region has been making sweets and pastries for centuries. Traditional skills are passed down from father to son, who have taken years to master their craft, from generation to generation. In Malaysia, kunafaFingers online store is the number one online confectionery store that sells Lebanese Sweets, Nawashef pistachios and walnuts, and Arabic confectionery online."
            )}
          </h1>
          <h2>
            {t(
              "Find out more about kunafaFingers handcrafted baklava, maamoul, and the classic flavors of Syrian cookies and sweet treats such as Baklava, Barazek, Mamoul, and Graybeh. We import the best Arabic Sweets so you can order from the best confectionery in London such as Arabic Sweets, Turkish Sweets, and Turkish Coffee. Our delivery team will arrive in no time if you place your order online."
            )}
          </h2>
        </div>
      </Container>
    </div>
  );
}

export default Glance;

