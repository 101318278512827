import React from "react";
import { MdOutlineLanguage } from "react-icons/md";
import { usePopup } from "../../Context/PopupContext";

function LanguageButton({ className, mobile }) {
  const { setSelectLangOpen } = usePopup();
  const kfLanguage = localStorage.getItem("kfLanguage") || "English";
  const lng =
    kfLanguage === "English"
      ? "EN"
      : kfLanguage === "Chinese"
      ? "ZH"
      : kfLanguage === "Malaysian"
      ? "MS"
      : "AR";
  return (
    <div
      onClick={() => setSelectLangOpen(true)}
      className={`${className} flex items-center gap-1 cursor-pointer rounded-[10px] border border-[#ddd] w-fit bg-[#00000008] hover:bg-[#0c0a0a26] duration-300 py-1 px-2`}
    >
      {lng}
      <MdOutlineLanguage />
    </div>
  );
}

export default LanguageButton;
