import React, { useContext, useEffect, useState } from "react";
import { fetchFavoriteSweets } from "../../Api/SweetApi";
import Container from "../../Shared/Components/Container";
import { useTranslation } from "react-i18next";
import CartItemLap from "../Components/CartItemLap";
import { SweetContext } from "../../Context/SweetContext";
import CartItemMob from "../Components/CartItemMob";
import { useNavigate } from "react-router-dom";
import CartItemLapSkeleton from "../Components/SkeletonLoading/CartItemLapSkeleton";
import CartItemMobSkeleton from "../Components/SkeletonLoading/CartItemMobSkeleton";
import SweetItemDetails from "../Components/SweetItemDetails";
import { usePopup } from "../../Context/PopupContext";

function WishList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [favoriteSweets, setFavoriteSweets] = useState([]);
  const { updateFavorite } = useContext(SweetContext);
  const [loading, setLoading] = useState(true);
  const getFavorite = async () => {
    try {
      setLoading(true);
      const response = await fetchFavoriteSweets();
      console.log(response.data);
      setFavoriteSweets(response.data);
      if (response.data.length < 1) navigate("/");
    } catch (error) {
      console.error("fetch favorite err : ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavorite();
  }, [updateFavorite]);
  return (
    <div>
      <div className="flex justify-center items-center p-[15px] text-[27px] font-[700] leading-[32px] tracking-[1.36px] max-md:text-[22px] max-md:leading-[26px] max-md:tracking-[1.12px] bg-[#f7f7f7]">
        {t("WISHLIST")}
      </div>
      <div className="py-5 bg-white">
        <Container>
          <div className="flex flex-col gap-3">
            <h1 className="font-[700] text-[26px] leading-[32px] max-md:text-[20px]">
              {t("My wishlist")}
            </h1>
            <table className="max-md:hidden">
              <thead>
                <tr className="border-b-[3px] border-b-[#ececec]">
                  <td></td>
                  <td></td>
                  <td className="leading-[1.05] tracking-[0.05em] uppercase p-[7px] text-[14px] font-[700]">
                    {t("PRODUCT NAME")}
                  </td>
                  <td className="leading-[1.05] tracking-[0.05em] uppercase p-[7px] text-[14px] font-[700]">
                    {t("UNIT PRICE")}
                  </td>
                  <td className="leading-[1.05] tracking-[0.05em] uppercase p-[7px] text-[14px] font-[700]">
                    {t("quantity")}
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <>
                    <CartItemLapSkeleton />
                    <CartItemLapSkeleton />
                  </>
                ) : (
                  favoriteSweets &&
                  favoriteSweets.map((item) => (
                    <CartItemLap
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      image={item.productsMedia[0].url_media}
                      price={item.price}
                    />
                  ))
                )}
              </tbody>
            </table>
            <div className="md:hidden">
              {loading ? (
                <div className="flex flex-col gap-2">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div key={index}>
                      <CartItemMobSkeleton />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  {favoriteSweets &&
                    favoriteSweets.map((item) => (
                      <CartItemMob
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        image={item.productsMedia[0].url_media}
                        price={item.price}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default WishList;

