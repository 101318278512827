import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HoverOnNameListItem from "./HoverOnNameListItem";
import { SweetContext } from "../../Context/SweetContext";

function HoverOnNameList({ handleLogout }) {
  const { t } = useTranslation();
  const { favoriteTotal } = useContext(SweetContext);
  return (
    <div className="p-5 flex flex-col gap-3 min-w-[260px] overflow-y-auto shadow-xl">
      {/* <HoverOnNameListItem content={t("DASHBOARD")} /> */}
      {/* <HoverOnNameListItem content={t("ORDERS")} /> */}
      {/* <HoverOnNameListItem content={t("STORE CREDITS")} /> */}
      {/* <HoverOnNameListItem content={t("DOWNLOADS")} /> */}
      {/* <HoverOnNameListItem content={t("ADDRESSES")} /> */}
      {/* <HoverOnNameListItem content={t("PAYMENT METHOD")} /> */}
      <HoverOnNameListItem
        content={t("ACCOUNT DETAILS")}
        link={`/profile/accountDetails`}
      />
      {favoriteTotal > 0 && (
        <HoverOnNameListItem
          content={t("WISHLIST")}
          link={`/profile/wishList`}
        />
      )}
      <HoverOnNameListItem
        content={t("LOGOUT")}
        lastItem={true}
        onClick={handleLogout}
      />
    </div>
  );
}

export default HoverOnNameList;

