import { createContext, useEffect, useState } from "react";
import React from "react";
import { fetchCategoriesCollection } from "../Api/SweetApi";

export const CategoriesContext = createContext();

function CategoriesProvider({ children }) {
  const language_id = localStorage.getItem("languageId");
  const lng = localStorage.getItem("kfLanguage") || "English";
  const [catLoading, setCatLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(1);
  const [updateCategories, setUpdateCategories] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    image: "",
  });
  const fetchCategories = async () => {
    try {
      setCatLoading(true);
      const response = await fetchCategoriesCollection(language_id);
      console.log(response.data);
      setCategories(response.data);
    } catch (error) {
      console.error("fetch categories err : ", error);
    } finally {
      setCatLoading(false);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, [lng]);
  const contextValue = {
    catLoading,
    setCatLoading,
    categories,
    setCategories,
    categoryId,
    setCategoryId,
    updateCategories,
    setUpdateCategories,
    selectedCategory,
    setSelectedCategory,
  };
  return (
    <CategoriesContext.Provider value={contextValue}>
      {children}
    </CategoriesContext.Provider>
  );
}

export default CategoriesProvider;
