import React from "react";
import Skeleton from "react-loading-skeleton";

function CartItemLapSkeleton({ edit }) {
  return (
    <tr>
      {Array.from({ length: 6 }).map((_, index) => (
        <td
          key={index}
          className={`${index === 0 && "w-[71px]"} ${
            index === 1 && "w-[71px]"
          } ${index === 5 && !edit && "hidden"}`}
        >
          <Skeleton width={"100%"} className="min-h-[90px]" />
        </td>
      ))}
    </tr>
  );
}

export default CartItemLapSkeleton;
