import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTheme } from "../../Context/ThemeContext";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { toastError } from "../../Shared/Components/ToastNotification/Toast";
import { useTranslation } from "react-i18next";
import { forgetPassword } from "../../Api/DashboardApi";
function ForgetPassword() {
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();
  const [adminEmail, setAdminEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    try {
      if (adminEmail === "") {
        toastError("email is required");
      } else {
        formData.append("email", adminEmail);
        setLoading(true);
        const response = await forgetPassword(formData);
        console.log(response.data);
        if (response.success === true) navigate("/admin");
      }
    } catch (error) {
      console.error("forget password err : ", error);
    }
    setLoading(false);
  };

  return (
    <div
      style={{ direction: "ltr" }}
      className={`${
        theme === "dark" ? "bg-MainBg" : "bg-MainBg"
      } h-screen flex justify-center items-center relative`}
    >
      <div
        className={`${
          theme === "dark"
            ? "bg-MainBg shadow-white shadow-registerWayBoxShadow"
            : "bg-white shadow-xl"
        } rounded-[10px] py-[20px] px-[36px] w-[402px] m-auto overflow-hidden`}
      >
        <h1 className="text-center my-[15px] font-[700] text-[25px] leading-[32px]">
          Reset password
        </h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <input
            className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] duration-300 w-full mb-3 h-[46px] rounded-[8px] text-[16px] font-[500] border-2 outline-none py-[21px] px-[19px] focus:border-2 focus:ring-0`}
            type="text"
            placeholder="Please input your email ."
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
          />
          <button
            className={`${
              loading ? "opacity-30 cursor-default pointer-events-none" : ""
            } flex justify-center items-center h-[46px] w-full min-w-[209px]  text-[20px] text-[#fff] font-[700] outline-none rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300`}
            type="submit"
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgetPassword;
