import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { usePopup } from "../../Context/PopupContext";

function BottomBarLink({
  onLogin,
  className,
  content,
  link,
  hasDropDown,
  data,
  click,
  login,
  ListOpen,
}) {
  const [dropDownListOpen, setDropDownListOpen] = useState(ListOpen);
  const { setIsHovered, setSideBarOpen } = usePopup();
  return (
    <div>
      <Link
        className={`${className} flex items-center gap-1 text-[#353535] font-[500] text-[0.84em] tracking-[0.1em] uppercase hover:text-PrimaryColor duration-300 max-xl:w-full max-xl:text-[#777] max-xl:hover:text-[#353535] max-xl:hover:bg-[#d1cece] max-xl:text-[1.1em] max-xl:h-fit ${
          !click && hasDropDown && "relative group"
        }`}
        to={link}
        onClick={() => {
          click && setDropDownListOpen(!dropDownListOpen);
          login && onLogin();
          !click && setSideBarOpen(false);
        }}
        onMouseEnter={() => window.innerWidth >= 1280 && setIsHovered(true)}
        onMouseLeave={() => window.innerWidth >= 1280 && setIsHovered(false)}
      >
        {content}
        {hasDropDown && (
          <IoIosArrowDown
            className={`${dropDownListOpen && "rotate-180"} duration-300`}
          />
        )}

        <div className="absolute -top-[1000px] bg-white opacity-0 -z-0 transition-opacity duration-300 group-hover:opacity-100 group-hover:z-10 group-hover:top-7">
          <div className="p-2 flex flex-col min-w-[200px]">
            {data &&
              data.map((item, index) => (
                <div
                  key={index}
                  className="px-2 py-1 text-[#777] hover:text-[#5f5e5e] duration-300 cursor-pointer"
                >
                  {item}
                </div>
              ))}
          </div>
        </div>
      </Link>
      {dropDownListOpen && (
        <div className="p-2 flex flex-col justify-center items-center w-full">
          {data &&
            data.map((item, index) => (
              <div
                onClick={() => setDropDownListOpen(false)}
                key={index}
                className="px-2 py-1 text-[#777] hover:text-[#5f5e5e] duration-300 cursor-pointer"
              >
                {item}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default BottomBarLink;
