import React, { useContext, useEffect, useState } from "react";
import Container from "../../Shared/Components/Container";
import { useTranslation } from "react-i18next";
import { FaHeart } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { useTheme } from "../../Context/ThemeContext";
import { usePopup } from "../../Context/PopupContext";
import Cookies from "js-cookie";
import { IoPerson } from "react-icons/io5";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import HoverOnNameList from "./HoverOnNameList";
import logo from "../../assests/Imgs/Logo/funafaFingersLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { SweetContext } from "../../Context/SweetContext";
import {
  fetchFavoriteSweetsCount,
  searchCurious,
  searchUser,
} from "../../Api/SweetApi";
import LanguageButton from "./LanguageButton";
import { CategoriesContext } from "../../Context/CategoriesContext";
import SearchItem from "./SearchItem";
import SweetItemDetails from "./SweetItemDetails";
import useOutsideClick from "../../Shared/Components/UseOutsideClick";

function CenterBar({ sessionId, logout }) {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = Cookies.get("kfToken");
  const name = localStorage.getItem("kfName");
  const lng = localStorage.getItem("kfLanguage") || "English";
  const language_id = localStorage.getItem("languageId");
  const { favoriteTotal, setFavoriteTotal, updateFavorite } =
    useContext(SweetContext);
  const { categories } = useContext(CategoriesContext);
  const searchRef = useOutsideClick(() => setSearchOpen(false));

  const { setLoginOpen, searchOpen, setSearchOpen, sweetItemDetailsOpen } =
    usePopup();
  const [searchCategory, setSearchCategory] = useState("");
  const [query, setQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const getFavoriteCount = async () => {
    try {
      const response = await fetchFavoriteSweetsCount();
      console.log(response.data);
      setFavoriteTotal(parseFloat(response.data));
    } catch (error) {
      console.error("fetch favorite sweets err : ", error);
    }
  };

  const handleSearch = async (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    const categoryId = searchCategory;
    try {
      setSearchLoading(true);
      const response = token
        ? await searchUser(newQuery, categoryId, language_id)
        : await searchCurious(newQuery, categoryId, language_id, sessionId);
      console.log(response.data);
      setSearchResult(response.data);
      setSearchOpen(true);
    } catch (err) {
      console.error("search user err : ", err);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    token && getFavoriteCount();
  }, [updateFavorite]);
  return (
    <div className={`bg-inherit max-xl:hidden`}>
      <Container>
        <div className="flex justify-between items-center py-2 text-[0.85em]">
          <div className="flex items-center gap-3">
            <Link to="/" className="w-[120px] h-[60px]">
              <img className="h-full object-contain" src={logo} alt="" />
            </Link>
            <div className="flex items-center gap-1">
              <select
                value={searchCategory}
                onChange={(e) => setSearchCategory(e.target.value)}
                className="rounded-[10px] border border-[#ddd] py-1 px-2 cursor-pointer outline-none bg-[#00000008] hover:bg-[#0c0a0a26] duration-300"
              >
                <option defaultValue={t("All")} value="">
                  {t("All")}
                </option>
                {categories &&
                  categories.length > 0 &&
                  categories.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <div className="relative min-w-[375px]">
                <input
                  className="w-full cursor-pointer rounded-[10px] border border-[#ddd] py-1 px-3 bg-[#00000008] hover:bg-[#0c0a0a26] duration-300 outline-none focus:ring-0"
                  type="text"
                  placeholder={`${t("Search")}...`}
                  value={query}
                  onChange={handleSearch}
                />
                <div
                  className={`${
                    lng === "Arabic" ? "left-2" : "right-2"
                  } absolute top-[50%] -translate-y-[50%] cursor-pointer`}
                >
                  {searchLoading ? (
                    <AiOutlineLoading3Quarters className="animate-spin" />
                  ) : (
                    <IoMdSearch />
                  )}
                </div>
                <div
                  ref={searchRef}
                  className={`${
                    searchOpen
                      ? "block z-20 opacity-100"
                      : "hidden -z-10 opacity-0"
                  } absolute -top-0 mt-8 transition-all duration-300 min-w-[375px] shadow-xl overflow-y-auto`}
                >
                  {searchResult &&
                    searchResult.map((result) => (
                      <div key={result.id}>
                        <SearchItem
                          id={result.id}
                          image={result.productsMedia[0].url_media}
                          name={result.name}
                          price={result.price}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <LanguageButton />
            </div>
          </div>
          <div className="flex items-center gap-3 text-[#666666d9] h-full">
            <div
              onClick={() =>
                favoriteTotal !== 0 && token && navigate("/profile/wishList")
              }
              className="relative rounded-full p-2 cursor-pointer border border-[#666666d9] hover:bg-PrimaryColor hover:border-PrimaryColor hover:text-white duration-300"
            >
              <FaHeart />
              <div
                className={`${
                  (!favoriteTotal || favoriteTotal === 0) && "hidden"
                } absolute -top-3 -left-3 text-[#f1f1f1] rounded-full p-1 text-[10px] min-w-[25px] min-h-[25px] flex justify-center items-center bg-[#79a218]`}
              >
                {favoriteTotal}
              </div>
            </div>
            <div className="h-[20px] w-[1px] bg-[#0000001a]"></div>
            <div
              onClick={() => !token && setLoginOpen(true)}
              className={`${
                token && "group relative"
              } font-bold cursor-pointer uppercase text-[#666666d9] hover:text-[#111111d9] duration-300 max-w-[200px]`}
            >
              {token ? (
                <div className="flex items-center gap-1">
                  {name} <IoPerson />
                </div>
              ) : (
                t("Login / Register")
              )}
              <div className="absolute -mt-2 -top-full left-[50%] -translate-x-[50%] bg-white opacity-0 -z-10 transition-opacity duration-300 group-hover:opacity-100 group-hover:z-20 group-hover:top-7">
                <HoverOnNameList handleLogout={logout} />
              </div>
            </div>
          </div>
        </div>
      </Container>
      {sweetItemDetailsOpen && <SweetItemDetails />}
    </div>
  );
}

export default CenterBar;

