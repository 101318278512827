import React, { useContext } from "react";
import Popup from "../../Shared/Components/Popup";
import { useTranslation } from "react-i18next";
import { usePopup } from "../../Context/PopupContext";
import americaFlag from "../../assests/Imgs/Flags/americaFlag.png";
import saudiArabiaFlag from "../../assests/Imgs/Flags/saudiArabiaFlag.png";
import chinaFlag from "../../assests/Imgs/Flags/chinaFlag.png";
import malaysiaFlag from "../../assests/Imgs/Flags/malaysiaFlag.png";
import { CategoriesContext } from "../../Context/CategoriesContext";
import { SweetContext } from "../../Context/SweetContext";

function LanguageSelect() {
  const { i18n, t } = useTranslation();
  const { selectLangOpen, setSelectLangOpen } = usePopup();
  const { setUpdateCategories } = useContext(CategoriesContext);
  const { setDir } = useContext(SweetContext);
  const selectLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("kfLanguage", lng);
    setDir(lng === "Arabic" ? "rtl" : "ltr");
    setSelectLangOpen(false);
    setUpdateCategories((prev) => prev + 1);
  };
  const lng = localStorage.getItem("kfLanguage") || "English";
  return (
    <div>
      <Popup
        toggleSwitch={selectLangOpen}
        onClick={() => setSelectLangOpen(false)}
        zIndex={`z-40`}
        className={`${
          selectLangOpen
            ? "left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]"
            : "-top-full"
        } w-[35%] rounded-[10px] shadow-2xl max-xl:w-[70%] max-lg:w-[80%] max-sm:w-[90%] bg-white z-50`}
        children={
          <div className="flex flex-col gap-5 h-full py-[30px] px-[20px] text-[#555]">
            <h1
              className={`${
                lng === "Arabic" && "ml-auto"
              } font-[700] text-[1.25em]`}
            >
              {t("CHOOSE LANGUAGE")}
            </h1>
            <div className="flex flex-col gap-3">
              <div
                onClick={() => {
                  selectLanguage("English");
                  localStorage.setItem("languageId", 1);
                }}
                className={`${
                  lng === "English"
                    ? "border-PrimaryColor cursor-auto"
                    : "border-transparent cursor-pointer"
                } group border-[2px] hover:border-PrimaryColor duration-300 flex items-center gap-2 w-full min-h-[100px] max-h-[100px] relative rounded-[10px] overflow-hidden`}
              >
                {lng === "English" && (
                  <div className="bg-PrimaryColor/50 absolute w-full h-full z-0"></div>
                )}
                <img
                  className="w-full h-full absolute object-cover -z-10"
                  src={americaFlag}
                  alt=""
                />
                <div
                  className={`${
                    lng === "English"
                      ? "bg-PrimaryColor text-white border-PrimaryColor"
                      : "bg-white border-[#555]"
                  } group-hover:bg-PrimaryColor group-hover:text-white group-hover:border-PrimaryColor duration-300 font-bold absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] min-w-[200px] flex justify-center items-center py-2 px-3 border rounded-[10px]`}
                >
                  {t("English")}
                </div>
              </div>
              <div
                onClick={() => {
                  selectLanguage("Chinese");
                  localStorage.setItem("languageId", 3);
                }}
                className={`${
                  lng === "Chinese"
                    ? "border-PrimaryColor cursor-auto"
                    : "border-transparent cursor-pointer"
                } group border-[2px] hover:border-PrimaryColor duration-300 flex items-center gap-2 w-full min-h-[100px] max-h-[100px] relative rounded-[10px] overflow-hidden`}
              >
                {lng === "Chinese" && (
                  <div className="bg-PrimaryColor/50 absolute w-full h-full z-0"></div>
                )}
                <img
                  className="w-full h-full absolute object-cover -z-10"
                  src={chinaFlag}
                  alt=""
                />
                <div
                  className={`${
                    lng === "Chinese"
                      ? "bg-PrimaryColor text-white border-PrimaryColor"
                      : "bg-white border-[#555]"
                  } group-hover:bg-PrimaryColor group-hover:text-white group-hover:border-PrimaryColor duration-300 font-bold absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] min-w-[200px] flex justify-center items-center py-2 px-3 border rounded-[10px]`}
                >
                  {t("Chinese")}
                </div>
              </div>
              <div
                onClick={() => {
                  selectLanguage("Malaysian");
                  localStorage.setItem("languageId", 4);
                }}
                className={`${
                  lng === "Malaysian"
                    ? "border-PrimaryColor cursor-auto"
                    : "border-transparent cursor-pointer"
                } group border-[2px] hover:border-PrimaryColor duration-300 flex items-center gap-2 w-full min-h-[100px] max-h-[100px] relative rounded-[10px] overflow-hidden`}
              >
                {lng === "Malaysian" && (
                  <div className="bg-PrimaryColor/50 absolute w-full h-full z-0"></div>
                )}
                <img
                  className="w-full h-full absolute object-cover -z-10"
                  src={malaysiaFlag}
                  alt=""
                />
                <div
                  className={`${
                    lng === "Malaysian"
                      ? "bg-PrimaryColor text-white border-PrimaryColor"
                      : "bg-white border-[#555]"
                  } group-hover:bg-PrimaryColor group-hover:text-white group-hover:border-PrimaryColor duration-300 font-bold absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] min-w-[200px] flex justify-center items-center py-2 px-3 border rounded-[10px]`}
                >
                  {t("Malaysian")}
                </div>
              </div>
              <div
                onClick={() => {
                  selectLanguage("Arabic");
                  localStorage.setItem("languageId", 2);
                }}
                className={`${
                  lng === "Arabic"
                    ? "border-PrimaryColor cursor-auto"
                    : "border-transparent cursor-pointer"
                } group border-[2px] hover:border-PrimaryColor duration-300 flex items-center gap-2 w-full min-h-[100px] max-h-[100px] relative rounded-[10px] overflow-hidden`}
              >
                {lng === "Arabic" && (
                  <div className="bg-PrimaryColor/50 absolute w-full h-full z-0"></div>
                )}
                <img
                  className="w-full h-full absolute object-cover -z-10"
                  src={saudiArabiaFlag}
                  alt=""
                />
                <div
                  className={`${
                    lng === "Arabic"
                      ? "bg-PrimaryColor text-white border-PrimaryColor"
                      : "bg-white border-[#555]"
                  } group-hover:bg-PrimaryColor group-hover:text-white group-hover:border-PrimaryColor duration-300 font-bold absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] min-w-[200px] flex justify-center items-center py-2 px-3 border rounded-[10px]`}
                >
                  {t("Arabic")}
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default LanguageSelect;
