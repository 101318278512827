import React, { useContext } from "react";
import Container from "../../Shared/Components/Container";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import CategoryItem from "./CategoryItem";
import CategoryItemSkeleton from "../Components/SkeletonLoading/CategoryItemSkeleton";
import { useTranslation } from "react-i18next";
import { CategoriesContext } from "../../Context/CategoriesContext";

function CategoryCollection() {
const { t } = useTranslation();
const lng = localStorage.getItem("kfLanguage") || "English";
const navigate = useNavigate();
const { categories, loading, setCategoryId } = useContext(CategoriesContext);
return (
  <div className="pt-5">
    <Container>
      <div className="flex justify-between items-center">
        <h1 className="text-PrimaryColor uppercase font-bold border-b-[2px] border-[#0000001a] py-2">
          {t("SHOP BY CATEGORY")}
        </h1>
        <Link
          to="/sweetsByCategory"
          className="font-bold hover:text-GreenColor duration-300 flex items-center gap-1"
        >
          {t("More Products")}
          <IoIosArrowForward
            className={`${lng === "Arabic" && "rotate-180"}`}
          />
        </Link>
      </div>
      <div className="w-full h-[2px] bg-[#f1f1f1]"></div>
      <div style={{ direction: "ltr" }} className="mainSwiper py-5 -z-0">
        <Swiper
          className="h-full"
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={20}
          speen={150}
          navigation
          autoplay
          loop
          breakpoints={{
            850: {
              slidesPerView: 5,
            },
            550: {
              slidesPerView: 3,
            },
            0: {
              slidesPerView: 2,
            },
          }}
        >
          {loading
            ? Array.from({ length: 8 }).map((_, index) => (
                <SwiperSlide key={index}>
                  <CategoryItemSkeleton />
                </SwiperSlide>
              ))
            : categories.length > 0 &&
              categories.map((item) => (
                <SwiperSlide
                  onClick={() => {
                    setCategoryId(item.id);
                    navigate("/sweetsByCategory");
                  }}
                  key={item.id}
                >
                  <CategoryItem name={item.name} pic={item.url_media} />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </Container>
  </div>
);
}

export default CategoryCollection;

