import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { GiKnifeFork } from "react-icons/gi";

import { HiDocumentReport } from "react-icons/hi";
import { FaCartShopping } from "react-icons/fa6";
import { usePopup } from "../../Context/PopupContext";
import { BsPersonVcardFill } from "react-icons/bs";

function DashboardSidebar() {
  const { sideBarOpen, setSideBarOpen } = usePopup();
  const { id } = useParams();
  const location = useLocation();
  function isActive(path) {
    return location.pathname === path;
  }
  return (
    <div className="mt-[2px] ml-[2px] h-full z-50">
      <div
        className={`${
          sideBarOpen ? "py-4" : "w-[54px]"
        } relative h-full bg-gradient-to-l from-PrimaryColor to-HoverPrimaryColor text-white rounded-br-3xl`}
      >
        <div className="absolute -right-4 top-[50%] -translate-y-[50%]">
          {sideBarOpen ? (
            <div
              onClick={() => setSideBarOpen(false)}
              className="p-3 rounded-full border border-white cursor-pointer bg-gradient-to-l from-PrimaryColor to-HoverPrimaryColor hover:opacity-90 duration-300"
            >
              <MdOutlineArrowBackIos />
            </div>
          ) : (
            <div
              onClick={() => setSideBarOpen(true)}
              className="p-3 rounded-full border border-white cursor-pointer bg-gradient-to-l from-PrimaryColor to-HoverPrimaryColor hover:opacity-90 duration-300"
            >
              <MdOutlineArrowForwardIos />
            </div>
          )}
        </div>
        <nav>
          <h1
            className={`${
              sideBarOpen ? "block" : "invisible py-5"
            } px-4 w-full pt-2 text-center text-white font-bold text-[30px]`}
          >
            Dashboard
          </h1>
          <Link
            to="/dashboard"
            className={`flex items-center gap-2 w-full h-full pt-4 pb-3 pl-4 text-[16px] transition duration-300 text-white hover:bg-orange-800
                    ${
                      isActive("/dashboard")
                        ? "bg-orange-900 hover:bg-orange-900 pointer-events-none"
                        : ""
                    }`}
            exact={true}
          >
            <FaUsers size={20} />
            <h1 className={`${sideBarOpen ? "block" : "hidden"}`}>Users</h1>
          </Link>
          <Link
            to="sweets"
            className={` flex items-center gap-2 w-full h-full pt-4 pb-3 pl-4 text-[16px] transition duration-300 text-white hover:bg-orange-800
                    ${
                      isActive("/dashboard/sweets")
                        ? "bg-orange-900 hover:bg-orange-900 pointer-events-none"
                        : ""
                    }`}
          >
            <GiKnifeFork size={20} />
            <h1 className={`${sideBarOpen ? "block" : "hidden"}`}>Sweets</h1>
          </Link>
          <Link
            to="visitors"
            className={` flex items-center gap-2 w-full h-full pt-4 pb-3 pl-4 text-[16px] transition duration-300 text-white hover:bg-orange-800
                    ${
                      isActive("/dashboard/visitors")
                        ? "bg-orange-900 hover:bg-orange-900 pointer-events-none"
                        : ""
                    }`}
          >
            <BsPersonVcardFill size={20} />
            <h1 className={`${sideBarOpen ? "block" : "hidden"}`}>Visitors</h1>
          </Link>
          <Link
            to="orders"
            className={`flex items-center gap-2 w-full h-full pt-4 pb-3 pl-4 text-[16px] transition duration-300 text-white hover:bg-orange-800
                    ${
                      isActive("/dashboard/orders") ||
                      isActive(`/dashboard/orders/${id}`)
                        ? "bg-orange-900 hover:bg-orange-900 pointer-events-none"
                        : ""
                    }`}
          >
            <FaCartShopping size={20} />
            <h1 className={`${sideBarOpen ? "block" : "hidden"}`}>Orders</h1>
          </Link>
          <Link
            to="reports"
            className={`flex items-center gap-2 w-full h-full pt-4 pb-3 pl-4 text-[16px] transition duration-300 text-white hover:bg-orange-800
                    ${
                      isActive("/dashboard/reports")
                        ? "bg-orange-900 hover:bg-orange-900 pointer-events-none"
                        : ""
                    }`}
          >
            <HiDocumentReport size={20} />
            <h1 className={`${sideBarOpen ? "block" : "hidden"}`}>Reports</h1>
          </Link>
        </nav>
      </div>
    </div>
  );
}

export default DashboardSidebar;
