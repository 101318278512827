import React from "react";
import Container from "../../Shared/Components/Container";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import TopBarLink from "./TopBarLink";

function TopBar() {
  const { t } = useTranslation();
  return (
    <div className={`bg-PrimaryColor text-white w-full py-[4px]`}>
      <Container>
        <div className="flex justify-between items-center w-full h-full min-h-[30px]">
          <div
            className={`mr-auto max-xl:mx-auto text-white uppercase font-bold text-[0.75em]`}
          >
            {t("#1 Online Sweet Store in malaysia")}
          </div>
          <div className={`ml-auto flex items-center max-xl:hidden`}>
            <div className={`flex items-center`}>
              <TopBarLink content={t("Home")} link="/" className="border-l-0" />
              <TopBarLink content={t("Contact")} link="#" />
              <TopBarLink content={t("Terms and Conditions")} link="#" />
              <TopBarLink content={t("Shipping & Refund Policy")} link="#" />
              <TopBarLink content={t("Privacy Policy")} link="#" />
              <TopBarLink content={t("FAQ")} link="#" />
              <div className="flex items-center gap-2 text-white text-[14px]">
                <NavLink
                  target="_blank"
                  to="https://www.facebook.com/share/XYHCRdFz71UVRyce/?mibextid=LQQJ4d"
                >
                  <FaFacebookF />
                </NavLink>
                <NavLink
                  target="_blank"
                  to="https://www.tiktok.com/@kunafafingers?_t=8mfnzDD5VJf"
                >
                  <FaTiktok />
                </NavLink>
                <NavLink
                  target="_blank"
                  to="https://www.instagram.com/kunafafingers?igsh=bG9kMDk1cmNmYXky"
                >
                  <FaInstagram />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default TopBar;

