import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function TopBarLink({ content, link, className }) {
  const location = useLocation();
  function isActive(link) {
    return location.pathname === link;
  }
  return (
    <NavLink
      onClick={() => console.log(link)}
      className={`
        ${isActive(`${link}`) ? "text-white font-semibold" : "text-[#ffffffcc]"}
           ${className} border-l border-l-[#ffffffcc]/20 px-4 text-[14px]`}
      to={link}
    >
      {content}
    </NavLink>
  );
}

export default TopBarLink;
