import React, { useState } from "react";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { updateCategory } from "../../Api/DashboardApi";
import { usePopup } from "../../Context/PopupContext";

function UpdateCategory({ id }) {
  const [categoryImage, setCategoryImage] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { setUpdateCategoryOpen } = usePopup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("url_media", categoryImage);
    try {
      setSubmitLoading(true);
      const response = await updateCategory(id, formData);
      console.log(response);
      setUpdateCategoryOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };
  return (
    <div className="max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col pb-[15px] bg-[#f7f7f7] "
      >
        <div className="flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-[18px] leading-[14px] h-[56px] md:sticky md:top-0 md:left-0 md:bg-MainBg">
          <div className="hover:bg-black/10 p-[5px] rounded-full">
            <AiOutlineClose
              className="cursor-pointer"
              onClick={() => {
                setUpdateCategoryOpen(false);
              }}
              size={26}
            />
          </div>
          <h1 className="md:py-[27px] text-MainText">Update Category</h1>
          <button
            type="submit"
            className={`${
              submitLoading && "pointer-events-none"
            } min-w-[45px] flex justify-center items-center h-[52px] text-[18px] cursor-pointer font-[700] text-PrimaryColor hover:underline duration-300`}
          >
            {submitLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Done"
            )}
          </button>
        </div>
        <div className="flex flex-col px-[15px] text-SecondText lg:bg-[#f7f7f7] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto">
          <div className="flex flex-col">
            <h1 className="mb-[0.25rem]">Category Image</h1>
            <input
              className="bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[2px] inputsAddressBoxShadow mb-[20px] focus:border-SecondText focus:shadow-none focus:outline-none duration-300 focus:ring-0"
              type="file"
              onChange={(e) => setCategoryImage(e.target.files[0])}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateCategory;
