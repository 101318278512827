import React, { useContext, useState } from "react";
import { FaHeart } from "react-icons/fa";
import pic1 from "../../assests/Imgs/kunafa1.png";
import { useTranslation } from "react-i18next";
import { AiFillEdit, AiOutlineLoading3Quarters } from "react-icons/ai";
import { addSweetItemToFavorite } from "../../Api/SweetApi";
import { usePopup } from "../../Context/PopupContext";
import Cookies from "js-cookie";
import { SweetContext } from "../../Context/SweetContext";
import { addItemToCart } from "../../Api/CartApi";
import { MdDelete } from "react-icons/md";
import { CategoriesContext } from "../../Context/CategoriesContext";

function SweetItem({
  id,
  favorite,
  pic,
  category,
  name,
  description,
  price,
  dashMode,
  tags,
  code,
  categoryId,
}) {
  const { t } = useTranslation();
  const lng = localStorage.getItem("kfLanguage") || "English";
  const [showHint, setShowHint] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const token = Cookies.get("kfToken");
  const {
    setCartOpen,
    setSweetItemDetailsOpen,
    setUpdateItemOpen,
    setMakeDecisionOpen,
    setAction,
    setType,
  } = usePopup();
  const {} = usePopup();
  const {
    setUpdateFavorite,
    setUpdateTotal,
    setUpdateCartItems,
    SetSweetItemId,
    youHaveToLogin,
    setSelectedSweetItem,
  } = useContext(SweetContext);

  const addToFavorite = async () => {
    if (!token) {
      youHaveToLogin();
    } else {
      try {
        setFavLoading(true);
        const response = await addSweetItemToFavorite(id);
        console.log(response);
        setUpdateFavorite((prev) => prev + 1);
      } catch (error) {
        console.error("add sweet item to favorite err : ", error);
      } finally {
        setFavLoading(false);
      }
    }
  };
  const addToCart = async () => {
    const formData = new FormData();
    formData.append("quantity", 1);
    formData.append("product_id", id);
    if (!token) {
      youHaveToLogin();
    } else {
      try {
        setAddLoading(true);
        const response = await addItemToCart(formData);
        console.log(response);
        setUpdateTotal((prev) => prev + 1);
        setUpdateCartItems((prev) => prev + 1);
        setCartOpen(true);
      } catch (err) {
        console.error("add item to cart err : ", err);
      } finally {
        setAddLoading(false);
      }
    }
  };
  const handleOpenDetails = async () => {
    await SetSweetItemId(id);
    !dashMode && setSweetItemDetailsOpen(true);
  };
  // Example threshold, adjust based on your needs
  const nameLengthThreshold = 26;

  // Determine if the name should scroll based on its length
  const shouldNameScroll = name.length > nameLengthThreshold;

  return (
    <div
      className={`group flex flex-col gap-1 items-center max-w-[230px] overflow-hidden`}
    >
      <div
        className={`flex flex-col relative ${
          !dashMode && "cursor-pointer"
        } w-full`}
      >
        <div
          onClick={() => {
            setSelectedSweetItem((prev) => ({ ...prev, id }));
            setMakeDecisionOpen(true);
            setAction("delete");
            setType("sweet item");
          }}
          className={`${
            dashMode && "group-hover:z-10 group-hover:opacity-100"
          } absolute -z-10 opacity-0 cursor-pointer border-[#666] text-[#666] hover:text-white hover:bg-PrimaryColor hover:border-PrimaryColor right-2 top-2 rounded-full w-[40px] h-[40px] flex justify-center items-center border-[2px] duration-300`}
        >
          <MdDelete size={20} />
        </div>
        <div
          onClick={() => {
            setSelectedSweetItem((prev) => ({
              ...prev,
              id: id,
              price: price,
              tags: tags,
              code: code,
              categoryId: categoryId,
            }));
            setUpdateItemOpen(true);
          }}
          className={`${
            dashMode && "group-hover:z-10 group-hover:opacity-100"
          } absolute -z-10 opacity-0 cursor-pointer border-[#666] text-[#666] hover:text-white hover:bg-PrimaryColor hover:border-PrimaryColor left-2 top-2 rounded-full w-[40px] h-[40px] flex justify-center items-center border-[2px] duration-300`}
        >
          <AiFillEdit size={20} />
        </div>

        {!dashMode && (
          <div
            onClick={addToFavorite}
            className={`${
              favorite
                ? "bg-red-600 border-red-600 text-white opacity-100 z-10"
                : "border-[#666] text-[#666] hover:bg-red-600 hover:border-red-600 hover:text-white md:opacity-0"
            } absolute -z-10 cursor-pointer right-2 top-2 rounded-full w-[40px] h-[40px] group-hover:z-10 group-hover:opacity-100 flex justify-center items-center border-[2px] duration-300`}
            onMouseEnter={() => setShowHint(true)} // Show hint on mouse enter
            onMouseLeave={() => setShowHint(false)} // Hide hint on mouse leave
          >
            <div className="relative">
              {favLoading ? (
                <AiOutlineLoading3Quarters className="animate-spin" size={20} />
              ) : (
                <FaHeart size={20} />
              )}
              <h1
                className={`${
                  showHint ? "block" : "hidden"
                } absolute top-full left-[50%] -translate-x-[50%] mt-[10px] bg-black p-1 text-[#f1f1f1] text-[0.9em] rounded-[5px] duration-300`}
              >
                {favorite ? t("Remove from wishlist") : t("Add to wishlist")}
              </h1>
            </div>
          </div>
        )}
        <div
          onClick={handleOpenDetails}
          className="w-full h-[200px] max-sm:h-[170px] overflow-hidden relative"
        >
          <img
            className={`w-full h-full group-hover:scale-110 duration-300`}
            src={pic}
            alt=""
          />
        </div>
        <div
          onClick={handleOpenDetails}
          className="flex flex-col items-center gap-1 text-center w-full"
        >
          <h1 className="uppercase text-[10px] text-[#777]">{category}</h1>
          <h2
            className={`${
              shouldNameScroll ? "marquee" : ""
            } text-[#2b2b2b] hover:text-GreenColor duration-300 text-[0.9em] font-[600] h-[20px]`}
          >
            {name}
          </h2>
          <div className="text-[0.8em] text-[#2b2b2b]">
            {lng === "Chinese"
              ? description.substring(0, 25)
              : description.substring(0, 53)}
            ...
          </div>
          <div className="text-[0.9em] text-[#2b2b2b] font-semibold">
            {parseFloat(price).toFixed(2)}RM
          </div>
        </div>
      </div>
      {!dashMode && (
        <button
          onClick={addToCart}
          className={`${
            addLoading && "pointer-events-none opacity-70"
          } px-3 py-1 min-w-[129px] flex justify-center items-center bg-white text-PrimaryColor font-semibold hover:text-white hover:bg-PrimaryColor duration-300 border-[2px] rounded-[10px] border-PrimaryColor`}
        >
          {addLoading ? (
            <AiOutlineLoading3Quarters className="animate-spin" size={23} />
          ) : (
            t("ADD TO CART")
          )}
        </button>
      )}
    </div>
  );
}

export default SweetItem;

