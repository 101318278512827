import React, { useEffect, useState } from "react";
import Container from "../../../Shared/Components/Container";
import { Link } from "react-router-dom";
import { BsFillBasketFill } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import SweetItem from "../../Components/SweetItem";
import { useTranslation } from "react-i18next";
import { fetchSuggestedSweets } from "../../../Api/SweetApi";
import SweetItemSkeleton from "../../Components/SkeletonLoading/SweetItemSkeleton";

function SuggestedForYou() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("kfLanguage") || "English";
  const language_id = localStorage.getItem("languageId");
  const [sweets, setSweets] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchSuggestedSweets(language_id);
      console.log(response.data);
      setSweets(response.data);
    } catch (error) {
      console.error("fetch suggested sweets err : ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [lng]);
  return (
    <div>
      <Container>
        <div className="flex items-center max-lg:flex-col gap-4 py-10">
          <div className="basis-[20%] max-lg:w-full flex flex-col items-center text-center uppercase gap-5 p-[30px] bg-PrimaryColor text-white text-[120%]">
            <h1 className="font-bold leading-[1.25]">
              {t("Suggested for You")}
            </h1>
            <Link className="font-bold flex justify-center items-center flex-nowrap min-w-[120px] gap-2 px-3 py-1 rounded-[10px] bg-[#79a218] hover:bg-[#608113] border border-[#79a218] text-[12px] w-fit duration-300">
              {t("view deals")}
              <BsFillBasketFill />
            </Link>
          </div>
          <div
            style={{ direction: "ltr" }}
            className="mainSwiper py-5 -z-0 w-[80%] max-lg:w-full"
          >
            <Swiper
              className="h-full"
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={20}
              speed={100}
              navigation
              autoplay
              loop
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                },
                850: {
                  slidesPerView: 5,
                },
                550: {
                  slidesPerView: 3,
                },
                0: {
                  slidesPerView: 2,
                },
              }}
            >
              {loading
                ? Array.from({ length: 7 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <SweetItemSkeleton />
                    </SwiperSlide>
                  ))
                : sweets.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div key={item.id}>
                        <SweetItem
                          id={item.id}
                          favorite={item?.FavoriteProduct?.[0]?.is_favorite}
                          pic={item?.productsMedia?.[0]?.url_media}
                          picFlip={item?.productsMedia?.[1]?.url_media}
                          name={item.name}
                          category={item.category[0].name}
                          description={item.description}
                          price={item.price}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SuggestedForYou;
