import React, { useContext, useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Container from "../../Shared/Components/Container";
import SweetItem from "../Components/SweetItem";
import Cookies from "js-cookie";
import {
  fetchSweetsByCategory,
  fetchSweetsByCategoryUser,
} from "../../Api/SweetApi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { CategoriesContext } from "../../Context/CategoriesContext";
import SweetItemSkeleton from "../Components/SkeletonLoading/SweetItemSkeleton";
import { usePopup } from "../../Context/PopupContext";
import SweetItemDetails from "../Components/SweetItemDetails";
import Skeleton from "react-loading-skeleton";

function SweetsByCategory() {
  const language_id = localStorage.getItem("languageId");
  const lng = localStorage.getItem("kfLanguage") || "English";
  const [sweets, setSweets] = useState([]);
  const token = Cookies.get("kfToken");
  const { categories, loading, categoryId, setCategoryId } =
    useContext(CategoriesContext);
  const [sweetsLoading, setSweetsLoading] = useState(true);
  const { sweetItemDetailsOpen } = usePopup();
  const fetchSweets = async (categoryId) => {
    try {
      setSweetsLoading(true);
      const response = token
        ? await fetchSweetsByCategoryUser(language_id, categoryId)
        : await fetchSweetsByCategory(language_id, categoryId);
      console.log(response.data);
      setSweets(response.data);
    } catch (error) {
      console.error("get sweets by category err : ", error);
    } finally {
      setSweetsLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchSweets(categoryId);
  }, [categoryId, lng]);
  return (
    <div>
      <Header />
      <Container>
        <div className="flex flex-col gap-2 min-h-[700px]">
          <div style={{ direction: "ltr" }} className="mainSwiper pt-5 -z-0">
            <Swiper
              className="h-full"
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={20}
              speen={100}
              navigation
              loop
              breakpoints={{
                850: {
                  slidesPerView: 5,
                },
                550: {
                  slidesPerView: 3,
                },
                0: {
                  slidesPerView: 2,
                },
              }}
              initialSlide={categoryId - 1}
            >
              {loading
                ? Array.from({ length: 8 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div className="w-[100px] h-[40px] rounded-[10px] mx-auto">
                        <Skeleton width={`100%`} height={`100%`} />
                      </div>
                    </SwiperSlide>
                  ))
                : categories.length > 0 &&
                  categories.map((item, index) => (
                    <SwiperSlide
                      className=""
                      onClick={() => setCategoryId(item.id)}
                      key={item.id}
                    >
                      <div className="flex justify-center items-center h-[50px]">
                        <div
                          className={`${
                            categoryId === index + 1 &&
                            "bg-PrimaryColor text-white pointer-events-none"
                          } border-PrimaryColor hover:bg-PrimaryColor hover:text-white duration-300 py-1 px-2 rounded-[10px] cursor-pointer font-bold border`}
                        >
                          {item.name}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
          <div className="py-5 grid grid-cols-5 max-lap:grid-cols-3 max-mob:grid-cols-2 gap-x-5 gap-y-8">
            {sweetsLoading
              ? Array.from({ length: 10 }).map((_, index) => (
                  <div key={index}>
                    <SweetItemSkeleton />
                  </div>
                ))
              : sweets.length > 0 &&
                sweets.map((item) => (
                  <div key={item.id}>
                    <SweetItem
                      id={item.id}
                      favorite={item?.FavoriteProduct?.[0]?.is_favorite}
                      pic={item?.productsMedia?.[0]?.url_media}
                      picFlip={item?.productsMedia?.[1]?.url_media}
                      name={item.name}
                      category={item.category[0].name}
                      description={item.description}
                      price={item.price}
                    />
                  </div>
                ))}
          </div>
        </div>
      </Container>
      <Footer />
      {sweetItemDetailsOpen && <SweetItemDetails />}
    </div>
  );
}

export default SweetsByCategory;
