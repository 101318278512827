import axios from "axios";
import { BASE_URL } from "./api";
import Cookies from "js-cookie"
import { toastError, toastSuccess } from "../Shared/Components/ToastNotification/Toast";

export const adminToken = Cookies.get("kfAdminToken");

const Admin = adminToken;

export const apiAdmin = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Admin}`,
  },
});

export const adminLogin = async (data) => {
  try {
    const response = await apiAdmin.post(
      `/api/loginAdmin`,data
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
      console.error("admin login message err : ", error);
      toastError(error.response.data.errors)
  }
};

export const forgetPassword = async (data) => {
  try {
    const response = await apiAdmin.post(
      `/api/forgetPassword`,data
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
      console.error("forget password err : ", error);
      toastError(error.response.data.errors)
  }
};

export const fetchUsers = async () => {
  try {
    const response = await apiAdmin.get(`/api/users`);
    return response.data;
  } catch (error) {
    console.error("fetch users err : ", error);
  }
};

export const addNewCategory = async (data) => {
  try {
    const response = await apiAdmin.post(
      `/api/Category`,data  ,{headers: {
        "Content-Type": "multipart/form-data",
      },}
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
      console.error("add new category err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const updateCategory = async (categoryId,data) => {
  try {
    const response = await apiAdmin.post(
      `/api/Update-Category?category_id=${categoryId}`,data  ,{headers: {
        "Content-Type": "multipart/form-data",
      },}
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
      console.error("update category err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    const response = await apiAdmin.delete(
      `/api/delete-Category?category_id=${categoryId}`  
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
      console.error("delete category err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const addNewItem = async (data) => {
  try {
    const response = await apiAdmin.post(
      `/api/Product`,data  ,{headers: {
        "Content-Type": "multipart/form-data",
      },}
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
      console.error("add new sweet item err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const updateItem = async (itemId,data) => {
  try {
    const response = await apiAdmin.post(
      `/api/update-Product?product_id=${itemId}`,data  ,{headers: {
        "Content-Type": "multipart/form-data",
      },}
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
      console.error("update sweet item err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const deleteItem = async (itemId) => {
  try {
    const response = await apiAdmin.delete(
      `/api/delete-Product?product_id=${itemId}`  
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
      console.error("delete sweet item err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const fetchVisitors = async () => {
  try {
    const response = await apiAdmin.get(`/api/userInfo`);
    return response.data;
  } catch (error) {
    console.error("fetch users err : ", error);
  }
};

export const fetchAllOrders = async () => {
  try {
    const response = await apiAdmin.get(`/api/orders`);
    return response.data;
  } catch (error) {
    console.error("fetch all orders err : ", error);
  }
};

export const fetchOrdersByDate = async (startDate,endDate) => {
  try {
    const response = await apiAdmin.get(`/api/SortOrder?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (error) {
    console.error("fetch orders by date err : ", error);
  }
};

export const fetchOrdersByStatus= async (status_id) => {
  try {
    const response = await apiAdmin.get(`/api/filterOrder?status_id=${status_id}`);
    return response.data;
  } catch (error) {
    console.error("fetch filter orders err : ", error);
  }
};

export const fetchOrderById= async (orderId) => {
  try {
    const response = await apiAdmin.get(`/api/OrderDetails?order_id=${orderId}`);
    return response.data;
  } catch (error) {
    console.error("fetch order by id err : ", error);
  }
};

export const fetchReports = async (startDate, endDate) => {
  try {
    const response = await apiAdmin.get(
      `/api/orders-export?start_date=${startDate}&end_date=${endDate}`,
      { responseType: "arraybuffer" }
    );
    return response;
  } catch (error) {
    console.error("fetch reports err : ", error);
  }
};

