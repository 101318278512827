import React, { useContext } from "react";
import { SweetContext } from "../../Context/SweetContext";
import { usePopup } from "../../Context/PopupContext";

function SearchItem({ id, image, name, price }) {
  const { SetSweetItemId } = useContext(SweetContext);
  const { setSearchOpen, setSideBarOpen, setSweetItemDetailsOpen } = usePopup();
  return (
    <div
      onClick={() => {
        SetSweetItemId(id);
        setSearchOpen(false);
        setSideBarOpen(false);
        setSweetItemDetailsOpen(true);
        console.log("fffffff");
      }}
      className="bg-white hover:bg-[#dedbd5] cursor-pointer duration-300 p-[10px] border-b border-gray-200 flex md:justify-between md:items-center max-md:flex-col gap-2"
    >
      <div className="flex items-center gap-2">
        <div className="w-[40px] h-[40px]">
          <img className="w-full h-full" src={image} alt="" />
        </div>
        <div className="text-[#333]  text-[14px]">{name}</div>
      </div>
      <div className="font-[600] text-[12px]">{price} RM</div>
    </div>
  );
}

export default SearchItem;
