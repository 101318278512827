import { toastSuccess } from "../Shared/Components/ToastNotification/Toast";
import { apiClient } from "./api";

export const fetchMainSectionData = async (language_id = 1) => {
  try {
    const response = await apiClient.get(`/api/Slider?language_id=${language_id}`);
    return response.data;
  } catch (error) {
    console.error("fetch main section data err : ", error);
  }
};

export const fetchCategoriesCollection = async (language_id = 1) => {
  try {
    const response = await apiClient.get(`/api/Categories?language_id=${language_id}`);
    return response.data;
  } catch (error) {
    console.error("fetch categories err : ", error);
  }
};

export const fetchSweetsCollection = async (language_id = 1) => {
  try {
    const response = await apiClient.get(`/api/Products?language_id=${language_id}`);
    return response.data;
  } catch (error) {
    console.error("fetch sweet collection err : ", error);
  }
};

export const fetchSweetsCollectionUser = async (language_id = 1) => {
  try {
    const response = await apiClient.get(`/api/getProductsUser?language_id=${language_id}`);
    return response.data;
  } catch (error) {
    console.error("fetch user sweet collection err : ", error);
  }
};

export const fetchSweetsByCategory = async (language_id = 1, category_id = 1) => {
  try {
    const response = await apiClient.get(
      `/api/ProductsByCategory?language_id=${language_id}&category_id=${category_id}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch sweets by category : ", error);
  }
};

export const fetchSweetsByCategoryUser = async (language_id = 1, category_id = 1) => {
  try {
    const response = await apiClient.get(
      `/api/getProductByCategory?language_id=${language_id}&category_id=${category_id}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch user sweets by category : ", error);
  }
};

export const fetchSweetById = async (language_id = 1, product_id) => {
  try {
    const response = await apiClient.get(
      `/api/ProductByID?language_id=${language_id}&product_id=${product_id}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch sweet item by id err : ", error);
  }
};

export const fetchSweetByIdUser = async (language_id = 1, product_id) => {
  try {
    const response = await apiClient.get(
      `/api/ProductByIDUser?language_id=${language_id}&product_id=${product_id}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch sweet item by id user err : ", error);
  }
};

export const addSweetItemToFavorite = async (product_id) => {
  try {
    const response = await apiClient.post(
      `/api/favorite-products?product_id=${product_id}`
    );
    toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
    console.error("add sweet item to favorite err : ", error);
  }
};

export const fetchFavoriteSweetsCount = async () => {
  try {
    const response = await apiClient.get(
      `/api/getFavoriteProductCount`
    );
    return response.data;
  } catch (error) {
    console.error("fetch favorite count err : ", error);
  }
};

export const fetchFavoriteSweets = async (language_id = 1) => {
  try {
    const response = await apiClient.get(
      `/api/favorite-products?language_id=${language_id}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch favorite sweets err : ", error);
  }
};

export const fetchSuggestedSweets = async (language_id = 1) => {
  try {
    const response = await apiClient.get(
      `/api/RandomProduct?language_id=${language_id}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch suggested sweets err : ", error);
  }
};

export const searchCurious = async (query,category_id,language_id = 1,session_id) => {
  try {
    const response = await apiClient.get(
      `/api/search?query=${query}&category_id=${category_id}&language_id=${language_id}&session_id=${session_id}`
    );
    return response.data;
  } catch (error) {
    console.error("search curious err : ", error);
  }
};

export const searchUser = async (query,category_id,language_id = 1) => {
  try {
    const response = await apiClient.get(
      `/api/searchUser?language_id=${language_id}&query=${query}&category_id=${category_id}`
    );
    return response.data;
  } catch (error) {
    console.error("search user err : ", error);
  }
};

