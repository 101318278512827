import React, { useContext, useEffect, useState } from "react";
import { fetchVisitors } from "../../Api/DashboardApi";
import { SweetContext } from "../../Context/SweetContext";
import LoadingPage from "../../Shared/Components/LoadingPage";
import { useTheme } from "../../Context/ThemeContext";
import Skeleton from "react-loading-skeleton";

function VisitorsView() {
  const { theme } = useTheme();
  const [visitors, setVisitors] = useState([]);
  const { lazyLoading, loading, setLoading } = useContext(SweetContext);
  const getVisitors = async () => {
    try {
      setLoading(true);
      const response = await fetchVisitors();
      console.log(response.data);
      setVisitors(response.data);
      setLoading(false);
    } catch (error) {
      console.error("fetch visitors data error : ", error);
    }
  };
  useEffect(() => {
    lazyLoading();
    getVisitors();
  }, []);
  return loading ? (
    <LoadingPage />
  ) : (
    <div>
      <h1 className="text-2xl font-semibold">Website Visitors</h1>
      <div className="mt-4">
        <table
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
          } w-full table-auto`}
        >
          <thead>
            <tr>
              <th className="border px-4 py-2">Id</th>
              <th className="border px-4 py-2">Ip Address</th>
              <th className="border px-4 py-2">Request Time</th>
              <th className="border px-4 py-2">Count</th>
              <th className="border px-4 py-2">Country</th>
              <th className="border px-4 py-2">Region</th>
              <th className="border px-4 py-2">City</th>
            </tr>
          </thead>
          <tbody>
            {visitors && visitors.length > 0 ? (
              visitors.map((visitor, index) => (
                <tr key={index}>
                  <td className="border text-center px-4 py-2">{visitor.id}</td>
                  <td className="border text-center px-4 py-2">
                    {visitor.ip_address}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {visitor.request_time}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {visitor.count}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {visitor.countryName}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {visitor.regionName}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {visitor.cityName}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                {Array.from({ length: 7 }).map((_, index) => (
                  <td key={index}>
                    <Skeleton width={"100%"} height={35} />
                  </td>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default VisitorsView;
