import React from "react";
import { HiShoppingBag } from "react-icons/hi";
import Cookies from "js-cookie";

function CartButton({ onClick, text, value, totalItems, loading }) {
  const token = Cookies.get("kfToken");
  return (
    <div
      onClick={onClick}
      className={`${loading && "opacity-50 pointer-events-none"} ${
        token && "relative"
      } bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 py-1 px-2 rounded-[6px] cursor-pointer text-white font-semibold flex justify-center items-center flex-nowrap gap-1`}
    >
      {text}
      {value} <HiShoppingBag />
      {!loading && totalItems > 0 && (
        <div className="absolute -top-3 -left-3 rounded-full p-1 text-[10px] min-w-[25px] min-h-[25px] flex justify-center items-center bg-[#79a218]">
          {totalItems}
        </div>
      )}
    </div>
  );
}

export default CartButton;
