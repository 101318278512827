import React from "react";

function Container({ className, children }) {
  return (
    <div className={`${className} max-w-[1230px] mx-auto max-xl:px-2 `}>
      {children}
    </div>
  );
}

export default Container;
