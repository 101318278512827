import React, { useContext, useState } from "react";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTheme } from "../../Context/ThemeContext";
import { usePopup } from "../../Context/PopupContext";
import { CategoriesContext } from "../../Context/CategoriesContext";
import { updateItem } from "../../Api/DashboardApi";

function UpdateItem({ id, price, tags, code, categoryId }) {
  const { theme } = useTheme();
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [item, setItem] = useState({
    name_en: "",
    description_en: "",
    name_zh: "",
    description_zh: "",
    name_ms: "",
    description_ms: "",
    name_ar: "",
    description_ar: "",
    price: price,
    tags: tags,
    code: code,
    category_id: categoryId,
    new_price: "",
    url_media: "",
  });

  const { setUpdateItemOpen } = usePopup();
  const { categories, setCategoryId } = useContext(CategoriesContext);

  const handleChange = async (e) => {
    setItem((item) => ({ ...item, [e.target.name]: e.target.value }));
  };

  const handleChangeImg = async (e) => {
    setItem((item) => ({ ...item, [e.target.name]: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name_en", item.name_en);
    formData.append("description_en", item.description_en);
    formData.append("name_zh", item.name_zh);
    formData.append("description_zh", item.description_zh);
    formData.append("name_ms", item.name_ms);
    formData.append("description_ms", item.description_ms);
    formData.append("name_ar", item.name_ar);
    formData.append("description_ar", item.description_ar);
    formData.append("price", item.price);
    formData.append("tags", item.tags);
    formData.append("code", item.code);
    formData.append("category_id", item.category_id);
    formData.append("new_price", item.new_price);
    formData.append("url_media", item.url_media);

    try {
      setSpinnerLoad(true);
      const response = await updateItem(id, formData);
      console.log(response);
      setUpdateItemOpen(false);
      setCategoryId(item.category_id);
    } catch (err) {
      console.log("update item err : ", err);
    } finally {
      setSpinnerLoad(false);
    }
  };

  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkBoxBg"
      } max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full`}
    >
      <form
        onSubmit={handleSubmit}
        className={`${
          theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-[#f7f7f7]"
        } flex flex-col pb-[15px]`}
      >
        <div
          className={`${
            theme === "dark" ? "bg-white" : "bg-white"
          } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-[18px] leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
        >
          <div
            className={`${
              theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
            } p-[5px] rounded-full`}
          >
            <AiOutlineClose
              className="cursor-pointer"
              onClick={() => {
                setUpdateItemOpen(false);
              }}
              size={26}
            />
          </div>
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } md:py-[27px]`}
          >
            Update Item
          </h1>
          <button
            type="submit"
            className="min-w-[45px] flex justify-center items-center h-[52px] text-[18px] cursor-pointer font-[700] text-PrimaryColor hover:underline duration-300"
          >
            {spinnerLoad ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Done"
            )}
          </button>
        </div>
        <div
          className={`${
            theme === "dark"
              ? "text-DarkMainText/70 bg-DarkBoxBg"
              : "text-SecondText lg:bg-[#f7f7f7]"
          } flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto`}
        >
          <div className="flex flex-col">
            <h1 className="mb-[0.25rem]">Category</h1>
            <select
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.category_id}
              name="category_id"
              onChange={handleChange}
              placeholder="Category"
            >
              <option disabled value="">
                select
              </option>
              {categories &&
                categories.length > 0 &&
                categories.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <h1 className="mb-[0.25rem]">Item Image</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="file"
              name="url_media"
              onChange={handleChangeImg}
              placeholder="Item Image"
            />
            <h1 className="mb-[0.25rem]">Name in english</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.name_en}
              name="name_en"
              onChange={handleChange}
              placeholder="Name in english"
            />

            <h1 className="mb-[0.25rem]">Description in english</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.description_en}
              name="description_en"
              onChange={handleChange}
              placeholder="Description in english"
            />
            <h1 className="mb-[0.25rem]">Name in chineese</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.name_zh}
              name="name_zh"
              onChange={handleChange}
              placeholder="Name in chineese"
            />

            <h1 className="mb-[0.25rem]">Description in chineese</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.description_zh}
              name="description_zh"
              onChange={handleChange}
              placeholder="Description in chineese"
            />
            <h1 className="mb-[0.25rem]">Name in malayisian</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.name_ms}
              name="name_ms"
              onChange={handleChange}
              placeholder="Name in malayisian"
            />

            <h1 className="mb-[0.25rem]">Description in malayisian</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.description_ms}
              name="description_ms"
              onChange={handleChange}
              placeholder="Description in malayisian"
            />
            <h1 className="mb-[0.25rem]">Name in arabic</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.name_ar}
              name="name_ar"
              onChange={handleChange}
              placeholder="Name in arabic"
            />

            <h1 className="mb-[0.25rem]">Description in arabic</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.description_ar}
              name="description_ar"
              onChange={handleChange}
              placeholder="Description in arabic"
            />
            <h1 className="mb-[0.25rem]">Price</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="number"
              value={item.price}
              name="price"
              onChange={handleChange}
              placeholder="Price"
            />
            <h1 className="mb-[0.25rem]">Tags</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.tags}
              name="tags"
              onChange={handleChange}
              placeholder="Tags"
            />
            <h1 className="mb-[0.25rem]">Code</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              value={item.code}
              name="code"
              onChange={handleChange}
              placeholder="Code"
            />
            <h1 className="mb-[0.25rem]">New Price</h1>
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="number"
              value={item.new_price}
              name="new_price"
              onChange={handleChange}
              placeholder="New Price"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateItem;
