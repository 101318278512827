import React, { useContext, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { SweetContext } from "../../Context/SweetContext";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  addItemToCart,
  deleteItemFromCart,
  updateItemInCart,
} from "../../Api/CartApi";
import { addSweetItemToFavorite } from "../../Api/SweetApi";
import { Link, useNavigate } from "react-router-dom";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useTheme } from "../../Context/ThemeContext";
import { usePopup } from "../../Context/PopupContext";

function CartItemLap({ id, image, name, price, count, subTotal, edit }) {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [quantity, setquantity] = useState(edit ? parseFloat(count) : 1);
  const {
    totalItems,
    setUpdateTotal,
    setUpdateFavorite,
    setUpdateCartItems,
    setUpdateCartInfo,
    SetSweetItemId,
  } = useContext(SweetContext);
  const { setSweetItemDetailsOpen } = usePopup();
  const removeFromFavorite = async () => {
    try {
      setFavLoading(true);
      const response = await addSweetItemToFavorite(id);
      console.log(response);
      setUpdateFavorite((prev) => prev + 1);
    } catch (error) {
      console.error("add sweet item to favorite err : ", error);
    } finally {
      setFavLoading(false);
    }
  };
  const addToCart = async () => {
    const formData = new FormData();
    formData.append("quantity", quantity);
    formData.append("product_id", id);
    try {
      setAddLoading(true);
      const response = await addItemToCart(formData);
      console.log(response);
      setUpdateTotal((prev) => prev + 1);
    } catch (err) {
      console.error("add item to cart err : ", err);
    } finally {
      setAddLoading(false);
    }
  };
  const editItemInCart = async () => {
    const formData = new FormData();
    formData.append("quantity", quantity);
    try {
      setEditLoading(true);
      const response = await updateItemInCart(id, formData);
      console.log(response);
      setUpdateCartInfo((prev) => prev + 1);
      setUpdateCartItems((prev) => prev + 1);
      setUpdateTotal((prev) => prev + 1);
    } catch (error) {
      console.error("edit item in cart err :", error);
    } finally {
      setEditLoading(false);
    }
  };
  const deleteItem = async () => {
    try {
      setDeleteLoading(true);
      const response = await deleteItemFromCart(id);
      console.log(response);
      setUpdateCartInfo((prev) => prev + 1);
      setUpdateCartItems((prev) => prev + 1);
      setUpdateTotal((prev) => prev + 1);
    } catch (error) {
      console.error("delete item from cart err : ", error);
    } finally {
      setDeleteLoading(false);
      parseFloat(totalItems) === 0 && navigate("/");
    }
  };
  return (
    <tr>
      <td>
        {favLoading || deleteLoading ? (
          <AiOutlineLoading3Quarters className="animate-spin" size={25} />
        ) : (
          <IoIosCloseCircleOutline
            onClick={edit ? deleteItem : removeFromFavorite}
            className="cursor-pointer text-[#ccc] hover:text-black duration-300"
            size={25}
          />
        )}
      </td>
      <td className="w-[75px] h-[90px] leading-[1.05] tracking-[0.05em] p-[7px] text-[14px]">
        <img className="w-full h-full" src={image} alt="" />
      </td>
      <td className="leading-[1.05] tracking-[0.05em] p-[7px] text-[14px]">
        <Link
          onClick={() => {
            SetSweetItemId(id);
            setSweetItemDetailsOpen(true);
          }}
          className="text-[#2b2b2b] hover:text-GreenColor duration-300 "
        >
          {name}
        </Link>
      </td>
      <td className="font-[700] leading-[1.05] tracking-[0.05em] p-[7px] text-[14px]">
        {price} RM
      </td>
      <td className="leading-[1.05] tracking-[0.05em] p-[7px] text-[14px]">
        <div className="flex items-center gap-4">
          <FaMinus
            onClick={() => quantity !== 1 && setquantity((prev) => prev - 1)}
            className="cursor-pointer hover:text-red-600 duration-300"
          />
          <div className="font-semibold">{quantity}</div>
          <FaPlus
            onClick={() => setquantity((prev) => prev + 1)}
            className="cursor-pointer hover:text-GreenColor duration-300"
          />
        </div>
      </td>
      {edit && (
        <td className="font-[700] leading-[1.05] tracking-[0.05em] p-[7px] text-[14px]">
          {subTotal} RM
        </td>
      )}

      <td className="leading-[1.05] tracking-[0.05em] p-[7px] text-[14px] min-w-[100px]">
        <h1
          onClick={edit ? editItemInCart : addToCart}
          className={`${
            (addLoading || editLoading) && "pointer-events-none"
          } cursor-pointer relative flex justify-center items-center hover:text-GreenColor duration-300`}
        >
          <div
            className={`absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]`}
          >
            {addLoading || editLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" size={20} />
            ) : (
              <div>{edit ? t("Save changes") : t("Add to cart")}</div>
            )}
          </div>
        </h1>
      </td>
    </tr>
  );
}

export default CartItemLap;

