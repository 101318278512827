import React, { useContext, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Popup from "../../Shared/Components/Popup";
import { FaHeart, FaMinus, FaPlus } from "react-icons/fa";
import { SweetContext } from "../../Context/SweetContext";
import { usePopup } from "../../Context/PopupContext";
import { addItemToCart } from "../../Api/CartApi";
import Cookies from "js-cookie";
import {
  addSweetItemToFavorite,
  fetchSweetById,
  fetchSweetByIdUser,
} from "../../Api/SweetApi";
import { useTranslation } from "react-i18next";
import SweetItemDetailsSkeleton from "./SkeletonLoading/SweetItemDetailsSkeleton";

function SweetItemDetails() {
  const { t } = useTranslation();
  const { setCartOpen, sweetItemDetailsOpen, setSweetItemDetailsOpen } =
    usePopup();
  const {
    setUpdateTotal,
    setUpdateCartItems,
    sweetItemId,
    youHaveToLogin,
    setUpdateFavorite,
  } = useContext(SweetContext);
  useEffect(() => {
    console.log(sweetItemId);
  }, [sweetItemId]);
  const [dataLoading, setDataLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [sweetItem, setSweetItem] = useState();
  const [updateData, setUpdateData] = useState(1);
  const token = Cookies.get("kfToken");
  const language_id = localStorage.getItem("languageId");
  const fetchData = async () => {
    try {
      setDataLoading(true);
      const response = token
        ? await fetchSweetByIdUser(language_id, sweetItemId)
        : await fetchSweetById(language_id, sweetItemId);
      console.log(response.data);
      setSweetItem(response.data);
    } catch (error) {
      console.error("fetch sweet by id err : ", error);
    } finally {
      setDataLoading(false);
    }
  };
  const addToCart = async () => {
    const formData = new FormData();
    formData.append("quantity", count);
    formData.append("product_id", sweetItemId);
    if (!token) {
      youHaveToLogin();
      setSweetItemDetailsOpen(false);
    } else {
      try {
        setAddLoading(true);
        const response = await addItemToCart(formData);
        console.log(response);
        setUpdateTotal((prev) => prev + 1);
        setUpdateCartItems((prev) => prev + 1);
        setCartOpen(true);
      } catch (err) {
        console.error("add item to cart err : ", err);
      } finally {
        setAddLoading(false);
      }
    }
  };
  const addToFavorite = async (id) => {
    if (!token) {
      youHaveToLogin();
    } else {
      try {
        setFavLoading(true);
        const response = await addSweetItemToFavorite(id);
        console.log(response);
        setUpdateFavorite((prev) => prev + 1);
        setUpdateData((prev) => prev + 1);
      } catch (error) {
        console.error("add sweet item to favorite err : ", error);
      } finally {
        setFavLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [updateData]);
  return (
    <Popup
      toggleSwitch={sweetItemDetailsOpen}
      onClick={() => setSweetItemDetailsOpen(false)}
      className={`${
        sweetItemDetailsOpen
          ? "left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] opacity-100"
          : "-left-full opacity-0"
      } lg:max-w-[500px] max-sm:w-[95%] h-[600px] max-h-[80%] rounded-[10px] overflow-y-scroll scrollbar-w-0 scrollbar z-30 shadow-2xl`}
      zIndex={`z-20`}
      children={
        dataLoading ? (
          <SweetItemDetailsSkeleton />
        ) : (
          <div className="flex flex-col">
            <div className="relative">
              <div
                onClick={() => addToFavorite(sweetItem?.id)}
                className={`${
                  sweetItem?.FavoriteProduct?.[0]?.is_favorite
                    ? "bg-red-600 border-red-600 text-white"
                    : "border-[#666] text-[#666] hover:bg-red-600 hover:border-red-600 hover:text-white"
                } absolute z-10 cursor-pointer group-hover:opacity-100 right-4 top-4 rounded-full w-[40px] h-[40px] flex justify-center items-center border-[2px] duration-300`}
                onMouseEnter={() => setShowHint(true)} // Show hint on mouse enter
                onMouseLeave={() => setShowHint(false)} // Hide hint on mouse leave
              >
                <div className="relative">
                  {favLoading ? (
                    <AiOutlineLoading3Quarters
                      className="animate-spin"
                      size={20}
                    />
                  ) : (
                    <FaHeart size={20} />
                  )}
                  <h1
                    className={`${
                      showHint ? "block" : "hidden"
                    } absolute top-full left-[50%] -translate-x-[50%] mt-[10px] bg-black p-1 text-[#f1f1f1] text-[0.9em] rounded-[5px] duration-300`}
                  >
                    {sweetItem?.FavoriteProduct?.[0]?.is_favorite
                      ? t("Remove from wishlist")
                      : t("Add to wishlist")}
                  </h1>
                </div>
              </div>
              <img
                className="w-full h-full object-cover object-center"
                src={sweetItem?.productsMedia?.[0]?.url_media}
                alt=""
              />
            </div>
            <div className="p-2 flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <div className="text-[#2b2b2b] text-[0.9em] font-[600]">
                  {sweetItem?.name}
                </div>
                <h1 className="uppercase text-[10px] text-[#777]">
                  {sweetItem?.category[0]?.name}
                </h1>
              </div>
              <div className="text-[0.8em] text-[#2b2b2b]">
                {sweetItem?.description}
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[14px]">{t("Price")}:</div>
                <div className="text-[0.9em] text-[#2b2b2b] font-semibold">
                  {parseFloat(sweetItem?.price).toFixed(2)} RM
                </div>
              </div>
            </div>
            <div className="p-2 flex flex-col gap-2 pt-2 border-t-[3px] border-t-[#ececec]">
              <h1 className="text-[14px] font-semibold text-[#2b2b2b]">
                {t("Calories")}:
              </h1>
              {sweetItem?.calories?.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between border-b-[1px] border-b-[#ececec] pt-2"
                >
                  <div className="text-[14px]">{item.key}</div>
                  <div className="text-[0.9em] text-[#2b2b2b] font-semibold">
                    {item.value} {t("Cal")}
                  </div>
                </div>
              ))}
            </div>
            <div className="p-2 bg-white flex items-center gap-4 sticky w-full bottom-0 left-0 border-[3px] border-b-[#ececec]">
              <div className="flex items-center gap-4">
                <FaMinus
                  onClick={() => count !== 1 && setCount((prev) => prev - 1)}
                  className="cursor-pointer hover:text-red-600 duration-300"
                />
                <div className="font-semibold">{count}</div>
                <FaPlus
                  onClick={() => setCount((prev) => prev + 1)}
                  className="cursor-pointer hover:text-GreenColor duration-300"
                />
              </div>
              <button
                onClick={addToCart}
                className={`${
                  addLoading && "pointer-events-none opacity-70"
                } px-3 py-1 min-w-[129px] w-full flex justify-center items-center bg-white text-PrimaryColor font-semibold hover:text-white hover:bg-PrimaryColor duration-300 border-[2px] rounded-[10px] border-PrimaryColor`}
              >
                {addLoading ? (
                  <AiOutlineLoading3Quarters
                    className="animate-spin"
                    size={25}
                  />
                ) : (
                  t("ADD TO CART")
                )}
              </button>
            </div>
          </div>
        )
      }
    />
  );
}

export default SweetItemDetails;

