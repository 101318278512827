import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import "../src/Translation/i18n";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./Context/ThemeContext";
import PopupProvider from "./Context/PopupContext";
import { ToastContainer } from "react-toastify";
import SweetProvider from "./Context/SweetContext";
import CategoriesProvider from "./Context/CategoriesContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PopupProvider>
      <ThemeProvider>
        <CategoriesProvider>
        <SweetProvider>
        <ToastContainer />
        <App />
        </SweetProvider>
        </CategoriesProvider>
      </ThemeProvider>
    </PopupProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
