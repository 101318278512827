import { toastError, toastSuccess } from "../Shared/Components/ToastNotification/Toast";
import { apiClient } from "./api";

export const fetchtotalCart = async () => {
  try {
    const response = await apiClient.get(
      `api/TotalCart`
    );
    return response.data;
  } catch (error) {
    console.error("fetch total cart err : ", error);
  }
};

export const addItemToCart = async (data) => {
  try {
    const response = await apiClient.post(
      `/api/addCartItem`,data
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
    console.error("add item to cart err : ", error);
  }
};

export const updateItemInCart = async (itemId,data) => {
  try {
    const response = await apiClient.post(
      `/api/updateCartItem?id=${itemId}`,data
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
    console.error("update item in cart err : ", error);
  }
};

export const deleteItemFromCart = async (itemId) => {
  try {
    const response = await apiClient.delete(
      `/api/deleteCartItem?id=${itemId}`
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
    console.error("delete item from cart err : ", error);
  }
};

export const fetchCartItemInfo = async (language_id = 1) => {
  try {
    const response = await apiClient.get(
      `/api/CartItem?page=1&language_id=${language_id}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch cart item info err : ", error);
  }
};

export const verifyCoupon = async (data) => {
  try {
    const response = await apiClient.post(
      `/api/verifyCode`,data
      );
      toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
    console.error("verifyCoupon err : ", error);
    toastError(error.response.data.data[0])
  }
};