import React from "react";
import { Link } from "react-router-dom";
import { BsFillBasketFill } from "react-icons/bs";

function MainSectionContent({
  pic,
  bgOpacity,
  textTop,
  textTopClassName,
  textCenter,
  textCenterClassName,
  hasButton,
  hasIcon,
  textButton,
  buttonClassName,
  link,
  onClick,
}) {
  return (
    <div className="flex justify-center items-center">
      <img
        className="absolute left-0 top-0 w-full h-full object-cover"
        src={pic}
        alt=""
      />
      <div
        className={`${bgOpacity} absolute left-0 top-0 w-full h-full bg-PrimaryColor/30`}
      ></div>
      <div className="flex flex-col text-center items-center gap-2 w-[70%] max-md:w-[85%] z-10">
        <h1 className={`${textTopClassName} font-Dancing text-[#f1f1f1]`}>
          {textTop}
        </h1>
        <h1 className={`${textCenterClassName} font-bold text-white uppercase`}>
          {textCenter}
        </h1>
        {hasButton && (
          <Link
            className={`${buttonClassName} font-bold flex justify-center items-center gap-2 px-3 py-1 rounded-[10px] border border-[#f1f1f1] w-fit text-[1.15em] duration-300 group uppercase`}
            to={link}
            onClick={onClick}
          >
            {textButton}
            {hasIcon && (
              <BsFillBasketFill
                className="hidden group-hover:block text-[#666]"
                size={24}
              />
            )}
          </Link>
        )}
      </div>
    </div>
  );
}

export default MainSectionContent;
