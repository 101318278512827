import React, { useContext, useEffect, useState } from "react";
import Container from "../../../Shared/Components/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import MainSectionContent from "../../Components/MainSectionContent";
import { useTranslation } from "react-i18next";
import { fetchMainSectionData } from "../../../Api/SweetApi";
import { CategoriesContext } from "../../../Context/CategoriesContext";
import { useNavigate } from "react-router-dom";
import MainSectionSkeleton from "../../Components/SkeletonLoading/MainSectionSkeleton";

function Main() {
  const { t } = useTranslation();
  const language_id = localStorage.getItem("languageId");
  const lng = localStorage.getItem("kfLanguage");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setCategoryId } = useContext(CategoriesContext);
  const navigate = useNavigate();
  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetchMainSectionData(language_id);
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error("get main section data err : ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [lng]);
  return (
    <Container>
      {loading ? (
        <MainSectionSkeleton />
      ) : (
        <div className="flex items-center max-md:flex-col pt-10 pb-10 max-xl:pt-0 max-xl:pb-3 gap-1 md:h-[600px]">
          <div
            style={{ direction: "ltr" }}
            className="mainSwiper h-full max-md:h-[300px] w-[75%] max-md:w-full"
          >
            <Swiper
              className="h-full"
              modules={[Navigation, Pagination, Autoplay]}
              slidesPerView={1}
              spaceBetween={20}
              speed={1000} // transition duration in milliseconds
              navigation
              autoplay={{ delay: 3500 }} // delay between slides in milliseconds
              loop
            >
              {data.slice(0, 3).map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-full h-full relative flex justify-center items-center slide-content">
                    <MainSectionContent
                      pic={item.url_media}
                      textTop={index !== 1 && item.text1}
                      textTopClassName={`text-[2em]`}
                      textCenter={item.text2}
                      textCenterClassName={`text-[2.5em] max-md:text-[26px]`}
                      hasIcon={index !== 1}
                      hasButton={true}
                      textButton={t(
                        index === 2 ? t(`Browse All`) : t(`Order Now`)
                      )}
                      buttonClassName={`min-w-[180px] text-[#f1f1f1] hover:text-[#666] hover:bg-white`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="flex flex-col gap-1 w-[25%] max-md:w-full h-full">
            <div className="w-full relative basis-[50%] min-h-[200px] md:h-[50%] flex justify-center items-center max-md:hidden">
              <MainSectionContent
                pic={data[4]?.url_media}
                bgOpacity={`bg-PrimaryColor/70`}
                textTop={data[4]?.text1}
                textTopClassName={`text-[16px] max-md:text-[22px]`}
                textCenter={data[4]?.text2}
                textCenterClassName={`text-[18px]`}
                hasButton={true}
                textButton={t(`Browse Products`)}
                onClick={() => {
                  setCategoryId(1);
                  navigate("sweetsByCategory");
                }}
                buttonClassName={`bg-white text-[#4b4f56] hover:bg-gray-200 text-[0.82em]`}
              />
            </div>
            <div className="w-full relative basis-[50%] min-h-[200px] md:h-[50%] flex justify-center items-center max-md:hidden">
              <MainSectionContent
                pic={data[3]?.url_media}
                textTop={data[3]?.text1}
                textTopClassName={`text-[18px] max-md:text-[22px]`}
                textCenter={data[3]?.text2}
                textCenterClassName={`text-[26px]`}
                hasButton={true}
                textButton={t(`View Deals`)}
                buttonClassName={`bg-PrimaryColor hover:bg-HoverPrimaryColor min-w-[150px] border-[#0000000d] text-white hover:text-white text-[0.82em]`}
              />
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Main;
