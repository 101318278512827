import React, { useContext, useEffect } from "react";
import { CategoriesContext } from "../../Context/CategoriesContext";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { usePopup } from "../../Context/PopupContext";

function CategoryItemDashboard({ id, image, name, index }) {
  const { categoryId, setCategoryId, selectedCategory, setSelectedCategory } =
    useContext(CategoriesContext);
  const { setUpdateCategoryOpen, setMakeDecisionOpen, setType, setAction } =
    usePopup();
  useEffect(() => {
    console.log(selectedCategory);
  }, [selectedCategory]);
  return (
    <div className="flex justify-center items-center h-[50px] relative">
      <div
        onClick={() => {
          setSelectedCategory((prev) => ({ ...prev, id, image }));
          setMakeDecisionOpen(true);
          setAction("delete");
          setType("category");
        }}
        className="absolute rounded-full top-[50%] -translate-y-[50%] right-10 opacity-0 -z-10 group-hover:z-0 group-hover:opacity-100 cursor-pointer border border-transparent border-[#333] hover:border-PrimaryColor p-2 duration-300 hover:bg-PrimaryColor hover:text-white"
      >
        <MdDelete className="text-[20px]" />
      </div>
      <div
        onClick={() => {
          setSelectedCategory((prev) => ({ ...prev, id, image }));
          setUpdateCategoryOpen(true);
        }}
        className="absolute rounded-full top-[50%] -translate-y-[50%] left-10 opacity-0 -z-10 group-hover:z-0 group-hover:opacity-100 cursor-pointer border border-transparent border-[#333] hover:border-PrimaryColor p-2 duration-300 hover:bg-PrimaryColor hover:text-white"
      >
        <AiFillEdit className="text-[20px]" />
      </div>
      <div
        onClick={() => setCategoryId(id)}
        className={`${
          categoryId === index + 1 &&
          "bg-PrimaryColor text-white pointer-events-none"
        } border-PrimaryColor hover:bg-PrimaryColor hover:text-white duration-300 py-1 px-2 rounded-[10px] cursor-pointer font-bold border`}
      >
        {name}
      </div>
    </div>
  );
}

export default CategoryItemDashboard;
