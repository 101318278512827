import React, { useContext, useState } from "react";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { usePopup } from "../../Context/PopupContext";
import { useTheme } from "../../Context/ThemeContext";
import { addNewCategory } from "../../Api/DashboardApi";
import { CategoriesContext } from "../../Context/CategoriesContext";

function AddNewCategory() {
  const { theme } = useTheme();
  const { setAddCategoryOpen } = usePopup();
  const { setUpdateCategories } = useContext(CategoriesContext);
  const [category, setCategory] = useState({
    name_en: "",
    name_zh: "",
    name_ms: "",
    name_ar: "",
    url_media: "",
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleChange = (e) => {
    setCategory((prevCategory) => ({
      ...prevCategory,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeImg = (e) => {
    setCategory((prevCategory) => ({
      ...prevCategory,
      [e.target.name]: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name_en", category.name_en);
    formData.append("name_zh", category.name_zh);
    formData.append("name_ms", category.name_ms);
    formData.append("name_ar", category.name_ar);
    formData.append("url_media", category.url_media);
    try {
      setSubmitLoading(true);
      const response = await addNewCategory(formData);
      console.log(response);
      if (response.success === true) {
        setAddCategoryOpen(false);
        setUpdateCategories((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkBoxBg"
      } max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full`}
    >
      <form
        onSubmit={handleSubmit}
        className={`${
          theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-[#f7f7f7]"
        } flex flex-col pb-[15px]`}
      >
        <div
          className={`${
            theme === "dark" ? "bg-DarkBoxBg" : "md:bg-MainBg"
          } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-[18px] leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
        >
          <div
            className={`${
              theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
            } p-[5px] rounded-full`}
          >
            <AiOutlineClose
              className="cursor-pointer"
              onClick={() => setAddCategoryOpen(false)}
              size={26}
            />
          </div>
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } md:py-[27px]`}
          >
            Add New Category
          </h1>
          <button
            type="submit"
            className={`${
              submitLoading && "pointer-events-none"
            } min-w-[45px] flex justify-center items-center h-[52px] text-[18px] cursor-pointer font-[700] text-PrimaryColor hover:underline duration-300`}
          >
            {submitLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Done"
            )}
          </button>
        </div>
        <div
          className={`${
            theme === "dark"
              ? "text-DarkMainText/70 bg-DarkBoxBg"
              : "text-SecondText lg:bg-[#f7f7f7]"
          } flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto`}
        >
          <div className="flex flex-col">
            <label htmlFor="name_en" className="mb-[0.25rem]">
              Name in english
            </label>
            <input
              id="categoryName"
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              placeholder="Category Name"
              name="name_en"
              value={category.name_en}
              onChange={handleChange}
            />
            <label htmlFor="name_zh" className="mb-[0.25rem]">
              Name in chineese
            </label>
            <input
              id="categoryName"
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              placeholder="Category Name"
              name="name_zh"
              value={category.name_zh}
              onChange={handleChange}
            />
            <label htmlFor="name_ms" className="mb-[0.25rem]">
              Name in malayisian
            </label>
            <input
              id="categoryName"
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              placeholder="Category Name"
              name="name_ms"
              value={category.name_ms}
              onChange={handleChange}
            />
            <label htmlFor="name_ar" className="mb-[0.25rem]">
              Name in arabic
            </label>
            <input
              id="categoryName"
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="text"
              placeholder="Category Name"
              name="name_ar"
              value={category.name_ar}
              onChange={handleChange}
            />
            <label htmlFor="categoryImage" className="mb-[0.25rem]">
              Category Image
            </label>
            <input
              id="categoryImage"
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
              type="file"
              name="url_media"
              onChange={handleChangeImg}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewCategory;
