import React from "react";
import TopFooter from "./TopFooter";
import CenterFooter from "./CenterFooter";
import BottomFooter from "./BottomFooter";

function Footer() {
  return (
    <div>
      <TopFooter />
      {/* <CenterFooter /> */}
      {/* <BottomFooter /> */}
    </div>
  );
}

export default Footer;
