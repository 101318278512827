import React from "react";
import Skeleton from "react-loading-skeleton";

function SweetItemDetailsSkeleton() {
  return (
    <div className="flex flex-col w-full min-w-full sm:min-w-[500px] min-h-[80%]">
      <div className="h-[300px] max-sm:h-[250px]">
        <Skeleton height={`100%`} />
      </div>
      <div className="p-2 flex flex-col gap-2 h-[20%]">
        <div className="flex justify-between items-center h-[30px]">
          <div className="w-[30%]">
            <Skeleton width={`100%`} height={`100%`} />
          </div>
          <div className="w-[30%]">
            <Skeleton width={`100%`} height={`100%`} />
          </div>
        </div>
        <div className="h-[80px]">
          <Skeleton height={`100%`} />
        </div>
        <div className="flex justify-between items-center h-[30px]">
          <div className="w-[30%]">
            <Skeleton width={`100%`} height={`100%`} />
          </div>
          <div className="w-[30%]">
            <Skeleton width={`100%`} height={`100%`} />
          </div>
        </div>
      </div>
      <div className="p-2 flex flex-col gap-2 h-[20%] pt-2 border-t-[3px] border-t-[#ececec]">
        <div className="w-[30%]">
          <Skeleton width={`100%`} height={`100%`} />
        </div>
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className="flex justify-between items-center h-[30px]"
          >
            <div className="w-[25%]">
              <Skeleton width={`100%`} height={`100%`} />
            </div>
            <div className="w-[25%]">
              <Skeleton width={`100%`} height={`100%`} />
            </div>
          </div>
        ))}
      </div>
      <div className="p-2 bg-white flex items-center h-[10%] gap-4 w-full border-[3px] border-b-[#ececec]">
        <div className="flex items-center gap-4 w-[40%]">
          <div className="w-[33.3%]">
            <Skeleton circle height={`100%`} />
          </div>
          <div className="w-[33.3%]">
            <Skeleton height={`100%`} />
          </div>
          <div className="w-[33.3%]">
            <Skeleton circle height={`100%`} />
          </div>
        </div>
        <div className="w-[60%]">
          <Skeleton height={`100%`} />
        </div>
      </div>
    </div>
  );
}

export default SweetItemDetailsSkeleton;
