import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/scrollbar";
import Container from "../../Shared/Components/Container";
import { useTheme } from "../../Context/ThemeContext";
import LoadingPage from "../../Shared/Components/LoadingPage";
import { SweetContext } from "../../Context/SweetContext";
import { CategoriesContext } from "../../Context/CategoriesContext";
import {
  fetchCategoriesCollection,
  fetchSweetsByCategory,
} from "../../Api/SweetApi";
import SweetItemSkeleton from "../../Website/Components/SkeletonLoading/SweetItemSkeleton";
import SweetItem from "../../Website/Components/SweetItem";
import Skeleton from "react-loading-skeleton";
import { usePopup } from "../../Context/PopupContext";
import CategoryItemDashboard from "../Components/CategoryItemDashboard";
import AddNewCategory from "../Components/AddNewCategory";
import Popup from "../../Shared/Components/Popup";
import UpdateCategory from "../Components/UpdateCategory";
import MakeDecision from "../../Shared/Components/MakeDecision";
import AddNewItem from "../Components/AddNewItem";
import UpdateItem from "../Components/UpdateItem";

function SweetsView() {
  const { theme } = useTheme();
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [sweets, setSweets] = useState([]);
  const [catLoading, setCatLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const { categoryId, selectedCategory, updateCategories } =
    useContext(CategoriesContext);
  const { lazyLoading, loading, selectedSweetItem } = useContext(SweetContext);
  const {
    addCategoryOpen,
    setAddCategoryOpen,
    updateCategoryOpen,
    setUpdateCategoryOpen,
    addItemOpen,
    setAddItemOpen,
    updateItemOpen,
    setUpdateItemOpen,
    MakeDecisionOpen,
    setMakeDecisionOpen,
  } = usePopup();
  const fetchCategories = async () => {
    try {
      setCatLoading(true);
      const response = await fetchCategoriesCollection(1);
      console.log(response.data);
      setCategories(response.data);
    } catch (error) {
      console.error("fetch categories err : ", error);
    } finally {
      setCatLoading(false);
    }
  };
  const fetchSweetsData = async () => {
    setIsLoadingPage(true);
    try {
      const sweetsData = await fetchSweetsByCategory(1, categoryId);
      console.log(sweetsData.data);
      setSweets(sweetsData.data); // Assuming sweetsData is the object structure returned by the API.
    } catch (error) {
      console.error("Error fetching sweets data : ", error);
    } finally {
      setIsLoadingPage(false);
    }
  };
  useEffect(() => {
    lazyLoading();
  }, []);
  useEffect(() => {
    fetchSweetsData();
  }, [categoryId]);
  useEffect(() => {
    fetchCategories();
  }, [updateCategories]);
  return loading ? (
    <LoadingPage />
  ) : (
    <div className="pb-5 flex flex-col">
      <h1 className="text-2xl mb-2 font-semibold">Sweets</h1>
      <div className="flex justify-start">
        <div className="flex gap-[14px] pb-[7px] mb-2 ">
          <div
            onClick={() => setAddCategoryOpen(true)}
            className={`cursor-pointer bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 text-white font-[700] py-[10px] px-[16px] text-center border text-[15px] rounded-[62px] w-fit`}
          >
            Add new category
          </div>
          <div
            onClick={() => {
              setAddItemOpen(true);
            }}
            className={`cursor-pointer bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 text-white font-[700] py-[10px] px-[16px] text-center border text-[15px] rounded-[62px] w-fit`}
          >
            Add new Item
          </div>
        </div>
      </div>
      <Container className="w-[100%]">
        <div className="mainSwiper flex justify-center pb-[7px] mb-2 artSlide relative max-h-[184px]">
          <Swiper
            className="w-full flex justify-center"
            modules={[Navigation, Scrollbar, A11y]}
            spaceBetween={0.2}
            breakpoints={{
              1280: {
                slidesPerView: 4.2,
              },
              1024: {
                slidesPerView: 3.2,
              },
              768: {
                slidesPerView: 2.2,
              },
            }}
            navigation
          >
            {catLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                  <SwiperSlide key={index}>
                    <div className="w-[100px] h-[40px] rounded-[10px] mx-auto">
                      <Skeleton width={`100%`} height={`100%`} />
                    </div>
                  </SwiperSlide>
                ))
              : categories.length > 0 &&
                categories.map((item, index) => (
                  <SwiperSlide className="group" key={item.id}>
                    <CategoryItemDashboard
                      id={item.id}
                      image={item.url_media}
                      name={item.name}
                      index={index}
                    />
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
      </Container>
      <div className="py-5 grid grid-cols-5 max-lap:grid-cols-3 max-mob:grid-cols-2 gap-x-5 gap-y-8">
        {isLoadingPage ? (
          Array.from({ length: 10 }).map((_, index) => (
            <div key={index}>
              <SweetItemSkeleton />
            </div>
          ))
        ) : sweets.length > 0 ? (
          sweets.map((item) => (
            <div key={item.id}>
              <SweetItem
                id={item.id}
                pic={item?.productsMedia?.[0]?.url_media}
                name={item.name}
                category={item.category[0].name}
                description={item.description}
                price={item.price}
                dashMode={true}
                tags={item.tags}
                code={item.code}
                categoryId={item.category[0].category_id}
              />
            </div>
          ))
        ) : (
          <div className="text-2xl">No Sweet Items</div>
        )}
      </div>
      {/* <ReactPaginate
        previousLabel={"<<"}
        nextLabel={">>"}
        breakLabel={"..."}
        perPage={perPage}
        breakClassName={"break-me"}
        pageCount={lastPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => fetchSweetsData(selected + 1)}
        containerClassName={"flex justify-center items-center gap-2 mt-10"}
        previousLinkClassName={
          "px-4 py-2 bg-PrimaryColor hover:bg-HoverPrimaryColor font-bold duration-300 rounded-l-lg"
        }
        nextLinkClassName={
          "px-4 py-2 bg-PrimaryColor hover:bg-HoverPrimaryColor font-bold duration-300 rounded-r-lg"
        }
        pageLinkClassName={
          "px-4 py-2 bg-PrimaryColor hover:bg-HoverPrimaryColor font-bold duration-300 rounded"
        }
        activeLinkClassName={`px-4 py-2 font-bold duration-300 rounded border-2 border-PrimaryColor bg-white ${
          theme === "dark" && "text-MainText"
        }`}
      /> */}

      <Popup
        toggleSwitch={addCategoryOpen}
        children={<AddNewCategory />}
        zIndex="z-40"
        className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
        onClick={() => setAddCategoryOpen(false)}
      />
      <Popup
        toggleSwitch={updateCategoryOpen}
        children={
          <UpdateCategory
            id={selectedCategory.id}
            image={selectedCategory.image}
          />
        }
        zIndex="z-40"
        className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
        onClick={() => setUpdateCategoryOpen(false)}
      />
      <Popup
        toggleSwitch={MakeDecisionOpen}
        children={<MakeDecision />}
        zIndex="z-40"
        className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
        onClick={() => setMakeDecisionOpen(false)}
      />
      <Popup
        toggleSwitch={addItemOpen}
        children={<AddNewItem />}
        zIndex="z-40"
        className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
        onClick={() => setAddItemOpen(false)}
      />
      <Popup
        toggleSwitch={updateItemOpen}
        children={
          <UpdateItem
            id={selectedSweetItem.id}
            price={selectedSweetItem.price}
            tags={selectedSweetItem.tags}
            code={selectedSweetItem.code}
            categoryId={selectedSweetItem.categoryId}
          />
        }
        zIndex="z-40"
        className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
        onClick={() => setUpdateItemOpen(false)}
      />
    </div>
  );
}

export default SweetsView;
