import React from "react";
import Skeleton from "react-loading-skeleton";

function MainSectionSkeleton() {
  return (
    <div className="flex items-center max-md:flex-col pt-10 pb-10 max-xl:pt-0 max-xl:pb-3 gap-1 md:h-[600px]">
      <div className="h-full max-md:h-[300px] w-[75%] max-md:w-full">
        <Skeleton height={`100%`} />
      </div>
      <div className="flex flex-col gap-1 w-[25%] max-md:w-full h-full max-xl:hidden">
        <div className="basis-[50%]">
          <Skeleton height={`100%`} className="min-h-[200px] " />
        </div>
        <div className="basis-[50%]">
          <Skeleton height={`100%`} className="min-h-[200px] " />
        </div>
      </div>
    </div>
  );
}

export default MainSectionSkeleton;
