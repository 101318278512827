import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { SkeletonTheme } from "react-loading-skeleton";
import Home from "./Website/Pages/Home/Home";
import WishList from "./Website/Pages/WishList";
import Profile from "./Website/Pages/Profile";
import AccountDetails from "./Website/Pages/AccountDetails";
import Cart from "./Website/Pages/Cart";
import Cookies from "js-cookie"
import NotFound from "./Website/Pages/NotFound";
import SweetsByCategory from "./Website/Pages/SweetsByCategory";
import PrivateRoute from "./Admin/Components/PrivateRoute";
import Admin from "./Admin/Pages/Admin";
import Dashboard from "./Admin/Pages/Dashboard";
import UsersView from "./Admin/Pages/UsersView";
import SweetsView from "./Admin/Pages/SweetsView";
import OrdersView from "./Admin/Pages/OrdersView";
import OrderInformation from "./Admin/Pages/OrderInformation";
import ReportsView from "./Admin/Pages/ReportsView";
import ForgetPassword from "./Admin/Pages/ForgetPassword";
import VisitorsView from "./Admin/Pages/VisitorsView";
import { useContext, useEffect, useState } from "react";
import { fetchCategoriesCollection } from "./Api/SweetApi";
import { useTranslation } from "react-i18next";
import { SweetContext } from "./Context/SweetContext";


function App() {
  const {dir,myLang,setDir} = useContext(SweetContext)
  const token = Cookies.get("kfToken")
  const {i18n} = useTranslation()
  const language_id = localStorage.getItem("languageId");
  const lng = localStorage.getItem("kfLanguage") || "English"
  const [categories, setCategories] = useState([]);
  const [catLoading, setCatLoading] = useState(true);
  const fetchCategories = async () => {
    try {
      setCatLoading(true);
      const response = await fetchCategoriesCollection(language_id);
      console.log(response.data);
      setCategories(response.data);
    } catch (error) {
      console.error("fetch categories err : ", error);
    } finally {
      setCatLoading(false);
    }
  };
  useEffect(() => {
    fetchCategories()
    document.body.dir = dir;
    setDir(lng === "Arabic" ? "rtl" : "ltr")
    i18n.changeLanguage(lng === "Arabic" ? "Arabic" : lng === "English" ? "English":"Deutsch")
  }, [dir,lng,myLang]);
  const router = createBrowserRouter([
    { path: "/", element: <Home categories={ categories} /> },
  { path: "/sweetsByCategory", element: < SweetsByCategory categories={ categories}/> },
  { path: "/cart", element: token ? <Cart />  : <NotFound/>},
  {
    path: "/profile",
    element: token ? <Profile /> :  <NotFound/>,
    children: [
      { path: "wishList", element: <WishList /> },
      { path: "accountDetails", element: <AccountDetails /> }
    ]
  },
  { path: "*", element: <NotFound /> },
  {
      path: "/admin",
      element: <Admin />,
  },
  {
      path: "/forgetPassword",
      element: <ForgetPassword />,
  },
    {
      path: "/dashboard",
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
      children: [
        {
          index: true,
          element: <UsersView />,
        },
        {
          path: "sweets",
          element: <SweetsView categories={categories} catLoading={catLoading} />,
        },
        {
          path: "visitors",
          element: <VisitorsView />,
        },
        {
          path: "orders",
          element: <OrdersView />,
        },
        {
          path: "/dashboard/orders/:id",
          element: <OrderInformation />,
        },
        {
          path: "reports",
          element: <ReportsView />,
        },
      ],
    },
]);
  return (
    <div className="App overflow-x-hidden">
      {/* <SkeletonTheme
        baseColor={`${theme === "dark" ? "#202020" : "#F5F5F5"}`}
        highlightColor={`${theme === "dark" ? "#444" : "#E0E0E0"}`}
      > */}
        <SkeletonTheme baseColor={`#F5F5F5`}
        highlightColor={`#E0E0E0`}>
      <RouterProvider router={router} />
      </SkeletonTheme>
    </div>
  );
} 

export default App;
