import React from "react";
import { usePopup } from "../../Context/PopupContext";
import { useTheme } from "../../Context/ThemeContext";
import useOutsideClick from "../../Shared/Components/UseOutsideClick";

function OrderFilterList({ className }) {
  const { theme } = useTheme();
  const { setOrderFilterOpen, setStatusFilter, setStatusFilterId } = usePopup();
  const dropdownRef = useOutsideClick(() => setOrderFilterOpen(false));

  return (
    <div ref={dropdownRef} className={className}>
      <div
        onClick={() => {
          setStatusFilterId("");
          setStatusFilter("All");
          setOrderFilterOpen(false);
        }}
        className={`${
          theme === "dark"
            ? "bg-gray-100 hover:bg-gray-200"
            : "bg-gray-100 hover:bg-gray-200"
        } cursor-pointer px-2 py-1 hover:rounded-[6px] hover:shadow-lg duration-300`}
      >
        All
      </div>
      <div
        onClick={() => {
          setStatusFilterId(1);
          setStatusFilter("Pending");
          setOrderFilterOpen(false);
        }}
        className={`${
          theme === "dark"
            ? "bg-gray-100 hover:bg-gray-200"
            : "bg-gray-100 hover:bg-gray-200"
        } cursor-pointer px-2 py-1 hover:rounded-[6px] hover:shadow-lg duration-300`}
      >
        Pending
      </div>
      <div
        onClick={() => {
          setStatusFilterId(2);
          setStatusFilter("Processing");
          setOrderFilterOpen(false);
        }}
        className={`${
          theme === "dark"
            ? "bg-gray-100 hover:bg-gray-200"
            : "bg-gray-100 hover:bg-gray-200"
        } cursor-pointer px-2 py-1 hover:rounded-[6px] hover:shadow-lg duration-300`}
      >
        Processing
      </div>
      <div
        onClick={() => {
          setStatusFilterId(3);
          setStatusFilter("Confirmed");
          setOrderFilterOpen(false);
        }}
        className={`${
          theme === "dark"
            ? "bg-gray-100 hover:bg-gray-200"
            : "bg-gray-100 hover:bg-gray-200"
        } cursor-pointer px-2 py-1 hover:rounded-[6px] hover:shadow-lg duration-300`}
      >
        Confirmed
      </div>

      <div
        onClick={() => {
          setStatusFilterId(4);
          setStatusFilter("Shipped");
          setOrderFilterOpen(false);
        }}
        className={`${
          theme === "dark"
            ? "bg-gray-100 hover:bg-gray-200"
            : "bg-gray-100 hover:bg-gray-200"
        } cursor-pointer px-2 py-1 hover:rounded-[6px] hover:shadow-lg duration-300`}
      >
        Shipped
      </div>
      <div
        onClick={() => {
          setStatusFilterId(5);
          setStatusFilter("Delivered");
          setOrderFilterOpen(false);
        }}
        className={`${
          theme === "dark"
            ? "bg-gray-100 hover:bg-gray-200"
            : "bg-gray-100 hover:bg-gray-200"
        } cursor-pointer px-2 py-1 hover:rounded-[6px] hover:shadow-lg duration-300`}
      >
        Delivered
      </div>
      <div
        onClick={() => {
          setStatusFilterId(6);
          setStatusFilter("Cancelled");
          setOrderFilterOpen(false);
        }}
        className={`${
          theme === "dark"
            ? "bg-gray-100 hover:bg-gray-200"
            : "bg-gray-100 hover:bg-gray-200"
        } cursor-pointer px-2 py-1 hover:rounded-[6px] hover:shadow-lg duration-300`}
      >
        Cancelled
      </div>
    </div>
  );
}

export default OrderFilterList;
