import React, { useEffect, useState } from "react";
import Container from "../../Shared/Components/Container";
import { useTranslation } from "react-i18next";
import { fetchAccountDetails, updateAccountDetails } from "../../Api/UserApi";
import { FaSpinner } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

function AccountDetails() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
  });
  const getUserInfo = async () => {
    try {
      setLoading(true);
      const response = await fetchAccountDetails();
      console.log(response.data);
      setUser({
        ...user,
        id: response.data.id,
        name: response.data.name,
        email: response.data.email,
      });
    } catch (error) {
      console.error("get user info err : ", error);
    } finally {
      setLoading(false);
    }
  };
  const changeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  console.log(user);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", user.name);
    try {
      setSaveLoading(true);
      const response = await updateAccountDetails(formData);
      console.log(response);
      localStorage.setItem("kfName", response.data.name);
      window.location.reload();
    } catch (error) {
      console.error("update user info : ", error);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <div>
      <div className="flex justify-center items-center p-[15px] text-[27px] font-[700] leading-[32px] tracking-[1.36px] max-md:text-[22px] max-md:leading-[26px] max-md:tracking-[1.12px] bg-[#f7f7f7]">
        {t("ACCOUNT DETAILS")}
      </div>
      <div className="py-5 bg-white">
        <Container>
          <div className="flex flex-col gap-3">
            <h1 className="font-[700] text-[26px] leading-[32px] max-md:text-[20px]">
              {t("My account")}
            </h1>
            <form onSubmit={submitHandler} className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <label
                  className="text-[#222] font-bold text-[0.9em]"
                  htmlFor="name"
                >
                  {t("Name")}*
                </label>
                {loading ? (
                  <div className="py-1 w-full h-[33.6px]">
                    <Skeleton height={`100%`} />
                  </div>
                ) : (
                  <input
                    className="outline-none px-[10px] py-1 border border-[#ddd] hover:drop-shadow-sm hover:shadow-inner duration-300"
                    placeholder={t("Your Name")}
                    name="name"
                    type="text"
                    value={user.name}
                    onChange={changeHandler}
                    maxLength={20}
                  />
                )}
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="text-[#222] font-bold text-[0.9em]"
                  htmlFor="email"
                >
                  {t("Email")}*
                </label>
                {loading ? (
                  <div className="py-1 w-full h-[33.6px]">
                    <Skeleton height={`100%`} />
                  </div>
                ) : (
                  <input
                    className="outline-none px-[10px] py-1 border border-[#ddd] hover:drop-shadow-sm hover:shadow-inner duration-300"
                    placeholder={t("Your Email")}
                    id="email"
                    type="text"
                    value={user.email}
                    readOnly
                  />
                )}
              </div>
              <button
                type="submit"
                className={`${
                  saveLoading && "opacity-50 pointer-events-none"
                } bg-PrimaryColor hover:bg-HoverPrimaryColor uppercase w-fit duration-300 py-1 px-3 rounded-[6px] cursor-pointer text-white font-semibold flex justify-center items-center`}
              >
                {saveLoading ? (
                  <div className="px-[48px] py-[1px]">
                    <FaSpinner className="animate-spin" size={20} />
                  </div>
                ) : (
                  t("Save changes")
                )}
              </button>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default AccountDetails;

