import { useState, useEffect, useContext } from "react";
import { useTheme } from "../../Context/ThemeContext";
import Skeleton from "react-loading-skeleton";
import LoadingPage from "../../Shared/Components/LoadingPage";
import { SweetContext } from "../../Context/SweetContext";
import { fetchUsers } from "../../Api/DashboardApi";

const UsersView = () => {
  const { theme } = useTheme();
  const [users, setUsers] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const { lazyLoading, loading, setLoading } = useContext(SweetContext);

  const fetchUsersData = async () => {
    try {
      setDataLoading(true);
      const usersData = await fetchUsers();
      console.log(usersData.data);
      setUsers(usersData.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setDataLoading(false); // Ensure loading stops regardless of the fetch outcome
    }
  };
  useEffect(() => {
    const syncFunc = async () => {
      await lazyLoading();
      setTimeout(() => {
        fetchUsersData();
      }, 100);
    };
    syncFunc();
  }, []);

  return loading ? (
    <LoadingPage />
  ) : (
    <div>
      <h1 className="text-2xl font-semibold">Users</h1>
      <div className="mt-4">
        <table
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
          } w-full table-auto`}
        >
          <thead>
            <tr>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Is Admin</th>
            </tr>
          </thead>
          <tbody>
            {dataLoading ? (
              <tr>
                {Array.from({ length: 3 }).map((_, index) => (
                  <td key={index}>
                    <Skeleton width={"100%"} height={35} />
                  </td>
                ))}
              </tr>
            ) : users && users.length > 0 ? (
              users.map((user, index) => (
                <tr key={index} className="">
                  <td className="border text-center px-4 py-2">{user.name}</td>
                  <td className="border text-center px-4 py-2">{user.email}</td>
                  <td className="border text-center px-4 py-2">
                    {user.isAdmin}
                  </td>
                </tr>
              ))
            ) : (
              <div className="text-2xl">No users</div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersView;
