import { useState, useEffect, useContext } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { MdDateRange } from "react-icons/md";
import Calendar from "react-calendar";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "../../Context/ThemeContext";
import useOutsideClick from "../../Shared/Components/UseOutsideClick";
import LoadingPage from "../../Shared/Components/LoadingPage";
import { SweetContext } from "../../Context/SweetContext";
import { usePopup } from "../../Context/PopupContext";
import {
  fetchAllOrders,
  fetchOrdersByDate,
  fetchOrdersByStatus,
} from "../../Api/DashboardApi";
import OrderFilterList from "../Components/OrderFilterList";

function OrdersView() {
  const { theme } = useTheme();
  const startDateRef = useOutsideClick(() => setStartDateOpen(false));
  const endDateRef = useOutsideClick(() => setEndDateOpen(false));
  

  const formatDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  };
  const [orders, setOrders] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formattedStartDate, setFormattedStartDate] = useState(
    formatDate(startDate)
  );
  const [formattedEndDate, setFormattedEndDate] = useState(formatDate(endDate));
  useEffect(() => {
    setFormattedStartDate(formatDate(startDate));
    setFormattedEndDate(formatDate(endDate));
  }, [startDate, endDate]);
  const { lazyLoading, loading } = useContext(SweetContext);
  const {
    orderFilterOpen,
    setOrderFilterOpen,
    startDateOpen,
    setStartDateOpen,
    endDateOpen,
    setEndDateOpen,
    statusFilter,
    statusFilterId,
  } = usePopup();

  const handleFetchAllOrders = async () => {
    try {
      setDataLoading(true);
      const response = await fetchAllOrders();
      console.log(response.data);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders data:", error);
    } finally {
      setDataLoading(false);
    }
  };

  const handleFilterChange = async (statusId) => {
    try {
      setDataLoading(true);
      const response = await fetchOrdersByStatus(statusId);
      console.log(response);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    } finally {
      setDataLoading(false);
    }
  };
  const handleSortSubmit = async (e) => {
    e.preventDefault();
    // Format the selected dates as "year-month-day"
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    setFormattedStartDate(formattedStartDate);
    setFormattedEndDate(formattedEndDate);
    try {
      setDataLoading(true);
      const response = await fetchOrdersByDate(
        formattedStartDate,
        formattedEndDate
      );
      console.log(response.data);
      setOrders(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    lazyLoading();
    if (statusFilterId) {
      handleFilterChange(statusFilterId);
    } else {
      handleFetchAllOrders();
    }
  }, [statusFilterId]);

  return loading ? (
    <LoadingPage />
  ) : (
    <div>
      <h1 className="text-2xl font-semibold">Orders</h1>
      <div className="flex justify-between items-center mt-3">
        <div className="flex items-center gap-5">
          <div className="relative w-fit cursor-pointer">
            <div
              onClick={() => {
                setOrderFilterOpen(!orderFilterOpen);
              }}
              className={`${
                theme === "dark"
                  ? "bg-white hover:bg-gray-100"
                  : "bg-white hover:bg-gray-100"
              } relative text-PrimaryColor flex items-center pl-4 gap-1 rounded-[20px] border-2 px-2 py-1 hover:text-HoverPrimaryColor hover:shadow-lg duration-300`}
            >
              <h1 className="cursor-pointer flex items-center gap-2">
                Filtered by {statusFilter}
              </h1>
              <IoIosArrowDown />
            </div>

            <OrderFilterList
              className={`${
                orderFilterOpen ? "opacity-100 z-50" : "opacity-0 -z-50"
              } ${
                theme === "dark" ? "bg-white" : "bg-white"
              } -mb-14 absolute w-full text-center rounded-[20px]`}
            />
          </div>
        </div>
        <form onSubmit={handleSortSubmit} className="flex gap-2 items-center">
          <div
            onClick={() => setStartDateOpen(!startDateOpen)}
            className="relative cursor-pointer"
            ref={startDateRef}
          >
            <Calendar
              className={`${startDateOpen ? "" : "hidden"} ${
                theme === "dark" && "bg-DarkBoxBg"
              } absolute min-w-[350px] top-[40px] right-0 z-50`}
              onChange={setStartDate}
              value={startDate}
            />
            <div className="flex items-center gap-2 bg-PrimaryColor text-white rounded-[20px] border-2 px-2 py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg duration-300">
              <h1 className="flex items-center gap-1">
                From {formattedStartDate}
              </h1>
              <MdDateRange />
            </div>
          </div>
          <div
            onClick={() => setEndDateOpen(!endDateOpen)}
            className="relative cursor-pointer"
            ref={endDateRef}
          >
            <Calendar
              className={`${endDateOpen ? "" : "hidden"} ${
                theme === "dark" && "bg-DarkBoxBg"
              } absolute min-w-[350px] top-[40px] right-0 z-50`}
              onChange={setEndDate}
              value={endDate}
            />
            <div className="flex items-center gap-2 bg-PrimaryColor text-white rounded-[20px] border-2 px-2 py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg duration-300">
              <h1 className="flex items-center gap-1">To {formattedEndDate}</h1>
              <MdDateRange />
            </div>
          </div>
          <button
            type="submit"
            className="flex items-center gap-2 bg-PrimaryColor text-white rounded-[20px] border-2 px-2 py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg duration-300"
          >
            Search
          </button>
        </form>
      </div>
      <div className="mt-4">
        <table
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
          } w-full table-auto`}
        >
          <thead>
            <tr>
              <th className="border px-4 py-2">Order ID</th>
              <th className="border px-4 py-2">Order date</th>
              <th className="border px-4 py-2">Owner</th>
              <th className="border px-4 py-2">Location</th>
              <th className="border px-4 py-2">Status</th>
              <th className="border px-4 py-2">Total price</th>
              <th className="border px-4 py-2 relative">
                <HiDotsVertical className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {dataLoading ? (
              <tr>
                {Array.from({ length: 7 }).map((_, index) => (
                  <td key={index}>
                    <Skeleton width={"100%"} height={35} />
                  </td>
                ))}
              </tr>
            ) : orders && orders.length > 0 ? (
              orders.map((order, index) => (
                <tr key={index} className="">
                  <td className="border text-center px-4 py-2">#{order?.id}</td>
                  <td className="border text-center px-4 py-2">
                    {order?.created_at}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {order?.user?.name}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {order?.address?.address_line_1}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {order?.status}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {order?.total_price}
                  </td>
                  <td className="border text-center px-4 py-2">
                    <Link
                      to={`/dashboard/orders/${order.id}`}
                      className="text-PrimaryColor font-bold cursor-pointer hover:underline duration-300"
                    >
                      view
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <div className="text-2xl">No Orders Available</div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrdersView;
