import React, { useEffect, useState } from "react";
import { usePopup } from "../../Context/PopupContext";
import Popup from "../../Shared/Components/Popup";
import { useTranslation } from "react-i18next";
import { loginAndSendCode, verifyCode } from "../../Api/AuthApi";
import { FaSpinner } from "react-icons/fa";
import Cookies from "js-cookie";
import { toastLoading } from "../../Shared/Components/ToastNotification/Toast";
import { toast } from "react-toastify";
import logo from "../../assests/Imgs/Logo/funafaFingersLogo.png";

function LoginWithCode() {
  const {
    loginOpen,
    setLoginOpen,
    codeOpen,
    setCodeOpen,
    showResendButton,
    setShowResendButton,
  } = usePopup();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const emailSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    try {
      setEmailLoading(true);
      const response = await loginAndSendCode(formData);
      console.log(response);
      if (response.success === true) {
        setCodeOpen(true);
        setLoginOpen(false);
        showButton();
      }
    } catch (error) {
      console.error("login and send code err : ", error);
    } finally {
      setEmailLoading(false);
    }
  };
  const codeSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("verification_code", code);
    try {
      setCodeLoading(true);
      setShowResendButton(false);
      const response = await verifyCode(formData);
      console.log(response);
      if (response.success === true) {
        Cookies.set("kfToken", response.data.token, { expires: 1 });
        localStorage.setItem("kfName", response.data.user.name);
        setCodeOpen(false);
        window.location.reload();
      }
    } catch (error) {
      console.error("verify code err : ", error);
      showButton();
    } finally {
      setCodeLoading(false);
      setCode("");
    }
  };
  const resendCode = async () => {
    const toastId = toastLoading(`${t("resend code")}...`);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    try {
      const response = await loginAndSendCode(formData);
      console.log(response);
    } catch (error) {
      console.error("resend code err : ", error);
    } finally {
      toast.dismiss(toastId);
    }
  };
  const showButton = () => {
    setTimeout(() => {
      setShowResendButton(true);
    }, 60000);
  };

  return (
    <div>
      <Popup
        toggleSwitch={loginOpen}
        onClick={() => setLoginOpen(false)}
        zIndex={`z-20`}
        className={`${
          loginOpen
            ? "left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]"
            : "-top-full"
        } w-[50%] max-xl:w-[70%] max-lg:w-[80%] max-sm:w-[90%] bg-white z-40 rounded-[10px] shadow-2xl overflow-hidden`}
        children={
          <div className="grid grid-cols-2 max-md:grid-cols-1 items-center h-full">
            <div className="basis-[50%] max-md:basis-[100%] flex flex-col gap-[10px] py-[30px] px-[20px]">
              <h1 className="font-[700] text-[1.25em] text-[#555]">
                {t("LOGIN")}
              </h1>
              <form onSubmit={emailSubmit} className="flex flex-col gap-[10px]">
                <label
                  className="font-bold text-[#222] text-[0.9em]"
                  htmlFor="name"
                >
                  {t("Name")}*
                </label>
                <input
                  className="outline-none px-[10px] py-1 border border-[#ddd] hover:drop-shadow-sm hover:shadow-inner duration-300"
                  id="name"
                  type="text"
                  placeholder={t("Your Name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label
                  className="font-bold text-[#222] text-[0.9em]"
                  htmlFor="email"
                >
                  {t("Email")}*
                </label>
                <input
                  className="outline-none px-[10px] py-1 border border-[#ddd] hover:drop-shadow-sm hover:shadow-inner duration-300"
                  id="email"
                  type="text"
                  placeholder={t("Your Email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className={`${
                    emailLoading && "opacity-50 pointer-events-none"
                  } bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 py-1 px-3 rounded-[6px] cursor-pointer text-white font-semibold flex justify-center items-center w-fit`}
                >
                  {emailLoading ? (
                    <div className="px-[7px] py-[2px]">
                      <FaSpinner className="animate-spin" size={20} />
                    </div>
                  ) : (
                    t("Next")
                  )}
                </button>
              </form>
            </div>
            <div className="basis-[50%] bg-cover bg-center w-full h-full bg-login flex justify-center items-center max-md:hidden">
              <div>
                <img className="w-full h-full" src={logo} alt="" />
              </div>
            </div>
          </div>
        }
      />
      {codeOpen && (
        <Popup
          toggleSwitch={codeOpen}
          onClick={() => setCodeOpen(false)}
          zIndex={`z-20`}
          className={`${
            codeOpen
              ? "left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]"
              : "-top-full"
          } w-[30%] max-xl:w-[40%] max-lg:w-[50%] max-md:w-[80%] max-sm:w-[90%] rounded-[10px] shadow-2xl bg-white z-40`}
          children={
            <div className="flex flex-col gap-[10px] py-[30px] px-[20px]">
              <h1
                onClick={() => {
                  setCodeOpen(false);
                  setLoginOpen(true);
                }}
                className={`underline font-[400] text-[13px] cursor-pointer text-[#222]`}
              >
                {t("Go back")}
              </h1>
              <h1 className="font-[700] text-[1.25em] text-[#555]">
                {t("The code sent to your email:")}
              </h1>
              <h1 className="font-[400] text-[1.1em] text-[#555]">{email}</h1>
              <form onSubmit={codeSubmit} className="flex flex-col gap-[10px]">
                <label
                  className="font-bold text-[#222] text-[0.9em]"
                  htmlFor="code"
                >
                  {t("Code")}*
                </label>
                <input
                  className="outline-none px-[10px] py-1 border border-[#ddd] hover:drop-shadow-sm hover:shadow-inner duration-300"
                  id="code"
                  type="number"
                  pattern="\d{4}"
                  inputMode="numeric"
                  maxLength={4}
                  placeholder={t("Your Code")}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <button
                  type="submit"
                  className={`${
                    codeLoading && "opacity-50 pointer-events-none"
                  } bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 py-1 px-3 rounded-[6px] cursor-pointer text-white font-semibold flex justify-center items-center w-fit`}
                >
                  {codeLoading ? (
                    <div className="px-[16px] py-[1px]">
                      <FaSpinner className="animate-spin" size={20} />
                    </div>
                  ) : (
                    t("Submit")
                  )}
                </button>
                <h1
                  onClick={resendCode}
                  className={`${
                    !showResendButton && "invisible pointer-events-none"
                  } underline font-[400] text-[13px] cursor-pointer text-[#222]`}
                >
                  {t("Send Code Again")}
                </h1>
              </form>
            </div>
          }
        />
      )}
    </div>
  );
}

export default LoginWithCode;

