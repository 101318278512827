import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdAccessTime } from "react-icons/md";
import { IoGift, IoPerson } from "react-icons/io5";
import { useTheme } from "../../Context/ThemeContext";
import { SweetContext } from "../../Context/SweetContext";
import { fetchOrderById } from "../../Api/DashboardApi";
import LoadingPage from "../../Shared/Components/LoadingPage";
import OrderDetailsSkeleton from "../Components/SkeletonLoading/OrderDetailsSkeleton";

function OrderInformation() {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { id } = useParams(); // Get the order ID from the URL
  const [orderDetails, setOrderDetails] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [currentStatus, setCurrentStatus] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const { loading, lazyLoading } = useContext(SweetContext);

  useEffect(() => {
    lazyLoading();
    const fetchOrderDetails = async () => {
      try {
        setDataLoading(true);
        const response = await fetchOrderById(id);
        console.log(response.data);
        setOrderDetails(response.data);
        setCurrentStatus(response.data.status);
        setCartItems(response.data.cart_items);
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setDataLoading(false);
      }
    };
    fetchOrderDetails();
  }, []);
  return (
    <div className="p-10">
      {loading ? (
        <LoadingPage />
      ) : dataLoading ? (
        <OrderDetailsSkeleton />
      ) : cartItems && cartItems.length > 0 ? (
        <div>
          <h1 className="text-2xl font-semibold">Order Details</h1>
          <div className={`${theme === "dark" ? "bg-DarkBoxBg" : "bg-white"}`}>
            <div className="flex items-center gap-2 text-[20px] p-4 mb-5">
              <IoPerson />
              <h1 className="font-semibold">
                {orderDetails?.address?.full_name}
              </h1>
            </div>
          </div>
          <div className="flex justify-between items-center mb-5">
            <div className="flex items-center gap-3">
              <FaArrowLeftLong
                className="text-PrimaryColor cursor-pointer"
                onClick={() => navigate("/dashboard/orders")}
              />
              <h1 className="text-[20px] font-semibold">Full Order Details</h1>
            </div>
            <div className="flex items-center gap-2">
              {/* <div
                onClick={() => setUpdateOrderStatusOpen(true)}
                className="text-[18px] text-white bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 cursor-pointer py-1 px-4 rounded-xl"
              >
                Change Status
              </div> */}
              <div className="flex items-center gap-1 font-semibold text-[18px] py-1 px-4 bg-white rounded-xl">
                <h1 className="text-[#777]">Status:</h1>
                <h2 className="text-PrimaryColor">{orderDetails?.status}</h2>
              </div>
            </div>
          </div>
          <div
            className={`${
              theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
            } flex flex-col bg-white rounded-xl mb-5`}
          >
            <div className="flex justify-between items-center border-b border-[#ebe7e7] p-4">
              <div className="flex items-center gap-1">
                <h1 className="text-[18px] font-semibold">Order ID:</h1>
                <h2 className="text-[18px] text-[#777]">#{orderDetails?.id}</h2>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <MdAccessTime size={20} className="text-PrimaryColor" />
                  <div className="flex items-center gap-1">
                    <h1 className="font-semibold">Time:</h1>
                    <h2 className="text-[#777]">{orderDetails?.created_at}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-nowrap justify-between p-4">
              <div className="flex flex-col">
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Phone Nubmer:</h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.address?.contact_number}
                  </h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Country:</h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.address?.country}
                  </h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">City:</h1>
                  <h2 className="text-[#777]">{orderDetails?.address?.city}</h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Post Code:</h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.address?.postcode}
                  </h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">First Address:</h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.address?.address_line_1}
                  </h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Second Address:</h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.address?.address_line_2}
                  </h2>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Order Date:</h1>
                  <h2 className="text-[#777]">{orderDetails?.order_date}</h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Total Price:</h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.total_price} RM
                  </h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Discount Price:</h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.price_after_discount} RM
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
            } flex flex-col bg-white rounded-xl overflow-hidden`}
          >
            <table className="w-full table-auto">
              <thead>
                <tr>
                  <th className="border px-4 py-2">Name</th>
                  <th className="border px-4 py-2">Image</th>
                  <th className="border px-4 py-2">Note</th>
                  <th className="border px-4 py-2">Quantity</th>
                  <th className="border px-4 py-2">Price</th>
                  <th className="border px-4 py-2">Sub Total</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails?.cart_items?.map((item, index) => (
                  <tr key={index} className="">
                    <td className="border text-center px-4 py-2">
                      {item?.product?.translations?.[0]?.name}
                    </td>
                    <td className="border text-center px-4 py-2">
                      <div className="w-[150px] h-[150px] mx-auto">
                        <img
                          className="w-full h-full"
                          src={item?.product?.products_media?.[0]?.url_media}
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item?.note}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item?.quantity}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item?.price} RM
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item?.sub_total_price} RM
                    </td>
                  </tr>
                ))}
                <tr>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <td key={index} className=" text-center px-4 py-2"></td>
                  ))}
                  <td className="font-semibold text-PrimaryColor text-end px-4 py-2">
                    Total:
                  </td>
                  <td className=" text-center px-4 py-2">
                    {orderDetails?.total_price}$
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* {updateOrderStatusOpen && (
            <Popup
              children={
                <UpdateOrderStatus
                  id={id}
                  currentStatus={currentStatus}
                  refreshOrderInfo={() => setRefreshKey(Date.now())}
                  setOrderDetails={setOrderDetails}
                />
              }
              zIndex="z-40"
              className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
              onClick={() => setUpdateOrderStatusOpen(false)}
            />
          )} */}
        </div>
      ) : (
        <div className="text-2xl">No Data Available</div>
      )}
    </div>
  );
}

export default OrderInformation;
