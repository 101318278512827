import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  English: {
    translation: {
    // TopBar
   "#1 Online Sweet Store in malaysia": "#1 Online Sweet Store in malaysia",
  "All": "All",
  "Search": "Search",
  "Login / Register": "Login / Register",
  // HoverOnNameList
  "logout processing": "logout processing",
  "ACCOUNT DETAILS": "ACCOUNT DETAILS",
  "WISHLIST": "WISHLIST",
  "LOGOUT": "LOGOUT",
  // BottomBar 
  "CART": "CART",
  "Login": "Login",
  // Main 
  "Browse All": "Browse All",
  "Order Now": "Order Now",
  "Browse Products": "Browse Products",
  "View Deals": "View Deals",
  // SweetByCategory 
  "More Products": "More Products",
  // CategoryCollection 
  "SHOP BY CATEGORY": "SHOP BY CATEGORY",
  // SuggestedForYou 
  "Suggested for You": "Suggested for You",
  "view deals": "view deals",
  // Glance 
  "kunafaFingers - Online Sweet Shop Malaysia": "kunafaFingers - Online Sweet Shop Malaysia",
  "The Levantine region has been making sweets and pastries for centuries. Traditional skills are passed down from father to son, who have taken years to master their craft, from generation to generation. In Malaysia, kunafaFingers online store is the number one online confectionery store that sells Lebanese Sweets, Nawashef pistachios and walnuts, and Arabic confectionery online.": "The Levantine region has been making sweets and pastries for centuries. Traditional skills are passed down from father to son, who have taken years to master their craft, from generation to generation. In Malaysia, kunafaFingers online store is the number one online confectionery store that sells Lebanese Sweets, Nawashef pistachios and walnuts, and Arabic confectionery online.",
  "Find out more about kunafaFingers handcrafted baklava, maamoul, and the classic flavors of Syrian cookies and sweet treats such as Baklava, Barazek, Mamoul, and Graybeh. We import the best Arabic Sweets so you can order from the best confectionery in London such as Arabic Sweets, Turkish Sweets, and Turkish Coffee. Our delivery team will arrive in no time if you place your order online.": "Find out more about kunafaFingers handcrafted baklava, maamoul, and the classic flavors of Syrian cookies and sweet treats such as Baklava, Barazek, Mamoul, and Graybeh. We import the best Arabic Sweets so you can order from the best confectionery in London such as Arabic Sweets, Turkish Sweets, and Turkish Coffee. Our delivery team will arrive in no time if you place your order online.",
 // TopFooter 
  "ABOUT": "ABOUT",
  "is the most popular online store for sweets and desserts in Malaysia. We are bringing the best Ingredients and Luxury flavors in high-quality products.": "is the most popular online store for sweets and desserts in Malaysia. We are bringing the best Ingredients and Luxury flavors in high-quality products.",
  "CONTACT DETAILS": "CONTACT DETAILS",
  "Under Bukit Bintang Monorail station Main Entrance": "Under Bukit Bintang Monorail station Main Entrance",
  "Bukit Bintang next to Maybank": "Bukit Bintang next to Maybank",
  "Contact us": "Contact us",
  "Email": "Email",
  "Your email": "Your email",
  "Message": "Message",
  "Your message": "Your message",
  "Send": "Send",
  "Home": "Home",
  "Contact": "Contact",
  "Terms and Conditions": "Terms and Conditions",
  "Shipping & Refund Policy": "Shipping & Refund Policy",
  "Privacy Policy": "Privacy Policy",
  "FAQ": "FAQ",
  "Copyright": "Copyright",
  "The best sweets ever in the Malaysie": "The best sweets ever in the Malaysie",
  "Cookies help us deliver our services. By using our services, you agree to our use of cookies.": "Cookies help us deliver our services. By using our services, you agree to our use of cookies.",
  "Dismiss": "Dismiss",
  "Got it": "Got it",
  "ACCOUNT DETAILS": "ACCOUNT DETAILS",
  "My account": "My account",
  "Name": "Name",
  "Your Name": "Your Name",
  "Email": "Email",
  "Your Email": "Your Email",
  "Save changes": "Save changes",
  "cart": "cart",
  "My cart": "My cart",
  "PRODUCT NAME": "PRODUCT NAME",
  "PRICE": "PRICE",
  "quantity": "quantity",
  "subtotal": "subtotal",
  "CONTINUE SHOPPING": "CONTINUE SHOPPING",
  "CART TOTALS": "CART TOTALS",
  "Total price": "Total price",
  "Proceed to checkout": "Proceed to checkout",
  "Coupon": "Coupon",
  "Coupon code": "Coupon code",
  "Apply coupon": "Apply coupon",
  "Message": "Message",
  "Your note": "Your note",
  "Send": "Send",
  "Save changes": "Save changes",
  "Add to cart": "Add to cart",
  "Not Found": "Not Found",
  "go to home page": "go to home page",
  "WISHLIST": "WISHLIST",
  "My wishlist": "My wishlist",
  "PRODUCT NAME": "PRODUCT NAME",
  "UNIT PRICE": "UNIT PRICE",
  "quantity": "quantity",
  "Cart": "Cart",
  "No Items": "No Items",
  "view cart": "view cart",
  "checkout": "checkout",
  "Subtotal": "Subtotal",
  "ACCOUNT DETAILS": "ACCOUNT DETAILS",
  "WISHLIST": "WISHLIST",
  "LOGOUT": "LOGOUT",
  "CHOOSE LANGUAGE": "CHOOSE LANGUAGE",
  "English": "English",
  "Chinese": "Chinese",
  "Malaysian": "Malaysian",
  "Arabic": "Arabic",
  "resend code": "resend code",
  "LOGIN": "LOGIN",
  "Name": "Name",
  "Your Name": "Your Name",
  "Email": "Email",
  "Your Email": "Your Email",
  "Next": "Next",
  "Go back": "Go back",
  "The code sent to your email:": "The code sent to your email:",
  "Code": "Code",
  "Your Code": "Your Code",
  "Submit": "Submit",
  "Send Code Again": "Send Code Again",
  "Remove from wishlist": "Remove from wishlist",
  "Add to wishlist": "Add to wishlist",
  "ADD TO CART": "ADD TO CART",
 

  "Price": "Price",
  "Calories": "Calories",
  "Cal": "Cal",

    },
  },
  Chinese: {
    translation: {     
        "All": "全部",
      "Search": "搜索",
      "Login / Register": "登录 / 注册",
      "logout processing": "退出处理",
      "ACCOUNT DETAILS": "帐户详细信息",
      "WISHLIST": "心愿单",
      "LOGOUT": "退出",
      "CART": "购物车",
      "Login": "登录",
      "Browse All": "浏览全部",
      "Order Now": "立即订购",
      "Browse Products": "浏览产品",
      "View Deals": "查看优惠",
      "More Products": "更多产品",
      "SHOP BY CATEGORY": "按类别购物",
      "Suggested for You": "为您推荐",
      "view deals": "查看优惠",
         // Glance
         "kunafaFingers - Online Sweet Shop Malaysia": "kunafaFingers - 马来西亚在线甜品商店",
         "The Levantine region has been making sweets and pastries for centuries. Traditional skills are passed down from father to son, who have taken years to master their craft, from generation to generation. In Malaysia, kunafaFingers online store is the number one online confectionery store that sells Lebanese Sweets, Nawashef pistachios and walnuts, and Arabic confectionery online.": "黎凡特地区几个世纪以来一直在制作糖果和糕点。传统技艺由父传子，他们经过多年才能精通这门手艺，代代相传。在马来西亚，kunafaFingers在线商店是售卖黎巴嫩糖果、纳瓦舍夫开心果和核桃以及阿拉伯糖果的第一在线糖果店。",
         "Find out more about kunafaFingers handcrafted baklava, maamoul, and the classic flavors of Syrian cookies and sweet treats such as Baklava, Barazek, Mamoul, and Graybeh. We import the best Arabic Sweets so you can order from the best confectionery in London such as Arabic Sweets, Turkish Sweets, and Turkish Coffee. Our delivery team will arrive in no time if you place your order online.": "了解更多关于kunafaFingers手工制作的巴克拉瓦、马穆勒和叙利亚饼干以及诸如巴克拉瓦、巴拉泽克、马穆勒和格雷贝等经典口味的甜点。我们进口最好的阿拉伯甜点，因此您可以从伦敦最好的糖果店订购阿拉伯甜点、土耳其甜点和土耳其咖啡。如果您在线下订单，我们的送货团队将立即到达。",
         // TopFooter
         "ABOUT": "关于",
         "is the most popular online store for sweets and desserts in Malaysia. We are bringing the best Ingredients and Luxury flavors in high-quality products.": "是马来西亚最受欢迎的甜品和甜点在线商店。我们为高品质产品带来最好的原料和豪华口味。",
         "CONTACT DETAILS": "联系方式",
         "Under Bukit Bintang Monorail station Main Entrance": "在武吉免登单轨车站主入口下方",
         "Bukit Bintang next to Maybank": "武吉免登靠近马来亚银行",
         "Contact us": "联系我们",
         "Email": "电子邮件",
         "Your email": "您的电子邮件",
         "Message": "留言",
         "Your message": "您的留言",
         "Send": "发送",
         "Home": "首页",
         "Contact": "联系",
         "Terms and Conditions": "条款和条件",
         "Shipping & Refund Policy": "运输和退款政策",
         "Privacy Policy": "隐私政策",
         "FAQ": "常见问题",
         "Copyright": "版权",
         "The best sweets ever in the Malaysie": "马来西亚最棒的甜品",
         "Cookies help us deliver our services. By using our services, you agree to our use of cookies.": "Cookies帮助我们提供服务。使用我们的服务即表示您同意我们使用cookies。",
         "Dismiss": "关闭",
         "Got it": "知道了",
         "ACCOUNT DETAILS": "帐户详情",
         "My account": "我的帐户",
         "Name": "名称",
         "Your Name": "您的姓名",
         "Email": "电子邮件",
         "Your Email": "您的电子邮件",
         "Save changes": "保存更改",
         "Save changes": "保存更改",
         "cart": "购物车",
         "My cart": "我的购物车",
         "PRODUCT NAME": "产品名称",
         "PRICE": "价格",
         "quantity": "数量",
         "subtotal": "小计",
         "CONTINUE SHOPPING": "继续购物",
         "CART TOTALS": "购物车总计",
         "Total price": "总价格",
         "Proceed to checkout": "去结账",
         "Coupon": "优惠券",
         "Coupon code": "优惠码",
         "Apply coupon": "应用优惠券",
         "Message": "留言",
         "Your note": "您的备注",
         "Send": "发送",
         "Save changes": "保存更改",
         "Add to cart": "添加到购物车",
         "Not Found": "未找到",
         "go to home page": "回到首页",
         "WISHLIST": "愿望清单",
         "My wishlist": "我的愿望清单",
         "PRODUCT NAME": "产品名称",
         "UNIT PRICE": "单价",
         "quantity": "数量",
         "Cart": "购物车",
         "No Items": "没有商品",
         "view cart": "查看购物车",
         "checkout": "结账",
         "Subtotal": "小计",
         "ACCOUNT DETAILS": "帐户详情",
         "WISHLIST": "愿望清单",
         "LOGOUT": "退出登录",
         "CHOOSE LANGUAGE": "选择语言",
         "English": "英语",
         "Chinese": "中文",
         "Malaysian": "马来西亚语",
         "Arabic": "阿拉伯语",
         "resend code": "重新发送代码",
         "LOGIN": "登录",
         "Name": "名称",
         "Your Name": "您的姓名",
         "Email": "电子邮件",
         "Your Email": "您的电子邮件",
         "Next": "下一步",
         "Go back": "返回",
         "The code sent to your email:": "发送到您电子邮件的代码：",
         "Code": "代码",
         "Your Code": "您的代码",
         "Submit": "提交",
         "Send Code Again": "重新发送代码",
         "Remove from wishlist": "从愿望清单中移除",
         "Add to wishlist": "添加到愿望清单",
         "ADD TO CART": "添加到购物车",
         "Price": "价格",
         "Calories": "卡路里",
         "Cal": "卡"
    
    },
  },
  Malaysian: {
    translation: { 
      "#1 Online Sweet Store in malaysia": "#1 Kedai Manisan Dalam Talian di Malaysia",
      "All": "Semua",
      "Search": "Cari",
      "Login / Register": "Log Masuk / Daftar",
      "logout processing": "pemprosesan log keluar",
      "ACCOUNT DETAILS": "DETAIL AKAUN",
      "WISHLIST": "SENARAI KEINGINAN",
      "LOGOUT": "LOG KELUAR",
      "CART": "TROLI",
      "Login": "Log Masuk",
      "Browse All": "Semua Penerokaan",
      "Order Now": "Tempah Sekarang",
      "Browse Products": "Semak Produk",
      "View Deals": "Lihat Tawaran",
      "More Products": "Lebih Produk",
      "SHOP BY CATEGORY": "BELI MENGIKUT KATEGORI",
      "Suggested for You": "Cadangan untuk Anda",
      "view deals": "lihat tawaran",
      "kunafaFingers - Online Sweet Shop Malaysia": "kunafaFingers - Kedai Manisan Dalam Talian Malaysia",
      "The Levantine region has been making sweets and pastries for centuries. Traditional skills are passed down from father to son, who have taken years to master their craft, from generation to generation. In Malaysia, kunafaFingers online store is the number one online confectionery store that sells Lebanese Sweets, Nawashef pistachios and walnuts, and Arabic confectionery online.": "Kawasan Levantine telah menghasilkan manisan dan pastri selama berabad-abad. Kemahiran tradisional diwarisi dari bapa ke anak, yang mengambil bertahun-tahun untuk menguasai kemahiran mereka, dari generasi ke generasi. Di Malaysia, kedai dalam talian kunafaFingers adalah kedai kraf konfeksi yang terbaik yang menjual Manisan Lubnan, Nawashef pistachios dan walnut, serta manisan Arab dalam talian.",
      "Find out more about kunafaFingers handcrafted baklava, maamoul, and the classic flavors of Syrian cookies and sweet treats such as Baklava, Barazek, Mamoul, and Graybeh. We import the best Arabic Sweets so you can order from the best confectionery in London such as Arabic Sweets, Turkish Sweets, and Turkish Coffee. Our delivery team will arrive in no time if you place your order online.": "Ketahui lebih lanjut tentang baklava, maamoul, dan rasa klasik biskut dan makanan manis Syria seperti Baklava, Barazek, Mamoul, dan Graybeh yang dibuat tangan oleh kunafaFingers. Kami mengimport Manisan Arab terbaik supaya anda boleh membuat pesanan dari kedai konfeksi terbaik di London seperti Manisan Arab, Manisan Turki, dan Kopi Turki. Pasukan penghantaran kami akan tiba dengan cepat jika anda membuat pesanan dalam talian.",
      "ABOUT": "TENTANG",
      "is the most popular online store for sweets and desserts in Malaysia. We are bringing the best Ingredients and Luxury flavors in high-quality products.": "adalah kedai dalam talian yang paling popular untuk manisan dan pencuci mulut di Malaysia. Kami membawa bahan terbaik dan rasa mewah dalam produk berkualiti tinggi.",
      "CONTACT DETAILS": "MAKLUMAT HUBUNGI",
      "Under Bukit Bintang Monorail station Main Entrance": "Di bawah Pintu Masuk Utama Stesen Monorel Bukit Bintang",
      "Bukit Bintang next to Maybank": "Bukit Bintang bersebelahan dengan Maybank",
      "Contact us": "Hubungi kami",
      "Email": "Emel",
      "Your email": "Emel anda",
      "Message": "Mesej",
      "Your message": "Mesej anda",
      "Send": "Hantar",
      "Home": "Laman Utama",
      "Contact": "Hubungi",
      "Terms and Conditions": "Terma dan Syarat",
      "Shipping & Refund Policy": "Polisi Penghantaran & Bayaran Balik",
      "Privacy Policy": "Dasar Privasi",
      "FAQ": "Soalan Lazim",
      "Copyright": "Hak Cipta",
      "The best sweets ever in the Malaysie": "Manisan terbaik di Malaysia",
      "Cookies help us deliver our services. By using our services, you agree to our use of cookies.": "Kuki membantu kami memberikan perkhidmatan kami. Dengan menggunakan perkhidmatan kami, anda bersetuju dengan penggunaan kuki kami.",
      "Dismiss": "Tutup",
      "Got it": "Faham",
      "Save changes": "Simpan perubahan",
      "PRODUCT NAME": "NAMA PRODUK",
      "PRICE": "HARGA",
      "quantity": "kuantiti",
      "subtotal": "jumlah kecil",
      "CONTINUE SHOPPING": "TERUS MEMBELI-BELAH",
      "CART TOTALS": "JUMLAH TROLI",
      "Total price": "Jumlah harga",
      "Proceed to checkout": "Teruskan ke pembayaran",
      "Coupon": "Kupon",
      "Coupon code": "Kod kupon",
      "Apply coupon": "Guna kupon",
      "Your note": "Nota anda",
      "Add to cart": "Tambah ke troli",
      "Not Found": "Tidak Ditemui",
      "go to home page": "pergi ke halaman utama",
      "My wishlist": "Senarai keinginan saya",
      "UNIT PRICE": "HARGA UNIT",
      "No Items": "Tiada item",
      "view cart": "lihat troli",
      "checkout": "semak",
      "Price": "Harga",
      "Calories": "Kalori",
      "Cal": "Kal",
      "ADD TO CART": "TAMBAH KE TROLI"
    },
  },
  Arabic: {
    translation: { 
        "#1 Online Sweet Store in malaysia": "#1 متجر حلويات عبر الإنترنت في ماليزيا",
  "All": "الكل",
  "Search": "بحث",
  "Login / Register": "تسجيل الدخول / التسجيل",
  "logout processing": "معالجة تسجيل الخروج",
  "ACCOUNT DETAILS": "تفاصيل الحساب",
  "WISHLIST": "قائمة الرغبات",
  "LOGOUT": "تسجيل الخروج",
  "CART": "سلة التسوق",
  "Login": "تسجيل الدخول",
  "Browse All": "تصفح الكل",
  "Order Now": "اطلب الآن",
  "Browse Products": "تصفح المنتجات",
  "View Deals": "عرض العروض",
  "More Products": "المزيد من المنتجات",
  "SHOP BY CATEGORY": "التسوق حسب الفئة",
  "Suggested for You": "مقترح لك",
  "view deals": "عرض العروض",
  "kunafaFingers - Online Sweet Shop Malaysia": "متجر كونافا فنجرز للحلويات عبر الإنترنت في ماليزيا",
  "The Levantine region has been making sweets and pastries for centuries. Traditional skills are passed down from father to son, who have taken years to master their craft, from generation to generation. In Malaysia, kunafaFingers online store is the number one online confectionery store that sells Lebanese Sweets, Nawashef pistachios and walnuts, and Arabic confectionery online.": "منذ قرون ، تقوم منطقة الشام بصنع الحلويات والمعجنات. تُورث المهارات التقليدية من الأب إلى الابن ، الذين قضوا سنوات في تعلم حرفتهم من جيل إلى جيل. في ماليزيا ، يعد متجر كونافا فنجرز عبر الإنترنت أفضل متجر للحلويات عبر الإنترنت يبيع الحلويات اللبنانية ومكسرات النواشف والجوز بالإضافة إلى الحلويات العربية.",
  "Find out more about kunafaFingers handcrafted baklava, maamoul, and the classic flavors of Syrian cookies and sweet treats such as Baklava, Barazek, Mamoul, and Graybeh. We import the best Arabic Sweets so you can order from the best confectionery in London such as Arabic Sweets, Turkish Sweets, and Turkish Coffee. Our delivery team will arrive in no time if you place your order online.": "اكتشف المزيد حول كونافا فنجرز من الباقلوا الحلوة المصنوعة يدويًا والمعمول والنكهات الكلاسيكية للكعك السوري والحلويات مثل الباقلوا والبرازق والمعمول والقريبة. نقوم بتوريد أفضل الحلويات العربية حتى تتمكن من الطلب من أفضل محلات الحلويات في لندن مثل الحلويات العربية والحلويات التركية والقهوة التركية. سيصل فريق التسليم لدينا في أقرب وقت ممكن إذا قمت بطلبك عبر الإنترنت.",
  "ABOUT": "حول",
  "is the most popular online store for sweets and desserts in Malaysia. We are bringing the best Ingredients and Luxury flavors in high-quality products.": "هو أكثر متجر عبر الإنترنت شعبية للحلويات والحلويات في ماليزيا. نحن نقدم أفضل المكونات والنكهات الفاخرة في منتجات عالية الجودة.",
  "CONTACT DETAILS": "تفاصيل الاتصال",
  "Under Bukit Bintang Monorail station Main Entrance": "تحت محطة بوكيت بينتانج للمونوريل المدخل الرئيسي",
  "Bukit Bintang next to Maybank": "بوكيت بينتانج بالقرب من مايبانك",
  "Contact us": "اتصل بنا",
  "Email": "البريد الإلكتروني",
  "Your email": "بريدك الإلكتروني",
  "Message": "رسالة",
  "Your message": "رسالتك",
  "Send": "إرسال",
  "Home": "الصفحة الرئيسية",
  "Contact": "اتصل بنا",
  "Terms and Conditions": "الشروط والأحكام",
  "Shipping & Refund Policy": "سياسة الشحن والاسترجاع",
  "Privacy Policy": "سياسة الخصوصية",
  "FAQ": "الأسئلة الشائعة",
  "Copyright": "حقوق النشر",
  "The best sweets ever in the Malaysie": "أفضل الحلويات على الإطلاق في ماليزيا",
  "Cookies help us deliver our services. By using our services, you agree to our use of cookies.": "تساعدنا ملفات تعريف الارتباط في تقديم خدماتنا. باستخدام خدماتنا ، فإنك توافق على استخدامنا لملفات تعريف الارتباط.",
  "Dismiss": "تجاهل",
  "Got it": "فهمت",
  "My account": "حسابي",
  "Name": "الاسم",
  "Your Name": "اسمك",
  "Save changes": "حفظ التغييرات",
  "cart": "سلة التسوق",
  "My cart": "سلتي",
  "PRODUCT NAME": "اسم المنتج",
  "PRICE": "السعر",
  "quantity": "الكمية",
  "subtotal": "المجموع الفرعي",
  "CONTINUE SHOPPING": "متابعة التسوق",
  "CART TOTALS": "إجمالي السلة",
  "Total price": "السعر الإجمالي",
  "Proceed to checkout": "الانتقال إلى الدفع",
  "Coupon": "كوبون",
  "Coupon code": "رمز الكوبون",
  "Apply coupon": "تطبيق الكوبون",
  "Your note": "ملاحظتك",
  "Save changes": "حفظ التغييرات",
  "Add to cart": "أضف إلى السلة",
  "Not Found": "غير موجود",
  "go to home page": "الذهاب إلى الصفحة الرئيسية",
  "WISHLIST": "قائمة الرغبات",
  "My wishlist": "قائمة رغباتي",
  "UNIT PRICE": "سعر الوحدة",
  "Cart": "السلة",
  "No Items": "لا توجد عناصر",
  "view cart": "عرض السلة",
  "checkout": "الدفع",
  "Subtotal": "المجموع الفرعي",
  "ACCOUNT DETAILS": "تفاصيل الحساب",
  "WISHLIST": "قائمة الرغبات",
  "LOGOUT": "تسجيل الخروج",
  "CHOOSE LANGUAGE": "اختر اللغة",
  "English": "الإنجليزية",
  "Chinese": "الصينية",
  "Malaysian": "الماليزية",
  "Arabic": "العربية",
  "resend code": "إعادة إرسال الرمز",
  "LOGIN": "تسجيل الدخول",
  "Name": "الاسم",
  "Your Name": "اسمك",
  "Email": "البريد الإلكتروني",
  "Your Email": "بريدك الإلكتروني",
  "Next": "التالي",
  "Go back": "العودة",
  "The code sent to your email:": "الرمز المرسل إلى بريدك الإلكتروني:",
  "Code": "الرمز",
  "Your Code": "رمزك",
  "Submit": "إرسال",
  "Send Code Again": "إعادة إرسال الرمز",
  "Remove from wishlist": "إزالة من قائمة الرغبات",
  "Add to wishlist": "إضافة إلى قائمة الرغبات",
  "ADD TO CART": "أضف إلى السلة",
  "Price": "السعر",
  "Calories": "السعرات الحرارية",
  "Cal": "سعرة",
    
},
},
};
const lng = localStorage.getItem("kfLanguage") || "English";
i18n.use(initReactI18next).init({
  resources,
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
