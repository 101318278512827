import React, { useContext, useEffect, useState } from "react";
import { MdDateRange } from "react-icons/md";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTheme } from "../../Context/ThemeContext";
import { usePopup } from "../../Context/PopupContext";
import { fetchReports } from "../../Api/DashboardApi";
import useOutsideClick from "../../Shared/Components/UseOutsideClick";
import { toastLoading } from "../../Shared/Components/ToastNotification/Toast";
import { toast } from "react-toastify";

function ReportsView() {
  const { theme } = useTheme();
  const startDateRef = useOutsideClick(() => setStartDateOpen(false));
  const endDateRef = useOutsideClick(() => setEndDateOpen(false));

  const formatDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  };
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formattedStartDate, setFormattedStartDate] = useState(
    formatDate(startDate)
  );
  const [formattedEndDate, setFormattedEndDate] = useState(formatDate(endDate));
  useEffect(() => {
    setFormattedStartDate(formatDate(startDate));
    setFormattedEndDate(formatDate(endDate));
  }, [startDate, endDate]);
  const { startDateOpen, setStartDateOpen, endDateOpen, setEndDateOpen } =
    usePopup();
  const handleSortSubmit = async (e) => {
    e.preventDefault();
    // Format the selected dates as "year-month-day"
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    setFormattedStartDate(formattedStartDate);
    setFormattedEndDate(formattedEndDate);
    try {
      const toastId = toastLoading("Loading ...");
      const response = await fetchReports(formattedStartDate, formattedEndDate);
      downloadFile(response.data, "report.xlsx");
      toast.dismiss(toastId);
    } catch (err) {
      console.log(err);
    }
  };
  const downloadFile = (data, filename) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  return (
    <div>
      <h1 className="text-2xl font-semibold">Reports</h1>
      <div className="flex justify-end items-center w-full mt-3">
        <form onSubmit={handleSortSubmit} className="flex gap-2 items-center">
          <div
            onClick={() => {
              setStartDateOpen(!startDateOpen);
              setEndDateOpen(false);
            }}
            className={`relative cursor-pointer`}
            ref={startDateRef}
          >
            <Calendar
              className={`${
                startDateOpen ? "" : "hidden"
              } absolute min-w-[350px] top-[40px] right-0 z-50`}
              onChange={setStartDate}
              value={startDate}
            />
            <div className="flex items-center gap-2 bg-PrimaryColor text-white rounded-[20px] border-2 px-2 py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg duration-300">
              <h1 className="flex items-center gap-1">
                From {formattedStartDate}
              </h1>
              <MdDateRange />
            </div>
          </div>
          <div
            onClick={() => {
              setEndDateOpen(!endDateOpen);
              setStartDateOpen(false);
            }}
            className="relative cursor-pointer"
            ref={endDateRef}
          >
            <Calendar
              className={`${
                endDateOpen ? "" : "hidden"
              } absolute min-w-[350px] top-[40px] right-0 z-50`}
              onChange={setEndDate}
              value={endDate}
            />
            <div className="flex items-center gap-2 bg-PrimaryColor text-white rounded-[20px] border-2 px-2 py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg duration-300">
              <h1 className="flex items-center gap-1">To {formattedEndDate}</h1>
              <MdDateRange />
            </div>
          </div>
          <button
            type="submit"
            className="flex items-center gap-2 bg-PrimaryColor text-white rounded-[20px] border-2 px-2 py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg duration-300"
          >
            Download
          </button>
        </form>
      </div>
    </div>
  );
}

export default ReportsView;
