import React, { createContext, useState } from "react";
import { toastError } from "../Shared/Components/ToastNotification/Toast";
import { usePopup } from "./PopupContext";
import { useTranslation } from "react-i18next";


export const SweetContext = createContext();

function SweetProvider({ children }) {
  const { t } = useTranslation();
  const { setLoginOpen } = usePopup();
  const [updateFavorite, setUpdateFavorite] = useState(0);
  const [favoriteTotal, setFavoriteTotal] = useState();
  const [updateTotal, setUpdateTotal] = useState(0);
  const [totalCartPrice, setTotalCartPrice] = useState("");
  const [updataCartItems, setUpdateCartItems] = useState(0);
  const [updataCartInfo, setUpdateCartInfo] = useState(0);
  const [totalItems, setTotalItems] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [sweetItemId, SetSweetItemId] = useState(Number);
  const [loading, setLoading] = useState(true);
  const [selectedSweetItem, setSelectedSweetItem] = useState({
    id: "",
    price: "",
    tags: "",
    code: "",
    categoryId: "",
  });
    const [myLang, setMyLang] = useState("English");
    const [dir, setDir] = useState("ltr");
    const lazyLoading = () => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    const youHaveToLogin = () => {
      toastError(t("You have to login first"));
      setLoginOpen(true);
    };

    const contextValue = {
      updateFavorite,
      setUpdateFavorite,
      favoriteTotal,
      setFavoriteTotal,
      updateTotal,
      setUpdateTotal,
      totalCartPrice,
      setTotalCartPrice,
      updataCartItems,
      setUpdateCartItems,
      totalItems,
      setTotalItems,
      updataCartInfo,
      setUpdateCartInfo,
      totalPrice,
      setTotalPrice,
      sweetItemId,
      SetSweetItemId,
      youHaveToLogin,
      loading,
      setLoading,
      lazyLoading,
      selectedSweetItem,
      setSelectedSweetItem,
      myLang,
      setMyLang,
      dir,
      setDir,
    };
  return (
    <SweetContext.Provider value={contextValue}>
      {children}
    </SweetContext.Provider>
  );
}

export default SweetProvider;
