import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { usePopup } from "../../Context/PopupContext";

function Profile() {
  const { isHovered } = usePopup();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div
        className={`transition-opacity  duration-700 ${
          isHovered ? "  opacity-50 " : "opacity-100"
        } text-[#555555] min-h-[600px]`}
      >
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
