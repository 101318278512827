import React, { useState } from "react";
import Container from "../../Shared/Components/Container";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { IoCall, IoLocation } from "react-icons/io5";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { contactUs } from "../../Api/api";
import WhatsappIcon from "./WhatsappIcon";

function TopFooter() {
const { t } = useTranslation();
const lng = localStorage.getItem("kfLanguage") || "English";
const [email, setEmail] = useState("");
const [message, setMessage] = useState("");
const [loading, setLoading] = useState(false);

const submit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
  formData.append("email", email);
  formData.append("subject", message);
  if (email && message) {
    try {
      setLoading(true);
      const response = await contactUs(formData);
      console.log(response);
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("contact us err : ", error);
    } finally {
      setLoading(false);
    }
  }
};
return (
  <div className="bg-[#5b5b5b] pt-12 xl:pb-10 max-xl:pb-[50px]">
    <Container>
      <div className="grid grid-cols-3 max-xl:grid-cols-2 max-md:grid-cols-1 text-[#f1f1f1] gap-3 max-xl:pb-6">
        <div className="flex flex-col gap-2 basis-[33.3%] max-md:hidden">
          <h1 className="text-[1em] font-[600]">{t("ABOUT")} KUNAFA FINGERS</h1>
          <div className="w-[5%] h-1 bg-[#ffffff4d] my-2"></div>
          <div>
            <span className={`${lng === "Arabic" ? "ml-1" : "mr-1"} font-bold`}>
              kunafaFingers.com
            </span>
            {t(
              "is the most popular online store for sweets and desserts in Malaysia. We are bringing the best Ingredients and Luxury flavors in high-quality products."
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 basis-[33.3%] max-md:basis-[50%]">
          <h1 className="text-[1em] font-[600]">{t("CONTACT DETAILS")}</h1>
          <div className="w-[5%] h-1 bg-[#ffffff4d] my-2"></div>
          <Link
            to={`https://www.google.com/maps?q=3.1458996804816715,101.71137866615783`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2"
          >
            <IoLocation />
            {t("Under Bukit Bintang Monorail station Main Entrance")}
          </Link>
          <Link
            to={`https://www.google.com/maps?q=3.1475251658150043,101.71120782260756`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2"
          >
            <IoLocation />
            {t("Bukit Bintang next to Maybank")}
          </Link>
          <Link to="tel:+6011111064777" className="flex items-center gap-2">
            <IoCall />
            <div style={{ direction: "ltr" }}>+60 11 1106 4777</div>
          </Link>
          <div className="flex items-center gap-2 py-2">
            <Link
              target="_blank"
              className="border border-[#f1f1f1] p-1 rounded-full"
              to="https://www.facebook.com/share/XYHCRdFz71UVRyce/?mibextid=LQQJ4d"
            >
              <FaFacebookF />
            </Link>
            <Link
              target="_blank"
              className="border border-[#f1f1f1] p-1 rounded-full"
              to="https://www.tiktok.com/@kunafafingers?_t=8mfnzDD5VJf"
            >
              <FaTiktok />
            </Link>
            <Link
              target="_blank"
              className="border border-[#f1f1f1] p-1 rounded-full"
              to="https://www.instagram.com/kunafafingers?igsh=bG9kMDk1cmNmYXky"
            >
              <FaInstagram />
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-2 basis-[33.3%] max-md:basis-[50%]">
          <h1 className="text-[1em] font-[600] uppercase">{t("Contact us")}</h1>
          <div className="w-[5%] h-1 bg-[#ffffff4d] my-2"></div>
          <form onSubmit={submit} className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <h1 className="font-semibold">{t("Email")}</h1>
              <input
                className="outline-none focus:ring-0 bg-[#ffffff33] border border-[#ffffff17] px-3 py-1 rounded-[10px] placeholder:text-[#f1f1f1] hover:border-white/50 duration-300 "
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="text"
                placeholder={t("Your email")}
              />
            </div>
            <div className="flex flex-col gap-1">
              <h1 className="font-semibold">{t("Message")}</h1>
              <textarea
                className="min-h-[70px] max-h-[70px] outline-none focus:ring-0 bg-[#ffffff33] border border-[#ffffff17] px-3 py-1 rounded-[10px] placeholder:text-[#f1f1f1] hover:border-white/50 duration-300 resize-none"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                type="text"
                placeholder={t("Your message")}
              />
            </div>
            <button
              type="submit"
              className={`${
                loading && "opacity-70 pointer-events-none"
              } min-w-[60px] bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 py-2 px-3 rounded-[10px] cursor-pointer text-white font-semibold flex justify-center items-center w-fit`}
            >
              {loading ? (
                <div className="py-[2px]">
                  <AiOutlineLoading3Quarters className="animate-spin" />
                </div>
              ) : (
                t("Send")
              )}
            </button>
          </form>
        </div>
      </div>
    </Container>
    <WhatsappIcon />
  </div>
);
}

export default TopFooter;

