import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CategoryItemSkeleton() {
  return (
    <div className="h-[200px]">
      <Skeleton className="w-full h-full" />
    </div>
  );
}

export default CategoryItemSkeleton;
