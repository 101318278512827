import Cookies from "js-cookie";
import React, { useState } from "react";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTheme } from "../../Context/ThemeContext";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { toastError } from "../../Shared/Components/ToastNotification/Toast";
import { useTranslation } from "react-i18next";
import { adminLogin } from "../../Api/DashboardApi";

function Admin() {
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    try {
      if (adminEmail === "" || password === "") {
        toastError("email and password are required");
      } else {
        formData.append("email", adminEmail);
        formData.append("password", password);
        setLoading(true);
        const response = await adminLogin(formData);
        Cookies.set("kfAdminToken", response.data.token);
        console.log(response);
        setTimeout(() => {
          if (response.success === true) navigate("/dashboard");
        }, 500);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div
      style={{ direction: "ltr" }}
      className={`${
        theme === "dark" ? "bg-MainBg" : "bg-MainBg"
      } h-screen flex justify-center items-center relative`}
    >
      {/* <div
        onClick={() => {
          theme === "light" ? setTheme("dark") : setTheme("light");
        }}
        className={`${
          theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
        } absolute top-5 right-5 p-[5px] cursor-pointer rounded-full`}
      >
        {theme === "dark" ? (
          <MdLightMode size={20} />
        ) : (
          <MdDarkMode size={20} />
        )}
      </div> */}
      <div
        className={`${
          theme === "dark"
            ? "bg-MainBg shadow-white shadow-registerWayBoxShadow"
            : "bg-white shadow-xl"
        } rounded-[10px] py-[20px] px-[36px] w-[402px] m-auto overflow-hidden`}
      >
        <h1 className="text-center my-[15px] font-[700] text-[25px] leading-[32px]">
          Admin Login
        </h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <input
            className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] duration-300 w-full mb-3 h-[46px] rounded-[8px] text-[16px] font-[500] border-2 outline-none py-[21px] px-[19px] focus:ring-0`}
            type="text"
            placeholder="Please input your email ."
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
          />
          <div className="relative ">
            <input
              className={`bg-[#fbf6f6] border-[#b5a7ac66] cursor-pointer hover:border-[#222] focus:border-[#222] duration-300 w-full h-[46px] rounded-[8px] text-[16px] font-[500] border-2 outline-none py-[21px] px-[19px] focus:ring-0`}
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <IoEye
              onClick={() => setShowPassword(true)}
              className={`${
                showPassword ? "hidden" : ""
              } text-[#777] hover:text-[#222] duration-300 absolute cursor-pointer right-4 top-[50%] -translate-y-[50%]`}
            />
            <IoEyeOff
              onClick={() => setShowPassword(false)}
              className={`${
                showPassword ? "" : "hidden"
              } text-[#777] hover:text-[#222] duration-300 absolute cursor-pointer right-4 top-[50%] -translate-y-[50%]`}
            />
          </div>
          <button
            className={`${
              loading ? "opacity-30 cursor-default pointer-events-none" : ""
            } flex justify-center items-center h-[46px] w-full min-w-[209px]  text-[20px] text-[#fff] font-[700] outline-none rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor my-[10px] duration-300`}
            type="submit"
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Log In"
            )}
          </button>
          <h1
            onClick={() => navigate("/forgetPassword")}
            className={`underline mx-auto font-[400] text-[13px] cursor-pointer text-[#777] hover:text-[#222] duration-300`}
          >
            Forget password ?
          </h1>
        </form>
      </div>
    </div>
  );
}

export default Admin;
