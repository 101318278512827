import axios from "axios";
import Cookies from "js-cookie";
import { toastSuccess } from "../Shared/Components/ToastNotification/Toast";

// export const BASE_URL = "http://127.0.0.1:8000";

export const BASE_URL = "https://kunafa-fingers-backend.tecrek.com/public";

export const userToken = Cookies.get("kfToken");

const User = userToken;

export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${User}`,
  },
});



export const adminToken = Cookies.get("kfAdminToken");
const AdminBearerToken = adminToken;

export const apiAdmin = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${AdminBearerToken}`,
  },
});


export const language = async () => {
  try {
    const response = await apiClient.get(`/api/language`);
    return response;
  } catch (err) {
    console.error("language err : ", err);
  }
};

export const deviceInfo = async () => {
  try {
    const response = await apiClient.get(`/api/info`);
    return response;
  } catch (err) {
    console.error("device info err : ", err);
  }
};

export const contactUs = async (data) => {
  try {
    const response = await apiClient.post(`/api/ContactUs`, data);
    toastSuccess(response.data.message,false)
    return response;
  } catch (err) {
    console.error("contact us err : ", err);
  }
};

