import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { usePopup } from "../../../Context/PopupContext";
import Main from "../Section/Main";
import Glance from "../Section/Glance";
import SweetByCategory from "../Section/SweetByCategory";
import SweetCollection from "../Section/SweetCollection";
import CategoryCollection from "../../Components/CategoryCollection";
import SuggestedForYou from "../Section/SuggestedForYou";
import ScrollTopButton from "../../Components/ScrollTopButton";
import SweetItemDetails from "../../Components/SweetItemDetails";
import { deviceInfo, language } from "../../../Api/api";
import LoadingPage from "../../../Shared/Components/LoadingPage";
import { SweetContext } from "../../../Context/SweetContext";

function Home() {
  const { sweetItemDetailsOpen } = usePopup();
  const { setDir } = useContext(SweetContext);
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(false);
  const languageDefined = localStorage.getItem("languageDefined");
  const whatIsLanguage = async () => {
    try {
      setLoading(true);
      const response = await language();
      console.log(response.data.language);
      if (response.data.language === "Arabic") {
        setDir("rtl");
        localStorage.setItem("languageId", 2);
        localStorage.setItem("kfLanguage", "Arabic");
      } else if (response.data.language === "Chinese") {
        localStorage.setItem("languageId", 3);
        localStorage.setItem("kfLanguage", "Chinese");
      } else if (response.data.language === "Malaysian") {
        localStorage.setItem("languageId", 4);
        localStorage.setItem("kfLanguage", "Malaysian");
      } else {
        localStorage.setItem("languageId", 1);
        localStorage.setItem("kfLanguage", "English");
      }
      // localStorage.setItem("languageDefined", true);
    } catch (error) {
      console.error("define language err : ", error);
    } finally {
      setLoading(false);
    }
  };
  const info = async () => {
    try {
      const response = await deviceInfo();
      setSessionId(response.data.data.id);
    } catch (error) {
      console.error("device info err : ", error);
    }
  };
  useEffect(() => {
    // !languageDefined &&
    whatIsLanguage();
    info();
    window.scrollTo(0, 0);
  }, []);
  return loading ? (
    <LoadingPage />
  ) : (
    <div>
      <Header sessionId={sessionId} />
      <div>
        <Main />
        <SweetByCategory />
        {/* <SweetCollection /> */}
        <CategoryCollection />
        <SuggestedForYou />
        <Glance />
      </div>
      {sweetItemDetailsOpen && <SweetItemDetails />}
      <ScrollTopButton />
      <Footer />
    </div>
  );
}

export default Home;
