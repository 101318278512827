import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { SweetContext } from "../../Context/SweetContext";
import { deleteItemFromCart } from "../../Api/CartApi";
import { usePopup } from "../../Context/PopupContext";

function ItemInCart({ id, image, name, quantity, price, subTotalPrice }) {
  const { t } = useTranslation();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { setUpdateCartItems, totalItems, setUpdateTotal } =
    useContext(SweetContext);
  const { setCartOpen } = usePopup();
  const deleteItem = async () => {
    try {
      setDeleteLoading(true);
      const response = await deleteItemFromCart(id);
      console.log(response);
      setUpdateCartItems((prev) => prev + 1);
      setUpdateTotal((prev) => prev + 1);
    } catch (error) {
      console.error("delete item from cart err : ", error);
    } finally {
      setDeleteLoading(false);
      parseFloat(totalItems) === 0 && setCartOpen(false);
    }
  };
  return (
    <div className="flex flex-col gap-2 relative pb-2 border-b border-b-[#0000001a]">
      <div className="absolute top-2 right-2">
        {deleteLoading ? (
          <AiOutlineLoading3Quarters className="animate-spin" size={20} />
        ) : (
          <IoIosCloseCircleOutline
            onClick={deleteItem}
            className="cursor-pointer text-[#ccc] hover:text-black duration-300"
            size={25}
          />
        )}
      </div>
      <div className="flex gap-2">
        <div className="basis-[30%] h-full">
          <img className="w-full h-full" src={image} alt="" />
        </div>
        <div className="flex flex-col justify-between gap-2">
          <div className="text-[#2b2b2b]">{name}</div>
          <div className="flex items-center gap-1 text-[#ccc]">
            <div>{quantity}</div> x
            <div className="text-[#2b2b2b]/70 font-[500]">
              {parseFloat(price).toFixed(2)} RM
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-2 font-[500]">
        <div className="text-[#2b2b2b]/70">{t("Subtotal")}:</div>
        <div className="flex items-center">
          {parseFloat(subTotalPrice).toFixed(2)} RM
        </div>
      </div>
    </div>
  );
}

export default ItemInCart;


