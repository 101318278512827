import React from "react";
import { Link } from "react-router-dom";

function HoverOnNameListItem({ className, link, onClick, content, lastItem }) {
  return (
    <Link
      className={`${className} text-[#777] hover:text-[#353535] duration-300 min-w-fit py-[10px] ${
        !lastItem && "border-b border-b-[#d1d1d1]"
      }`}
      to={link}
      onClick={onClick}
    >
      {content}
    </Link>
  );
}

export default HoverOnNameListItem;
