import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Container from "../../Shared/Components/Container";
import { Link } from "react-router-dom";
import { SweetContext } from "../../Context/SweetContext";
import CartItemLapSkeleton from "../Components/SkeletonLoading/CartItemLapSkeleton";
import CartItemMobSkeleton from "../Components/SkeletonLoading/CartItemMobSkeleton";
import CartItemLap from "../Components/CartItemLap";
import CartItemMob from "../Components/CartItemMob";
import { fetchCartItemInfo, verifyCoupon } from "../../Api/CartApi";
import { FaBookmark } from "react-icons/fa";
import { PiNoteFill } from "react-icons/pi";
import Skeleton from "react-loading-skeleton";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Cart() {
  const { t } = useTranslation();
  const language_id = localStorage.getItem("languageId");
  const [sweets, setSweets] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponLoading, setCouponLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const { updataCartInfo, setUpdateCartInfo, setUpdateCartItems } =
    useContext(SweetContext);
  const [loading, setLoading] = useState(true);
  const fetchCartItems = async () => {
    try {
      setLoading(true);
      const response = await fetchCartItemInfo(language_id);
      console.log(response.data);
      console.log(response.data.Items);
      setSweets(response.data.Items);
      setTotalPrice(response.data.total_sub_total_price);
    } catch (error) {
      console.error("get cart items err : ", error);
    } finally {
      setLoading(false);
    }
  };
  const couponSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("code", coupon);
    try {
      setCouponLoading(true);
      const response = await verifyCoupon(formData);
      console.log(response.data);
      setUpdateCartInfo((prev) => prev + 1);
      setUpdateCartItems((prev) => prev + 1);
      setCoupon("");
    } catch (error) {
      console.error("verify coupon code err : ", error);
    } finally {
      setCouponLoading(false);
    }
  };
  const messageSubmit = async (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchCartItems();
  }, [updataCartInfo]);

  return (
    <div>
      <Header />
      <div className="min-h-[600px]">
        <div className="flex justify-center items-center uppercase p-[15px] text-[27px] font-[700] leading-[32px] tracking-[1.36px] max-md:text-[22px] max-md:leading-[26px] max-md:tracking-[1.12px] bg-[#f7f7f7]">
          {t("cart")}
        </div>
        <Container>
          <div className="py-5 bg-white">
            <div className="flex flex-col gap-3 mb-[12px]">
              <h1 className="font-[700] text-[26px] leading-[32px] max-md:text-[20px]">
                {t("My cart")}
              </h1>
              <table className="max-md:hidden">
                <thead>
                  <tr className="border-b-[3px] border-b-[#ececec] text-[#777]">
                    <td></td>
                    <td></td>
                    <td className="leading-[1.05] tracking-[0.05em] uppercase p-[7px] text-[14px] font-[700]">
                      {t("PRODUCT NAME")}
                    </td>
                    <td className="leading-[1.05] tracking-[0.05em] uppercase p-[7px] text-[14px] font-[700]">
                      {t("PRICE")}
                    </td>
                    <td className="leading-[1.05] tracking-[0.05em] uppercase p-[7px] text-[14px] font-[700]">
                      {t("quantity")}
                    </td>
                    <td className="leading-[1.05] tracking-[0.05em] uppercase p-[7px] text-[14px] font-[700]">
                      {t("subtotal")}
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <>
                      <CartItemLapSkeleton edit={true} />
                      <CartItemLapSkeleton edit={true} />
                    </>
                  ) : (
                    sweets &&
                    sweets.map((item) => (
                      <CartItemLap
                        id={item.id}
                        key={item.id}
                        name={item.product_name}
                        image={item.product_image}
                        price={item.price}
                        count={item.quantity}
                        subTotal={item.sub_total_price}
                        edit={true}
                      />
                    ))
                  )}
                </tbody>
              </table>
              <div className="md:hidden">
                {loading ? (
                  <div className="flex flex-col gap-2">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div key={index}>
                        <CartItemMobSkeleton />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col gap-2">
                    {sweets &&
                      sweets.map((item) => (
                        <CartItemMob
                          id={item.id}
                          key={item.id}
                          name={item.product_name}
                          image={item.product_image}
                          price={item.price}
                          count={item.quantity}
                          subTotal={item.sub_total_price}
                          edit={true}
                        />
                      ))}
                  </div>
                )}
              </div>
              <Link
                to="/"
                className="px-3 py-1 flex justify-center items-center w-fit bg-white text-PrimaryColor font-semibold hover:text-white hover:bg-PrimaryColor duration-300 border-[2px] rounded-[10px] border-PrimaryColor"
              >
                {t("CONTINUE SHOPPING")}
              </Link>
            </div>
            <div className="flex flex-col gap-3">
              <div className="leading-[1.05] tracking-[0.05em] uppercase p-[7px] text-[14px] font-[700] border-b-[3px] border-b-[#ececec] text-[#777]">
                {t("CART TOTALS")}
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex justify-between items-center border-b-[1px] border-b-[#ececec] pb-3">
                  {loading ? (
                    <>
                      <Skeleton width={100} height={20} />
                      <Skeleton width={100} height={20} />
                    </>
                  ) : (
                    <>
                      <div className="leading-[1.05] tracking-[0.05em] text-[14px] text-[#777]">
                        {t("Total price")}:
                      </div>
                      <div className="font-[700]">
                        {parseFloat(totalPrice).toFixed(2)} RM
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={`${
                    loading && "opacity-70 pointer-events-none"
                  } md:max-w-[300px] uppercase bg-GreenColor hover:bg-[#5d7c12] duration-300 py-2 px-2 rounded-[10px] cursor-pointer text-white font-semibold flex justify-center items-center`}
                >
                  {t("Proceed to checkout")}
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-2 border-b-[3px] border-b-[#ececec] text-[#777]">
                    <FaBookmark className="-rotate-[135deg]" size={10} />
                    <label htmlFor="coupon">{t("Coupon")}</label>
                  </div>
                  <form
                    onSubmit={couponSubmit}
                    className="flex flex-col gap-3 md:max-w-[300px]"
                  >
                    <input
                      id="coupon"
                      className="outline-none px-[10px] py-1 border border-[#ddd] hover:drop-shadow-sm hover:shadow-inner duration-300"
                      placeholder={t("Coupon code")}
                      type="text"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                    <button
                      type="submit"
                      className={`${
                        couponLoading && "opacity-70 pointer-events-none"
                      } bg-[#f9f9f9] text-[#666] hover:bg-[#c7c7c7] border border-[#ddd] hover:border-[#c7c7c7] duration-300 py-2 px-2 rounded-[10px] cursor-pointer flex justify-center items-center`}
                    >
                      {couponLoading ? (
                        <AiOutlineLoading3Quarters
                          className="animate-spin"
                          size={25}
                        />
                      ) : (
                        t("Apply coupon")
                      )}
                    </button>
                  </form>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-2 border-b-[3px] border-b-[#ececec] text-[#777]">
                    <PiNoteFill size={10} />
                    <label htmlFor="message">{t("Message")}</label>
                  </div>
                  <form
                    onSubmit={messageSubmit}
                    className="flex flex-col gap-3 md:max-w-[300px]"
                  >
                    <input
                      id="message"
                      className="outline-none px-[10px] py-1 border border-[#ddd] hover:drop-shadow-sm hover:shadow-inner duration-300"
                      placeholder={t("Your note")}
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                      type="submit"
                      className={`${
                        messageLoading && "opacity-70 pointer-events-none"
                      } bg-[#f9f9f9] text-[#666] hover:bg-[#c7c7c7] border border-[#ddd] hover:border-[#c7c7c7] duration-300 py-2 px-2 rounded-[10px] cursor-pointer flex justify-center items-center`}
                    >
                      {messageLoading ? (
                        <AiOutlineLoading3Quarters
                          className="animate-spin"
                          size={25}
                        />
                      ) : (
                        t("Send")
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Cart;

