import React, { createContext, useContext, useState } from "react";

const PopupContext = createContext();

export const usePopup = () => useContext(PopupContext);

function PopupProvider({ children }) {
  // header
  const [loginOpen, setLoginOpen] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [selectLangOpen, setSelectLangOpen] = useState(false);
  const [codeOpen, setCodeOpen] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [sweetItemDetailsOpen, setSweetItemDetailsOpen] = useState(false);
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [updateCategoryOpen, setUpdateCategoryOpen] = useState(false);
  const [addItemOpen, setAddItemOpen] = useState(false);
  const [updateItemOpen, setUpdateItemOpen] = useState(false);
  const [MakeDecisionOpen, setMakeDecisionOpen] = useState(false);
  const [type, setType] = useState();
  const [action, setAction] = useState();
  const [orderFilterOpen, setOrderFilterOpen] = useState(false);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("All");
  const [statusFilterId, setStatusFilterId] = useState("");
  const contextValue = {
    loginOpen,
    setLoginOpen,
    sideBarOpen,
    setSideBarOpen,
    selectLangOpen,
    setSelectLangOpen,
    codeOpen,
    setCodeOpen,
    showResendButton,
    setShowResendButton,
    isHovered,
    setIsHovered,
    searchOpen,
    setSearchOpen,
    cartOpen,
    setCartOpen,
    sweetItemDetailsOpen,
    setSweetItemDetailsOpen,
    addCategoryOpen,
    setAddCategoryOpen,
    updateCategoryOpen,
    setUpdateCategoryOpen,
    addItemOpen,
    setAddItemOpen,
    updateItemOpen,
    setUpdateItemOpen,
    MakeDecisionOpen,
    setMakeDecisionOpen,
    type,
    setType,
    action,
    setAction,
    orderFilterOpen,
    setOrderFilterOpen,
    startDateOpen,
    setStartDateOpen,
    endDateOpen,
    setEndDateOpen,
    statusFilter,
    setStatusFilter,
    statusFilterId,
    setStatusFilterId,
  };
  return (
    <PopupContext.Provider value={contextValue}>
      {children}
    </PopupContext.Provider>
  );
}

export default PopupProvider;
