import { React, useState, useContext } from "react";
import { useTheme } from "../../Context/ThemeContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePopup } from "../../Context/PopupContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { deleteCategory, deleteItem } from "../../Api/DashboardApi";
import { CategoriesContext } from "../../Context/CategoriesContext";
import { SweetContext } from "../../Context/SweetContext";

function MakeDecision() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const { setMakeDecisionOpen, type, action } = usePopup();
  const { selectedCategory, setUpdateCategories, setCategoryId } =
    useContext(CategoriesContext);
  const { selectedSweetItem } = useContext(SweetContext);
  const handleConfirm = async () => {
    setSpinnerLoad(true);
    if (type === "category") {
      try {
        const response = await deleteCategory(selectedCategory.id);
        console.log(response);
        setUpdateCategories((prev) => prev + 1);
        setCategoryId(1);
        setMakeDecisionOpen(false);
      } catch (error) {
        console.log("delete category err : ", error);
      } finally {
        setSpinnerLoad(false);
      }
    } else if (type === "sweet item") {
      try {
        const response = await deleteItem(selectedSweetItem.id);
        console.log(response);
        setCategoryId(selectedSweetItem.categoryId);
        setMakeDecisionOpen(false);
      } catch (error) {
        console.log("delete sweet item err : ", error);
      } finally {
        setSpinnerLoad(false);
      }
    }
  };
  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkBoxBg text-DarkMainText"
      } max-h-[673px] max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full flex flex-col gap-2 p-5`}
    >
      <h1>
        {t("Are you sure you want to")} {action} {t("this")} {type} ?
      </h1>
      <div className="flex items-center justify-start my-3 gap-3">
        <button
          onClick={() => setMakeDecisionOpen(false)}
          className="px-2 py-1 cursor-pointer rounded-[62px] border font-bold  border-[#333332] text-PrimaryColor  bg-white hover:bg-gray-200 min-w-[70px] duration-300 flex justify-center items-center"
        >
          {t("Cancel")}
        </button>
        <button
          onClick={handleConfirm}
          className="px-2 py-1 cursor-pointer rounded-[62px] border font-bold  border-orange-300 text-white bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 min-w-[70px] min-h-[33.6px] flex justify-center items-center"
        >
          {spinnerLoad ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Ok")
          )}
        </button>
      </div>
    </div>
  );
}

export default MakeDecision;
