import React from "react";
import image from "../../assests/Imgs/WhatsApp_icon.png";

function WhatsappIcon() {
  return (
    <div
      onClick={() => window.open("https://wa.me/+601111064777", "_blank")}
      className="w-[50px] h-[50px] bottom-2 right-2 fixed cursor-pointer z-20"
    >
      <img className="w-full h-full" src={image} alt="" />
    </div>
  );
}

export default WhatsappIcon;
