import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SweetItemSkeleton() {
  return (
    <div className="flex flex-col">
      <div className="w-full basis-[50%]">
        <Skeleton width={"100%"} height={200} />
      </div>
      <div className="w-full basis-[50%] flex flex-col items-center">
        <div className="w-[30%]">
          <Skeleton className="h-[20px] rounded-xl" />
        </div>
        <div className="w-[60%]">
          <Skeleton className="h-[20px] rounded-xl" />
        </div>
        <div className="w-[90%]">
          <Skeleton className="h-[40px] rounded-xl" />
        </div>
        <div className="w-[20%]">
          <Skeleton className="h-[20px] rounded-xl" />
        </div>
      </div>
    </div>
  );
}

export default SweetItemSkeleton;
