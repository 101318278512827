import React, { useContext, useEffect, useState } from "react";
import { usePopup } from "../../Context/PopupContext";
import Popup from "../../Shared/Components/Popup";
import { fetchCartItemInfo } from "../../Api/CartApi";
import { SweetContext } from "../../Context/SweetContext";
import { useTranslation } from "react-i18next";
import ItemInCart from "./ItemInCart";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import Cookies from "js-cookie";

function CartItems() {
  const { t } = useTranslation();
  const language_id = localStorage.getItem("languageId");
  const lng = localStorage.getItem("kfLanguage") || "English";
  const { cartOpen, setCartOpen } = usePopup();
  const [cartItems, setCartItems] = useState([]);
  const { totalCartPrice, setTotalCartPrice, updataCartItems, totalItems } =
    useContext(SweetContext);
  const [loading, setLoading] = useState(true);
  const token = Cookies.get("kfToken");
  const getCartItems = async () => {
    if (token) {
      try {
        setLoading(true);
        const response = await fetchCartItemInfo(language_id);
        console.log(response.data.Items);
        setCartItems(response.data.Items);
        setTotalCartPrice(response.data.total_sub_total_price);
        if (parseFloat(response.data.Items.length) < 1) {
          setCartOpen(false);
        }
      } catch (error) {
        console.error("get cart items err : ", error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getCartItems();
  }, [updataCartItems]);
  return (
    <Popup
      toggleSwitch={cartOpen}
      onClick={() => setCartOpen(false)}
      zIndex={`z-30`}
      className={`${
        cartOpen
          ? lng === "Arabic"
            ? `left-0 top-0`
            : `right-0 top-0`
          : lng === "Arabic"
          ? "-left-full"
          : "-right-full"
      } w-fit max-md:w-full md:min-w-[413px] md:max-w-[413px] h-screen bg-white z-40`}
      children={
        <div className="flex flex-col h-full justify-between p-4 overflow-y-auto relative">
          <div className="flex flex-col gap-2">
            <div className="flex justify-center items-center relative text-[#2b2b2b]/70 font-bold pb-2 text-[20px] border-b border-b-[#0000001a]">
              <IoMdClose
                onClick={() => setCartOpen(false)}
                className={`absolute top-[6px] right-2 cursor-pointer text-[#ccc] hover:text-black duration-300`}
                size={20}
              />
              {t("Cart")}
            </div>
            {loading ? (
              Array.from({ length: 2 }).map((_, index) => (
                <div key={index}>
                  <Skeleton height={130} />
                </div>
              ))
            ) : cartItems.length > 0 ? (
              cartItems &&
              cartItems.map((item) => (
                <div key={item.id}>
                  <ItemInCart
                    id={item.id}
                    image={item.product_image}
                    name={item.product_name}
                    quantity={item.quantity}
                    price={item.price}
                    subTotalPrice={item.sub_total_price}
                  />
                </div>
              ))
            ) : (
              <div>{t("No Items")}</div>
            )}
            <div className="flex justify-center items-center gap-2 pb-2 border-b border-b-[#0000001a] font-[500]">
              <div className="text-[#2b2b2b]/70">{t("Total:")}</div>
              {loading ? (
                <Skeleton width={100} height={30} />
              ) : (
                `${parseFloat(totalCartPrice).toFixed(2)} RM`
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Link
              onClick={() => setCartOpen(false)}
              to="/cart"
              className="uppercase bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 py-2 px-2 rounded-[6px] cursor-pointer text-white font-semibold flex justify-center items-center"
            >
              {t("view cart")}
            </Link>
            <button className="uppercase bg-GreenColor hover:bg-[#5d7c12] duration-300 py-2 px-2 rounded-[6px] cursor-pointer text-white font-semibold flex justify-center items-center">
              {t("checkout")}
            </button>
          </div>
        </div>
      }
    />
  );
}

export default CartItems;

