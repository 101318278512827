import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Container from "../../../Shared/Components/Container";
import SweetItem from "../../Components/SweetItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import {
  fetchSweetsByCategory,
  fetchSweetsByCategoryUser,
} from "../../../Api/SweetApi";
import SweetItemSkeleton from "../../Components/SkeletonLoading/SweetItemSkeleton";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { SweetContext } from "../../../Context/SweetContext";

function SweetByCategory() {
  const { t } = useTranslation();
  const language_id = localStorage.getItem("languageId");
  const lng = localStorage.getItem("kfLanguage") || "English";
  const [sweets, setSweets] = useState([]);
  const [catName, setCatName] = useState("");
  const [loading, setLoading] = useState(false);
  const token = Cookies.get("kfToken");
  const { updateFavorite } = useContext(SweetContext);
  const fetchSweetsInCategory = async () => {
    try {
      setLoading(true);
      const response = token
        ? await fetchSweetsByCategoryUser(language_id)
        : await fetchSweetsByCategory(language_id);
      console.log(response.data);
      setSweets(response.data);
      setCatName(response.data[0].category[0].name);
    } catch (error) {
      console.error("get sweets by category err : ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSweetsInCategory();
  }, [updateFavorite, lng]);

  return (
    <div className="pt-1">
      <Container>
        <div className="flex justify-between items-center">
          <h1 className="basis-[50%] text-PrimaryColor uppercase font-bold border-b-[2px] border-[#0000001a] py-2">
            {loading ? <Skeleton width={"100%"} height={30} /> : catName}
          </h1>
          <Link
            to="/sweetsByCategory"
            className="font-bold hover:text-GreenColor duration-300 flex items-center gap-1"
          >
            {t("More Products")}
            <IoIosArrowForward
              className={`${lng === "Arabic" && "rotate-180"}`}
            />
          </Link>
        </div>
        <div className="w-full h-[2px] bg-[#f1f1f1]"></div>
        <div style={{ direction: "ltr" }} className="mainSwiper py-5 -z-0">
          <Swiper
            className="h-full"
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            speed={80}
            navigation
            autoplay={{ delay: 2000 }} // delay between slides in milliseconds
            loop
            breakpoints={{
              850: {
                slidesPerView: 5,
              },
              550: {
                slidesPerView: 3,
              },
              0: {
                slidesPerView: 2,
              },
            }}
          >
            {loading
              ? Array.from({ length: 7 }).map((_, index) => (
                  <SwiperSlide key={index}>
                    <SweetItemSkeleton />
                  </SwiperSlide>
                ))
              : sweets.length > 0 &&
                sweets.map((item) => (
                  <SwiperSlide key={item.id}>
                    <SweetItem
                      id={item.id}
                      favorite={item?.FavoriteProduct?.[0]?.is_favorite}
                      pic={item?.productsMedia?.[0]?.url_media}
                      picFlip={item?.productsMedia?.[1]?.url_media}
                      name={item.name}
                      category={item.category[0].name}
                      description={item.description}
                      price={item.price}
                    />
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
      </Container>
    </div>
  );
}

export default SweetByCategory;

