import { toastSuccess } from "../Shared/Components/ToastNotification/Toast";
import { apiClient } from "./api";

export const fetchAccountDetails = async () => {
  try {
    const response = await apiClient.get(
      `/api/getUser`
    );
    return response.data;
  } catch (error) {
    console.error("fetch account details err : ", error);
  }
};

export const updateAccountDetails = async (data) => {
  try {
    const response = await apiClient.post(
      `/api/update-user`,data
    );
    toastSuccess(response.data.message)
    return response.data;
  } catch (error) {
    console.error("update account details err : ", error);
  }
};
