import React from "react";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../Components/DashboardSidebar";
import { useTheme } from "../../Context/ThemeContext";
import { MdDarkMode, MdLightMode } from "react-icons/md";

function Dashboard() {
  const { theme, setTheme } = useTheme();
  return (
    <div
      style={{ direction: "ltr" }}
      className={`${
        theme === "dark" ? "bg-DarkMainBg" : "bg-MainBg"
      } flex h-screen p-2 overflow-x-hidden relative`}
    >
      {/* <div
        onClick={() => {
          theme === "light" ? setTheme("dark") : setTheme("light");
        }}
        className={`${
          theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
        } absolute top-5 right-5 p-[5px] cursor-pointer rounded-full`}
      >
        {theme === "dark" ? (
          <MdLightMode size={20} />
        ) : (
          <MdDarkMode size={20} />
        )}
      </div> */}
      <div>
        <DashboardSidebar />
      </div>
      <div className="p-10 w-full">
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
