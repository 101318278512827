import React from "react";
import { Link } from "react-router-dom";
import pic1 from "../../assests/Imgs/kunafa1.png";

function CategoryItem({ pic = pic1, name = "baklawa series", onClick, link }) {
  return (
    <Link
      onClick={onClick}
      to={link}
      className="flex justify-center items-center relative h-[200px]"
    >
      <div className="absolute top-0 left-0 w-full h-full">
        <img className="w-full h-full object-cover" src={pic} alt="" />
      </div>
      <div className="uppercase absolute left-[50%] -translate-x-[50%] flex justify-center items-center text-center bottom-3 px-2 py-1 bg-white opacity-85 leading-[1.3] font-[700] text-[#555]">
        {name}
      </div>
    </Link>
  );
}

export default CategoryItem;
