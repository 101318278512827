import {
  toastError,
  toastSuccess,
} from "../Shared/Components/ToastNotification/Toast.jsx";
import { apiClient } from "./api";
import "react-toastify/dist/ReactToastify.css";

export const loginAndSendCode = async (Data) => {
  try {
    const response = await apiClient.post(`/api/register`, Data);
    return response.data;
  } catch (error) {
    console.error("login and send code err : ", error);
    console.error("login err : ", error.response.data.data[0]);
    toastError(error.response.data.data[0]);
  }
};

export const verifyCode = async (Data) => {
  try {
    const response = await apiClient.post(`/api/verify`, Data);
    toastSuccess(response.data.message);
    return response.data;
  } catch (error) {
    console.error("verify email err : ", error.response.data.data[0]);
    toastError(error.response.data.data[0]);
  }
};

export const logout = async () => {
  try {
    const response = await apiClient.get(`/api/logout`);
    toastSuccess(response.data.message);
    return response.data;
  } catch (error) {
    console.error("logout err : ", error.response.data.data[0]);
    toastError(error.response.data.data[0]);
  }
};
