import React, { useContext, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { addSweetItemToFavorite } from "../../Api/SweetApi";
import {
  addItemToCart,
  deleteItemFromCart,
  updateItemInCart,
} from "../../Api/CartApi";
import { SweetContext } from "../../Context/SweetContext";
import { Link, useNavigate } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import { usePopup } from "../../Context/PopupContext";

function CartItemMob({ id, image, name, price, count, subTotal, edit }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [quantity, setquantity] = useState(edit ? parseFloat(count) : 1);
  const {
    totalItems,
    setUpdateTotal,
    setUpdateCartItems,
    setUpdateFavorite,
    setUpdateCartInfo,
    SetSweetItemId,
  } = useContext(SweetContext);
  const { setSweetItemDetailsOpen } = usePopup();
  const removeFromFavorite = async () => {
    try {
      setFavLoading(true);
      const response = await addSweetItemToFavorite(id);
      console.log(response);
      setUpdateFavorite((prev) => prev + 1);
    } catch (error) {
      console.error("add sweet item to favorite err : ", error);
    } finally {
      setFavLoading(false);
    }
  };
  const addToCart = async () => {
    const formData = new FormData();
    formData.append("quantity", quantity);
    formData.append("product_id", id);
    try {
      setAddLoading(true);
      const response = await addItemToCart(formData);
      console.log(response);
      setUpdateTotal((prev) => prev + 1);
    } catch (err) {
      console.error("add item to cart err : ", err);
    } finally {
      setAddLoading(false);
    }
  };
  const editItemInCart = async () => {
    try {
      setEditLoading(true);
      const response = await updateItemInCart();
      console.log(response);
      setUpdateCartInfo((prev) => prev + 1);
      setUpdateCartItems((prev) => prev + 1);
    } catch (error) {
      console.error("edit item in cart err :", error);
    } finally {
      setEditLoading(false);
    }
  };
  const deleteItem = async () => {
    try {
      setDeleteLoading(true);
      const response = await deleteItemFromCart(id);
      console.log(response);
      setUpdateCartInfo((prev) => prev + 1);
      setUpdateCartItems((prev) => prev + 1);
      setUpdateTotal((prev) => prev + 1);
    } catch (error) {
      console.error("delete item from cart err : ", error);
    } finally {
      setDeleteLoading(false);
      parseFloat(totalItems) === 0 && navigate("/");
    }
  };
  return (
    <div className="relative flex flex-col gap-1">
      <div className="absolute -top-2 -left-2">
        {favLoading || deleteLoading ? (
          <AiOutlineLoading3Quarters className="animate-spin" size={25} />
        ) : (
          <IoIosCloseCircleOutline
            onClick={edit ? deleteItem : removeFromFavorite}
            className="cursor-pointer text-[#ccc] hover:text-black duration-300"
            size={25}
          />
        )}
      </div>
      <div className="flex gap-3">
        <div className="basis-[30%] min-h-full">
          <img className="w-full h-full" src={image} alt="" />
        </div>
        <div className="basis-[70%] flex flex-col justify-between">
          <Link
            onClick={() => {
              SetSweetItemId(id);
              setSweetItemDetailsOpen(true);
            }}
            className="text-[#2b2b2b] hover:text-GreenColor duration-300 text-[18px] font-[700]"
          >
            {name}
          </Link>
          <div className="flex items-center gap-4">
            <FaMinus
              onClick={() => quantity !== 1 && setquantity((prev) => prev - 1)}
              className="cursor-pointer hover:text-red-600 duration-300"
            />
            <div className="font-semibold">{quantity}</div>
            <FaPlus
              onClick={() => setquantity((prev) => prev + 1)}
              className="cursor-pointer hover:text-GreenColor duration-300"
            />
          </div>
          <div>
            <div className="flex justify-between items-center text-[14px]">
              <div>{t("Price")}:</div>
              <div className="font-[700]">{price}RM</div>
            </div>
            {edit && (
              <div className="flex justify-between items-center text-[14px]">
                <div>{t("Subtotal")}:</div>
                <div className="font-[700]">{subTotal}RM</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        onClick={edit ? editItemInCart : addToCart}
        className={`${
          (addLoading || editLoading) && "pointer-events-none"
        } cursor-pointer flex justify-center items-center hover:text-GreenColor duration-300`}
      >
        {addLoading || editLoading ? (
          <AiOutlineLoading3Quarters className="animate-spin" size={20} />
        ) : edit ? (
          t("Save changes")
        ) : (
          t("Add to cart")
        )}
      </div>
    </div>
  );
}

export default CartItemMob;
