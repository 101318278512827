import React from "react";
import TopBar from "./TopBar";
import CenterBar from "./CenterBar";
import BottomBar from "./BottomBar";
import { logout } from "../../Api/AuthApi";
import { toastLoading } from "../../Shared/Components/ToastNotification/Toast";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Header({ sessionId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogout = async () => {
    const toastId = toastLoading(`${t("logout processing")}...`);
    try {
      const response = await logout();
      console.log(response);
      if (response.success === true) {
        navigate("/");
        window.location.reload();
        Cookies.remove("kfToken");
      }
    } catch (error) {
      console.error("logout err : ", error);
    } finally {
      toast.dismiss(toastId);
    }
  };
  return (
    <div className="flex flex-col">
      <TopBar />
      <CenterBar sessionId={sessionId} logout={handleLogout} />
      <BottomBar logout={handleLogout} />
    </div>
  );
}

export default Header;
