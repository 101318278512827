import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "../../Context/ThemeContext";
import { LuMenu } from "react-icons/lu";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import { useTranslation } from "react-i18next";
import BottomBarLink from "./BottomBarLink";
import Container from "../../Shared/Components/Container";
import CartButton from "./CartButton";
import Popup from "../../Shared/Components/Popup";
import { usePopup } from "../../Context/PopupContext";
import LoginWithCode from "./LoginWithCode";
import logo from "../../assests/Imgs/Logo/funafaFingersLogo.png";
import { Link, useNavigate } from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import { fetchtotalCart } from "../../Api/CartApi";
import Cookies from "js-cookie";
import { SweetContext } from "../../Context/SweetContext";
import LanguageButton from "./LanguageButton";
import { CategoriesContext } from "../../Context/CategoriesContext";
import CartItems from "./CartItems";
import { IoPerson } from "react-icons/io5";
import { searchCurious, searchUser } from "../../Api/SweetApi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import SweetItemDetails from "./SweetItemDetails";
import SearchItem from "./SearchItem";
import useOutsideClick from "../../Shared/Components/UseOutsideClick";

function BottomBar({ sessionId, logout }) {
  const { theme } = useTheme();
  const name = localStorage.getItem("kfName");
  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false);
  const [totalLoading, setTotalLoading] = useState(true);
  const token = Cookies.get("kfToken");
  const {
    updateTotal,
    totalItems,
    setTotalItems,
    totalPrice,
    setTotalPrice,
    handleLogout,
  } = useContext(SweetContext);
  const { categories, setCategoryId } = useContext(CategoriesContext);
  const getTotalCart = async () => {
    try {
      setTotalLoading(true);
      const response = await fetchtotalCart();
      console.log(response.data);
      setTotalItems(response.data.total_items);
      setTotalPrice(response.data.total_price);
      setTotalLoading(false);
    } catch (error) {
      console.error("total err : ", error);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const isScrolledBeyondThreshold = window.scrollY > 500;
      setIsFixed(isScrolledBeyondThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup listener on component unmount

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    token && getTotalCart();
  }, [updateTotal]);
  const { t } = useTranslation();
  const language_id = localStorage.getItem("languageId");
  const lng = localStorage.getItem("kfLanguage");
  const {
    setLoginOpen,
    sideBarOpen,
    setSideBarOpen,
    setCartOpen,
    searchOpen,
    setSearchOpen,
    sweetItemDetailsOpen,
  } = usePopup();
  const { favoriteTotal } = useContext(SweetContext);
  const searchRef = useOutsideClick(() => setSearchOpen(false));
  const [searchCategory, setSearchCategory] = useState("");
  const [query, setQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const handleSearch = async (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    const categoryId = searchCategory;
    try {
      setSearchLoading(true);
      const response = token
        ? await searchUser(newQuery, categoryId, language_id)
        : await searchCurious(newQuery, categoryId, language_id, sessionId);
      console.log(response.data);
      setSearchResult(response.data);
      setSearchOpen(true);
    } catch (err) {
      console.error("search user err : ", err);
    } finally {
      setSearchLoading(false);
    }
  };

  return (
    <div
      className={`bg-white ${
        isFixed &&
        "xl:fixed z-50 w-full top-0 left-0 py-3 shadow-fixedBarShadow"
      }`}
    >
      <Container>
        <div className="flex justify-between items-center max-xl:hidden pb-2">
          <div className="flex items-center gap-3">
            {categories &&
              categories.length > 0 &&
              categories.map((cat, index) => (
                <div
                  onClick={() => {
                    setCategoryId(cat.id);
                    navigate("/sweetsByCategory");
                  }}
                  key={cat.id}
                  className={`${index > 6 && "hidden"}  ${
                    index !== 0 && "border-l border-l-[#0000001a] pl-3"
                  }`}
                >
                  <BottomBarLink key={cat.id} id={cat.id} content={cat.name} />
                </div>
              ))}
          </div>
          <CartButton
            onClick={() => parseFloat(totalPrice) > 0 && setCartOpen(true)}
            text={`${t("CART")} / `}
            value={
              token
                ? `${
                    parseFloat(totalPrice) > 0
                      ? parseFloat(totalPrice).toFixed(2)
                      : "0.00"
                  } RM`
                : `0.00 RM`
            }
            totalItems={totalItems}
            loading={totalLoading}
          />
        </div>
        <div className="flex justify-between items-center relative min-h-[74px] xl:hidden py-1">
          <div onClick={() => setSideBarOpen(true)} className="cursor-pointer">
            <LuMenu size={20} />
          </div>
          <Popup
            toggleSwitch={sideBarOpen}
            onClick={() => setSideBarOpen(false)}
            className={`${
              sideBarOpen ? "left-0 top-0" : "-left-full"
            } w-full h-screen bg-white/90 z-20`}
            children={
              <div className="flex flex-col items-center min-h-screen px-2">
                <div className="w-full flex relative justify-between items-center py-2 min-h-[80px]">
                  <LanguageButton
                    className={`flex justify-center items-center bg-transparent max-xl:text-[#777] max-xl:hover:text-[#353535] max-xl:hover:bg-[#d1cece]`}
                  />
                  <Link
                    onClick={() => setSideBarOpen(false)}
                    to="/"
                    className="w-[120px] h-[80px] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%]"
                  >
                    <img
                      className="w-full h-full object-contain"
                      src={logo}
                      alt=""
                    />
                  </Link>
                  <IoMdClose
                    onClick={() => setSideBarOpen(false)}
                    className="cursor-pointer"
                    size={20}
                  />
                </div>
                <div className="flex flex-col gap-3 w-full overflow-y-auto text-[#353535]">
                  {token ? (
                    <div className="flex justify-between items-center">
                      <div className="flex gap-3 items-center">
                        <div className="flex items-center gap-1 uppercase text-[1.1em]">
                          <IoPerson /> {name}
                        </div>
                        <div className="h-[20px] w-[1px] bg-[#0000001a]"></div>
                        <div
                          onClick={() => {
                            if (favoriteTotal !== 0 && token) {
                              navigate("/profile/wishList");
                              setSideBarOpen(false);
                            }
                          }}
                          className="relative rounded-full p-2 cursor-pointer border border-[#666666d9] hover:bg-PrimaryColor hover:border-PrimaryColor hover:text-white duration-300"
                        >
                          <FaHeart />
                          <div
                            className={`${
                              (!favoriteTotal || favoriteTotal === 0) &&
                              "hidden"
                            } absolute -bottom-3 -right-3 text-[#f1f1f1] rounded-full p-1 text-[10px] min-w-[25px] min-h-[25px] flex justify-center items-center bg-[#79a218]`}
                          >
                            {favoriteTotal}
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={logout}
                        className="flex items-center gap-1 cursor-pointer text-[#353535] font-[500] uppercase hover:text-PrimaryColor duration-300 text-[1.1em]"
                      >
                        {t("Logout")}
                      </div>
                    </div>
                  ) : (
                    <BottomBarLink
                      content={t("Login")}
                      onLogin={() => {
                        setLoginOpen(true);
                      }}
                      login={true}
                    />
                  )}
                  <div className="flex flex-col gap-1 pt-2">
                    <select
                      value={searchCategory}
                      onChange={(e) => setSearchCategory(e.target.value)}
                      className="rounded-[10px] max-w-[200px] border border-[#ddd] py-1 px-2 cursor-pointer outline-none bg-[#00000008] hover:bg-[#0c0a0a26] duration-300"
                    >
                      <option defaultValue={t("All")} value="">
                        {t("All")}
                      </option>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    <div className="relative min-h-[600px]">
                      <div className="relative">
                        <input
                          className="w-full cursor-pointer rounded-[10px] border border-[#ddd] py-1 px-3 bg-[#00000008] hover:bg-[#0c0a0a26] duration-300 outline-none focus:ring-0"
                          type="text"
                          placeholder={`${t("Search")}...`}
                          value={query}
                          onChange={handleSearch}
                        />
                        <div
                          className={`${
                            lng === "Arabic" ? "left-2" : "right-2"
                          } absolute top-[50%] -translate-y-[50%] cursor-pointer`}
                        >
                          {searchLoading ? (
                            <AiOutlineLoading3Quarters className="animate-spin" />
                          ) : (
                            <IoMdSearch />
                          )}
                        </div>
                      </div>
                      <div
                        ref={searchRef}
                        className={`${
                          searchOpen
                            ? "block z-30 opacity-100"
                            : "hidden -z-10 opacity-0"
                        } absolute -top-0 mt-8 transition-all duration-300 w-full shadow-xl overflow-y-auto`}
                      >
                        {searchResult &&
                          searchResult.map((result) => (
                            <div key={result.id}>
                              <SearchItem
                                id={result.id}
                                image={result.productsMedia[0].url_media}
                                name={result.name}
                                price={result.price}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
          <Link
            to="/"
            className="w-[120px] h-[80px] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%]"
          >
            <img className="w-full h-full object-contain" src={logo} alt="" />
          </Link>
          <CartButton
            onClick={() => parseFloat(totalPrice) > 0 && setCartOpen(true)}
            totalItems={totalItems}
            loading={totalLoading}
          />
        </div>
        <LoginWithCode />
        <LanguageSelect />
        <CartItems />
      </Container>
      {sweetItemDetailsOpen && <SweetItemDetails />}
    </div>
  );
}

export default BottomBar;

