import React from "react";
import Skeleton from "react-loading-skeleton";

function OrderDetailsSkeleton() {
  return (
    <div>
      <div className="w-full h-[60px] rounded-xl mb-5">
        <Skeleton height={`100%`} />
      </div>
      <div className="flex justify-between items-center mb-5">
        <div className="w-[250px] h-[60px] rounded-xl">
          <Skeleton height={`100%`} />
        </div>
        <div className="w-[200px] h-[60px] rounded-xl">
          <Skeleton height={`100%`} />
        </div>
      </div>
      <div className="w-full h-[220px] rounded-xl mb-5">
        <Skeleton height={`100%`} />
      </div>
      <div className="w-full h-[220px] rounded-xl mb-5">
        <Skeleton height={`100%`} />
      </div>
    </div>
  );
}

export default OrderDetailsSkeleton;
