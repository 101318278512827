import React from "react";

function Popup({ toggleSwitch, onClick, className, children, zIndex }) {
  return (
    <>
      {toggleSwitch && (
        <>
          <div
            className={`${className} bg-white duration-300 transition-all fixed`}
          >
            {children}
          </div>
          <div
            onClick={onClick}
            className={`${zIndex} fixed top-0 left-0 z-10 bg-black/50 w-screen h-screen`}
          ></div>
        </>
      )}
    </>
  );
}

export default Popup;
