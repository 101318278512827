import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex flex-col gap-2">
        <div className="md:text-2xl">404 {t("Not Found")}</div>
        <Link
          to="/"
          className="px-3 py-1 flex justify-center items-center uppercase w-fit bg-white text-PrimaryColor font-semibold hover:text-white hover:bg-PrimaryColor duration-300 border-[2px] rounded-[10px] border-PrimaryColor"
        >
          {t("go to home page")}
        </Link>
      </div>
    </div>
  );
}

export default NotFound;

